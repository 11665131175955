import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { errorToast, successToast } from "../../../helpers/toast";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { useFormik } from "formik";
import {
  useAddUserApiMutation,
  useEditUserMutation,
  useLazyGetSpecificUserByIdQuery,
} from "../../../services/users";
import { CommonBody } from "../../../types/General";
import Loader from "../../../helpers/constants/Loader";
import { useLazyGetSpecificAgentByIdQuery } from "../../../services/agents";
import { isString } from "../../../utils/validation";

const UsersForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const navigate = useNavigate();
  const [dialCode, setPhoneCode] = useState("+971");
  const [editUSERbyId] = useEditUserMutation();
  const { id } = useParams();
  const [fetchApi] = useLazyGetSpecificAgentByIdQuery();
  const [addUser] = useAddUserApiMutation();

  const fetchDataById = async (id: any) => {
    try {
      setIsLoading(true);
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
        setPhoneCode(response?.data?.dialCode || "");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: ByIdDetails?.name || "",
      email: ByIdDetails?.email || "",
      phoneNo: ByIdDetails?.phoneNo || "",
      dialCode: dialCode,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          "Enter a valid email address"
        )
        .matches(
          /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/,
          "Emojis are not allowed in the email address"
        ),
      name: Yup.string()
        .required("Full name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed")
        .matches(
          /^[a-zA-Z\s]+$/,
          "Full name must contain only letters and spaces"
        ),
      phoneNo: Yup.string()
        .required("Phone number is required")
        .min(7, "Phone number must be at least 7 characters")
        .max(20, "Phone number must be at most 20 characters"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);
      let body: any = {
        name: values.name || "",
        email: values.email || "",
        phoneNo: values.phoneNo || "",
        dialCode: dialCode,
        profileSteps: 1,
      };
      if (!id) {
        body = { ...body, role: 4 };
      }
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      if (id) {
        try {
          setIsLoading(true);
          const response = await editUSERbyId({
            id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("User updated successfully");
            navigate("/manage-users");
          }
          setIsLoading(false);
        } catch (error: any) {
          setIsLoading(false);
          console.error(error);
          errorToast(error?.data?.message);
        } finally {
          setIsLoading(false);
          setSubmitting(false);
        }
      } else {
        try {
          setIsLoading(true);
          const response = await addUser({ body: encryptedBody }).unwrap();
          if (response?.statusCode === 200) {
            successToast("User added successfully");
            navigate("/manage-users");
          }
          setIsLoading(false);
        } catch (error: any) {
          console.error(error);
          setIsLoading(false);
          errorToast(error?.data?.message);
        } finally {
          setIsLoading(false);
          setSubmitting(false);
        }
      }
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phoneNo", phone?.replace(country?.dialCode, ""));
    setPhoneCode(
      country?.dialCode.includes("+")
        ? country?.dialCode
        : "+" + country?.dialCode
    );
  };

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        name: ByIdDetails?.name || "",
        email: ByIdDetails?.email || "",
        phoneNo: ByIdDetails?.phoneNo || "",
        dialCode: ByIdDetails?.dialCode || "",
      });
    }
  }, [ByIdDetails]);

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{id ? "Edit User Profile" : "Add User"}</h1>
          <Loader isLoad={isLoading} />
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-users");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 0, pb: 0 }}>
              <Grid container spacing={2}>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Full Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    variant="outlined"
                    fullWidth
                    placeholder="Full Name"
                    inputProps={{ maxLength: 35 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className="text_field"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name.toString()
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={(val) => {

                      if (
                        val.target.value == " " 
                      ) {

                      } else  {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email.toString()
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    value={dialCode + formik.values.phoneNo}
                    country={"ae"}
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phoneNo && formik.errors.phoneNo ? (
                    <h6 className="err_msg">
                      {formik.touched.phoneNo &&
                        formik.errors.phoneNo.toString()}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid>

              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default UsersForm;
