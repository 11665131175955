import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from '@mui/icons-material/Delete';
import MainContainer from "../../layout/MainContainer";
import { Autocomplete } from "@react-google-maps/api";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { UploadMedia, Uploadpdf, UploadVideo } from "../../utils/uploadMedia";
import { errorToast, successToast, warnToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";

import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CommonBody, PropertyType, PropertyValues } from "../../types/General";
import {
  useEditPropertyMutation,
  useLazyGetSpecificPropertyByIdQuery,
  usePostAddPropertyMutation,
  useLazyGetpropertYTypeListingQuery,
  useLazyGetAmenitiesListingQuery,
} from "../../services/property";

import { useLazyGetAllCategoryQuery } from "../../services/category";
import Loader from "../../helpers/constants/Loader";
import {
  useLazyGetPendingAgentQuery,
  useLazyGetPendingCompanyQuery,
} from "../../services/users";
import { useLazyGetAllpropertyStatusQuery } from "../../services/propertyStatus";
import moment from "moment";
import { isNumber, isNumberPrice } from "../../utils/validation";
import PhoneInput from "react-phone-input-2";
import DragAndDrop from "../../components/DragAndDrop";


interface PaymentPlan {
  downPaymentPercent: string;
  downPaymentInstallments: string;
  duringConsPercent: string;
  duringConsInstallments: string;
  onHandoverPercent: string;
  onHandoverInstallments: string;
}
interface PaymentPlanNumber {
  downPaymentPercent: number;
  downPaymentInstallments: number;
  duringConsPercent: number;
  duringConsInstallments: number;
  onHandoverPercent: number;
  onHandoverInstallments: number;
}

interface Layout {
  floorImage: string;
  layoutType: string;
  size: number | string;
}

interface Units {
  beds: string;
  countryISO: string;
  dialCode: string;
  enquiryNo: string;
  price: number | string;
  size: number | string;
  layouts: Layout[]
}

const AddOffPlanProperty = () => {
  const [ByIdDetails, setByIdDetails] = useState<PropertyValues | any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  // const [dialCode, setPhoneCode] = useState("+971");
  const { _id } = useParams();
  const targetRef: any = useRef(null);
  const today = new Date().toISOString().split("T")[0];
  const [addProperties] = usePostAddPropertyMutation();
  const [updateProperty] = useEditPropertyMutation();
  const [fetchApi] = useLazyGetSpecificPropertyByIdQuery();
  const [propertyTypeList] = useLazyGetpropertYTypeListingQuery();
  const [amenitiesTypeList] = useLazyGetAmenitiesListingQuery();
  const [paymentPlan, setPaymentPlan] = useState<string>("2");
  const [unitsObject, setUnitsObject] = useState<Units>({
    beds: "",
    enquiryNo: "",
    price: "",
    size: "",
    countryISO: "AE",
    dialCode: "+971",
    layouts: []
  });
  const [unitsArray, setUnitsArray] = useState<Units[]>([]);
  const [layoutObject, setLayoutObject] = useState<Layout>({
    floorImage: "",
    layoutType: "",
    size: ""
  })
  const [interiorImages, setInteriorImages] = useState<string[]>([]);
  const [exteriorImages, setExteriorImages] = useState<string[]>([]);
  const [gardenImages, setGardenImages] = useState<string[]>([]);
  const imageHandlers = [setInteriorImages, setExteriorImages, setGardenImages];
  const imageMap: { [key in 0 | 1 | 2]: string[] } = {
    0: interiorImages,
    1: exteriorImages,
    2: gardenImages,
  };
  const [value, setValue] = useState<number>(0);
  const images = imageMap[value as 0 | 1 | 2] || interiorImages;
  const setImages = imageHandlers[value];
  const [showLayout, setShowLayout] = useState<boolean>(false)
  const [paymentPlanArray, setPaymentPlanArray] = useState<PaymentPlanNumber[]>([]);
  const [paymentObject, setPaymentObject] = useState<PaymentPlan>({
    downPaymentPercent: "",
    downPaymentInstallments: "",
    duringConsPercent: "",
    duringConsInstallments: "",
    onHandoverPercent: "",
    onHandoverInstallments: "",
  })
  const [propertyTypeId, setPropertyType] = useState<
    { _id: string; name: string }[]
  >([]);

  const [categories, setCategories] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [amennity, setAmenity] = useState<{ _id: string; name: string }[]>([
    {
      _id: "",
      name: "",
    },
  ]);
  const [companyListingApi] = useLazyGetPendingCompanyQuery();
  const [companyListing, setCompanyListing] = useState<any>([]);
  const [companyId, setCompanyId] = useState<string>("");
  const [autocomplete, setAutocomplete] = useState<any>();
  const [rows, setRows] = useState<PropertyType[]>([]);
  const [fetchAll] = useLazyGetAllpropertyStatusQuery();
  const [fetchAllCategory] = useLazyGetAllCategoryQuery();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const handlePropertyTypeChange = (item: any) => {
    if (item?._id) {
      formik.setFieldValue("propertyTypeId", item._id);
    }
  };

  const handleCrossClick = (e: React.MouseEvent, i: number) => {
    e.stopPropagation();
    let res = [...imageMap[value as 0 | 1 | 2]];
    res?.splice(i, 1);
    imageHandlers[value]([...res]);
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleImageUpload1 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        setLayoutObject({ ...layoutObject, floorImage: imageUrl });
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };


  const handleAmenities = (event: SelectChangeEvent<string[]>) => {
    if (event?.target?.value) {
      formik.setFieldValue("amenitiesId", event.target?.value);
    }
  };

  const handlePropertyCondition = (event: SelectChangeEvent) => {
    formik.setFieldValue("propertyCondition", event.target?.value);
  };

  const handleChangePhone = (phone: any, country: any) => {
    setUnitsObject({ ...unitsObject, enquiryNo: phone?.replace(country?.dialCode, ""), dialCode: country?.dialCode.includes("+") ? "" : "+" + country?.dialCode, countryISO: country?.countryCode ? country?.countryCode.toUpperCase() : "" });

  };


  const handleAddPaymentObject = () => {
    if (Number(paymentObject.downPaymentPercent) + Number(paymentObject.duringConsPercent) + Number(paymentObject.onHandoverPercent) !== 100) {
      warnToast("Total percentage must equal 100%. Please adjust the values");
      return;
    } else if (paymentPlanArray?.length === 3) {
      warnToast("You can only add 3 payment plans");
      return;
    } else {
      let obj = {
        downPaymentPercent: Number(paymentObject.downPaymentPercent),
        downPaymentInstallments: Number(paymentObject.downPaymentInstallments),
        duringConsPercent: Number(paymentObject.duringConsPercent),
        duringConsInstallments: Number(paymentObject.duringConsInstallments),
        onHandoverPercent: Number(paymentObject.onHandoverPercent),
        onHandoverInstallments: Number(paymentObject.onHandoverInstallments),
      }
      setPaymentPlanArray((prevArray) => [...prevArray, obj])
      setPaymentObject({
        downPaymentPercent: "",
        downPaymentInstallments: "",
        duringConsPercent: "",
        duringConsInstallments: "",
        onHandoverPercent: "",
        onHandoverInstallments: "",
      })
    }
  }


  const handleAddUnits = () => {
    if (!unitsObject.beds || !unitsObject.price || !unitsObject.size || !unitsObject.enquiryNo) {
      warnToast("Please fill all the fields");
      return;
    } else {
      let obj = {
        beds: unitsObject?.beds,
        enquiryNo: unitsObject.enquiryNo,
        dialCode: unitsObject.dialCode,
        countryISO: unitsObject.countryISO,
        layouts: [],
        size: Number(unitsObject.size),
        price: Number(unitsObject.price)
      }

      setUnitsArray((prev) => [...prev, obj])
      setUnitsObject({
        beds: "",
        enquiryNo: "",
        layouts: [],
        size: "",
        price: "",
        dialCode: "+971",
        countryISO: "AE",
      })
    }
  }

  const handleAddLayout = (i: number) => {
    if (!layoutObject.layoutType || !layoutObject.size) {
      warnToast("Please fill layout type and size");
      return;
    } else {
      let obj = {
        layoutType: layoutObject.layoutType,
        size: layoutObject.size,
        floorImage: layoutObject.floorImage || ""
      }

      setUnitsArray((prev) => prev?.map((data, index) => {
        if (index === i) {
          return { ...data, layouts: [...data.layouts, obj] }
        } else {
          return data;
        }
      }));
      setLayoutObject({
        layoutType: "",
        size: "",
        floorImage: ""
      })
      setShowLayout(false)
    }
  }

  const handleDeletePaymentObject = (i: number) => {
    setPaymentPlanArray((prev) => prev.filter((data, index) => (index != i)))
  }

  const handleDeleteUnit = (i: number) => {
    setUnitsArray((prev) => prev.filter((data, index) => (index != i)))
  }
  const handleDeleteLayout = (unitIndex: number, layoutIndex: number) => {
    setUnitsArray((prev) =>
      prev?.map((unit, index) => {
        if (index === unitIndex) {
          return {
            ...unit,
            layouts: unit.layouts.filter((_, idx) => idx !== layoutIndex),
          };
        }
        return unit;
      })
    );
  };



  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {

    const files = event.target.files;
    const allowedLength = Array.from(files || []).length;
    if (Array.from(files || []).length > 20) {
      errorToast('Max limit is upto 20 images');
      event.target.value = '';
      return;
    }

    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const allowedVideoTypes = ['video/mp4', 'video/avi', 'video/mkv'];
    const invalidFiles = Array.from(files || []).filter(
      (file) =>
        !allowedTypes.includes(file.type) &&
        !allowedVideoTypes.includes(file.type),
    );
    if (invalidFiles.length > 0) {
      errorToast('Inavlid  type');
      event.target.value = '';
      return;
    }

    if (
      interiorImages.length +
      exteriorImages.length +
      gardenImages.length +
      allowedLength >
      20
    ) {
      errorToast('Max limit is upto 20 images');
      event.target.value = '';
      return;
    }

    const videoFiles = Array.from(files || []).filter((file) =>
      allowedVideoTypes.includes(file.type),
    );

    let validVideos = true;

    await Promise.all(
      videoFiles?.map(
        (file) =>
          new Promise<void>((resolve) => {
            const videoElement = document.createElement('video');
            videoElement.preload = 'metadata';

            videoElement.onloadedmetadata = function () {
              window.URL.revokeObjectURL(videoElement.src);
              if (videoElement.duration > 120) {
                validVideos = false;
                errorToast('Video duration exceeds the limit of 2 minutes');
              }
              resolve();
            };

            videoElement.src = URL.createObjectURL(file);
          }),
      ),
    );

    if (!validVideos) {
      event.target.value = '';
      return;
    }

    setLoading(true);
    const resArray = await Promise.all(
      Array.from(files || [])?.map((file) => {
        if (allowedTypes.includes(file.type)) {
          return UploadMedia(file);
        } else if (allowedVideoTypes.includes(file.type)) {
          return UploadVideo(file);
        }
        return null;
      }),
    );
    resArray?.map((res) => {
      if (res?.statusCode === 200) {
        const { location } = res?.data[0];

        if (imageHandlers[value]) {
          imageHandlers[value]((prev) => [...prev, location]);
        }
        setLoading(false);

        event.target.value = '';
        return;

      } else {
        errorToast(res?.message as string);
        setLoading(false);
        event.target.value = '';
      }
    });
  };


  const [searchParams, setSearchParams] = useSearchParams();
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any)?.getPlace();

      if (place && place?.address_components) {
        let address = place?.address_components;

        let state,
          city,
          country,
          zip = "";

        address?.forEach(function (component: any) {
          let types = component?.types;

          if (
            types?.indexOf("locality") > -1 ||
            types?.indexOf("administrative_area_level_3") > -1
          ) {
            city = component?.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component?.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place?.geometry?.location?.lat();
        var lng = place?.geometry?.location?.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({
        userId: id,
        isDraft: Boolean(searchParams?.get("draft")),
      }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetchAllCategory({
        limit: 50,
        page: 1,
        search: "",
        type: "subcategory",
        parentId: "",
      }).unwrap();
      setCategories(response?.data?.category || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchPropertyType = async () => {
    try {
      const response = await propertyTypeList({}).unwrap();
      setPropertyType(response?.data?.propertyType || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchAmenities = async () => {
    try {
      const response = await amenitiesTypeList({}).unwrap();
      setAmenity(response?.data || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const List = async (search: string) => {
    try {
      const response = await fetchAll({ search: search }).unwrap();
      setRows(response?.data?.PropertyCondition || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const handleDateChange = (event: any) => {
    formik.setFieldValue("constructionDate", event.target.value);
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(
        Date.UTC(
          selectedDate.getUTCFullYear(),
          selectedDate.getUTCMonth(),
          selectedDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setStartDate(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };

  const handleDateChange1 = (event: any) => {
    formik.setFieldValue("deliveryDate", event.target.value);
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(
        Date.UTC(
          selectedDate.getUTCFullYear(),
          selectedDate.getUTCMonth(),
          selectedDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setEndDate(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };

  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    return result;
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      address: "",
      specifications: "",
      keyFeature: "",
      advertisementPermitNo: "",
      categoryId: [],
      category: "",
      propertyCondition: "",
      amenitiesId: [],
      latitude: "",
      longitude: "",
      propertyTypeId: "",
      area: "",
      companyName: "",
      agentName: "",
      agentId: "",
      companyId: "",
      constructionDate: "",
      deliveryDate: "",
      developedBy: ""
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Property Name is required"),
      price: Yup.string()
        .required("Price is required")
        .max(10, "Price must be less than 10 characters"),
      address: Yup.string().required("Address is required"),
      area: Yup.string().required("Area is required"),
      specifications: Yup.string().required("Specifications is required"),
      keyFeature: Yup.string().required("Key Feature is required"),
      developedBy: Yup.string().required("This field is required"),
      companyName: Yup.string().required("Please select a company"),
      category: Yup.string().required("Category is required"),
      amenitiesId: Yup.array()
        .min(1, "Please select amenity")
        .required("Amenities are required"),

      advertisementPermitNo: Yup.string()
        .max(20)
        .required("Advertisement Permit No is required"),
      constructionDate: Yup.string(),
      deliveryDate: Yup.string(),
    }),

    onSubmit: async (values: any) => {
      formik.setSubmitting(true);

      if (interiorImages?.length === 0) {
        warnToast("Please Upload Interior Images")
        return;
      }
      if (exteriorImages?.length === 0) {
        warnToast("Please Upload Exterior Images")
        return;
      }


      const body: any = {};

      if (values?.name) {
        body.name = values.name;
      }
      if (values?.price) {
        body.price = Number(String(values.price).replace(/,/g, ''));
      }
      if (values?.address) {
        body.address = values.address;
      }
      if (values?.specifications) {
        body.specifications = values.specifications;
      }
      if (values?.keyFeature) {
        body.keyFeature = values.keyFeature;
      }
      if (values?.developedBy) {
        body.developedBy = values.developedBy
      }
      if (values?.advertisementPermitNo) {
        body.advertisementPermitNo = values.advertisementPermitNo;
      }
      if (values?.area) {
        body.area = values.area;
      }
      if (values?.category) {
        body.categoryId = values.category;
      }
      if (interiorImages?.length) {
        body.interior = interiorImages;
      }
      if (exteriorImages?.length) {
        body.exterior = exteriorImages;
      }
      if (gardenImages?.length) {
        body.garden = gardenImages;
      }
      if (unitsArray?.length) {
        body.units = unitsArray;
      }
      if (paymentPlanArray?.length) {
        body.paymentPlans = paymentPlanArray;
      }
      if (values?.propertyCondition) {
        body.propertyConditionId = values.propertyCondition;
      }
      if (values?.amenitiesId) {
        body.amenitiesId = values.amenitiesId;
      }
      if (values?.latitude) {
        body.latitude = values.latitude;
      }
      if (values?.longitude) {
        body.longitude = values.longitude;
      }
      if (values?.propertyTypeId) {
        body.propertyTypeId = values.propertyTypeId;
      }
      if (companyId) {
        body.companyId = companyId;
      }
      if (selectedAgentId) {
        body.agentId = selectedAgentId;
      }

      if (values?.deliveryDate) {
        body.deliveryDate = endDate;
      }
      if (values?.constructionDate) {
        body.constructionDate = startDate;
      }
      body.isOffPlanProjects = true;

      body.images = [
        ...interiorImages,
        ...exteriorImages,
        ...gardenImages,
      ]

      if (searchParams.get("draft")) {
        body["draftId"] = _id;
      }

      console.log(body, "body");

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        if (!_id || Boolean(searchParams.get("draft"))) {
          const response = await addProperties(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Properties Added Successfully.");
            navigate("/off-plan-listing");
          }
        } else {
          const response = await updateProperty({
            id: _id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Property Updated Successfully.");
            navigate("/off-plan-listing");
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const saveDraft = async () => {
    const values = formik?.values;
    const body: any = {};

    if (values?.name) {
      body.name = values.name;
    }
    if (values?.price) {
      body.price = Number(String(values.price).replace(/,/g, ''));
    }
    if (values?.address) {
      body.address = values.address;
    }
    if (values?.specifications) {
      body.specifications = values.specifications;
    }
    if (values?.keyFeature) {
      body.keyFeature = values.keyFeature;
    }

    if (values?.advertisementPermitNo) {
      body.advertisementPermitNo = values.advertisementPermitNo;
    }
    if (values?.area) {
      body.area = values.area;
    }
    if (values?.category) {
      body.categoryId = values.category;
    }
    if (interiorImages?.length) {
      body.interior = interiorImages;
    }
    if (exteriorImages?.length) {
      body.exterior = exteriorImages;
    }
    if (gardenImages?.length) {
      body.garden = gardenImages;
    }
    if (unitsArray?.length) {
      body.units = unitsArray;
    }
    if (paymentPlanArray?.length) {
      body.paymentPlans = paymentPlanArray;
    }
    if (values.developedBy) {
      body.developedBy = values.developedBy
    }
    if (values?.propertyCondition) {
      body.propertyConditionId = values.propertyCondition;
    }
    if (values?.amenitiesId) {
      body.amenitiesId = values.amenitiesId;
    }
    if (values?.latitude) {
      body.latitude = values.latitude;
    }
    if (values?.longitude) {
      body.longitude = values.longitude;
    }
    if (values?.propertyTypeId) {
      body.propertyTypeId = values.propertyTypeId;
    }
    if (companyId) {
      body.companyId = companyId;
    }
    if (selectedAgentId) {
      body.agentId = selectedAgentId;
    }

    if (values?.deliveryDate) {
      body.deliveryDate = endDate;
    }
    if (values?.constructionDate) {
      body.constructionDate = startDate;
    }
    body.images = [
      ...interiorImages,
      ...exteriorImages,
      ...gardenImages,
    ]
    body.isOffPlanProjects = true;
    body.isDraftSaved = true;
    console.log(body, "body11");

    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

    try {
      if (!_id) {
        const response = await addProperties(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Properties drafted successfully.");
          navigate("/off-plan-listing");
        }
      } else {
        const response = await updateProperty({
          id: _id,
          body: encryptedBody,
        }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Property drafted successfully.");
          navigate("/off-plan-listing");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    } finally {
      formik.setSubmitting(false);
    }
  };
  const [indexForAddLayout, setIndexForAddLayout] = useState<number>(0);
  const [getApprovedCompany] = useLazyGetPendingAgentQuery();
  const [agentsData, setAgentsData] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const fetchApproved = async (role: number, page: number, search: string) => {
    setLoading(true);
    try {
      const response = await getApprovedCompany({
        page: page,
        search: "",
        limit: 200,
        parentId: companyId,
        isAdminAprooved: 2,
        startDate: "",
        endDate: "",
      }).unwrap();
      if (response.statusCode === 200) {
        setLoading(false);
        setAgentsData(response?.data?.user);
        // if (!response?.data?.user?.length)
        //   errorToast("This company dont have any agents");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  console.log(formik.values.propertyTypeId, "formik.values.propertyTypeId ");

  const scrollToElement = (target: any) => {
    window.scrollTo({
      top: target,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchCategories();
    fetchAmenities();
    fetchPropertyType();
    fetchCompanyListing();
    List("");
  }, []);
  useEffect(() => {
    if (_id) fetchDataById(_id);
  }, [_id]);
  useEffect(() => {
    if (ByIdDetails) {
      // @ts-ignore
      formik.setValues({
        name: ByIdDetails?.name || "",
        price: ByIdDetails?.price ? formatNumberWithCommas(String(ByIdDetails?.price)) : "",
        address: ByIdDetails?.address || "",
        specifications: ByIdDetails?.specifications || "",
        keyFeature: ByIdDetails?.keyFeature || "",
        propertyCondition: ByIdDetails?.propertyConditionId || "",
        propertyTypeId: ByIdDetails?.propertyTypeId?._id || "",
        categoryId: ByIdDetails?.categoryId?._id || "",
        category: ByIdDetails?.categoryId?._id || "",
        area: ByIdDetails?.area,
        amenitiesId:
          ByIdDetails?.amenities?.map((item: { _id: any }) => item?._id) || [],
        advertisementPermitNo: ByIdDetails?.advertisementPermitNo || "",
        latitude: ByIdDetails?.latitude || "",
        longitude: ByIdDetails?.longitude || "",
        companyName: ByIdDetails?.companyId || "",
        agentName: ByIdDetails?.agentId || "",
        developedBy: ByIdDetails?.developedBy || "",
        deliveryDate:
          moment(ByIdDetails?.deliveryDate).format("YYYY-MM-DD") || "",
        constructionDate:
          moment(ByIdDetails?.constructionDate).format("YYYY-MM-DD") || "",
      });
      setSelectedAgentId(ByIdDetails?.agentId || "");
      setCompanyId(ByIdDetails?.companyId || "");
      setInteriorImages(ByIdDetails?.interior || [])
      setExteriorImages(ByIdDetails?.exterior || [])
      setGardenImages(ByIdDetails?.garden || [])
      setPaymentPlanArray(ByIdDetails?.paymentPlans || [])
      setUnitsArray(ByIdDetails?.units || [])
    }
  }, [ByIdDetails]);

  const fetchCompanyListing = async () => {
    try {
      const res = await companyListingApi({
        page: 1,
        search: "",
        limit: 100,
        range: {
          to: "",
          from: "",
        },
        isAdminAprooved: 2,
      }).unwrap();
      if (res?.statusCode === 200) {
        setCompanyListing(res?.data?.user);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };
  useEffect(() => {
    if (companyId) fetchApproved(3, 1, "");
  }, [companyId]);


  useEffect(() => {
    if (showLayout) {
      scrollToElement(targetRef.current.offsetTop)
    }
  }, [showLayout])






  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{_id ? "Edit Off-Plan Property" : "Add Off-Plan Property"}</h1>
          <Button
            className="btn btn_primary"
            onClick={() =>
              navigate(
                "/off-plan-listing" +
                (Boolean(searchParams.get("draft")) ? "?draft=true" : ""),
                { replace: true }
              )
            }
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="custom_tabs">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="basic tabs example"
                    className="custom_tabs_links"
                  >
                    <Tab label="Interior Images" />
                    <Tab label="Exterior Images" />
                    <Tab label="Garden Images" />
                  </Tabs>
                  <Box className="upload_images_container">
                    <div className="upload_image upload_property">
                      <div className="gap_p">
                        <DragAndDrop
                          myImages={images}
                          setMyImages={setImages}
                          handleCrossClick={handleCrossClick}
                        />

                        {images?.length < 20 ? (
                          <label
                            htmlFor="icon-button-file"
                          >
                            <Input
                              sx={{ display: 'none' }}
                              id="icon-button-file"
                              type="file"
                              inputProps={{
                                accept: 'image/*,video/*',
                                multiple: true,
                              }}
                              onChange={handleImageUpload}
                            />
                            <span className="upload_image_holder">
                              <AddIcon />
                              Upload Picture/Video
                            </span>
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </Box>



                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Property Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    className="text_field"
                    variant="outlined"
                    disabled={_id ? true : false}
                    fullWidth
                    placeholder="Enter Property Name"
                    value={formik?.values?.name}
                    onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name &&
                        typeof formik.errors.name === "string"
                        ? formik.errors.name
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Advertisement Permit Number
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="advertisementPermitNo"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    disabled={_id ? true : false}
                    placeholder="Enter Advertisement Permit No "
                    value={formik?.values?.advertisementPermitNo}
                    onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.advertisementPermitNo &&
                      Boolean(formik.errors.advertisementPermitNo)
                    }
                    helperText={
                      formik.touched.advertisementPermitNo &&
                        typeof formik.errors.advertisementPermitNo === "string"
                        ? formik.errors.advertisementPermitNo
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Launch Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Launch Price"
                    value={formik?.values?.price}
                    onChange={(val) => {
                      let { value } = val.target;
                      const numericValue = value.replace(/,/g, '');
                      if (
                        isNumberPrice(numericValue) &&
                        numericValue.length <= 12
                      ) {
                        const formattedValue =
                          formatNumberWithCommas(numericValue);
                        formik.setFieldValue('price', formattedValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={
                      formik.touched.price &&
                        typeof formik.errors.price === "string"
                        ? formik.errors.price
                        : undefined
                    }
                  />
                </Grid>


                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      Property Type
                    </Typography>

                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyTypeId"
                      value={
                        propertyTypeId?.find((it: any) => it?.name === "Off-plan")?._id ||
                        formik?.values?.propertyTypeId ||
                        ""
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.propertyTypeId &&
                        Boolean(formik.errors?.propertyTypeId)
                      }
                      displayEmpty
                      disabled={propertyTypeId?.some((it: any) => it?.name === "Off-plan")}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {propertyTypeId?.length > 0
                        ? propertyTypeId?.map((it: any) => (
                          <MenuItem
                            key={it?._id}
                            value={it?._id}
                            onClick={() => {
                              handlePropertyTypeChange(it);
                            }}
                          >
                            {it?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>

                    {formik.touched?.propertyTypeId &&
                      typeof formik?.errors?.propertyTypeId === "string" && (
                        <Typography
                          sx={{ fontSize: "12px" }}
                          variant="caption"
                          color="red"
                        >
                          {formik.errors?.propertyTypeId}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>



                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Amenities</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="amenitiesId"
                      value={formik?.values?.amenitiesId || []}
                      onChange={handleAmenities}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.amenitiesId &&
                        Boolean(formik.errors?.amenitiesId)
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust height
                            width: 250,     // Adjust width
                          },
                        },
                      }}
                      displayEmpty
                      multiple
                      renderValue={(selected) =>
                        selected?.length > 0
                          ? selected
                            ?.map((value) => {
                              const amenity = amennity?.find(
                                (it) => it?._id === value
                              );
                              return amenity?.name;
                            })
                            .join(", ")
                          : "Select"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {amennity?.length > 0
                        ? amennity?.map((it) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>
                    {formik.touched.amenitiesId &&
                      typeof formik.errors.amenitiesId === "string" && (
                        <Typography
                          sx={{ fontSize: "12px" }}
                          variant="caption"
                          color="red"
                        >
                          {formik.errors.amenitiesId}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      Property Status
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyCondition"
                      value={formik?.values?.propertyCondition || ""}
                      onChange={handlePropertyCondition}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.propertyCondition &&
                        Boolean(formik.errors.propertyCondition)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {rows?.length &&
                        rows?.map((data: any) => {
                          return (
                            <MenuItem value={data?._id}>{data?.name}</MenuItem>
                          );
                        })}
                    </Select>
                    {formik.touched.propertyCondition &&
                      typeof formik.errors.propertyCondition === "string" && (
                        <Typography
                          sx={{ fontSize: "12px" }}
                          variant="caption"
                          color="red"
                        >
                          {formik.errors.propertyCondition}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Category</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="category"
                      value={formik?.values?.category || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust height
                            width: 250,     // Adjust width
                          },
                        },
                      }}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {categories?.length > 0
                        ? categories?.map((category) => (
                          <MenuItem key={category?._id} value={category?._id}>
                            {category?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>
                    {formik.touched?.category && formik.errors?.category && (
                      <Typography
                        sx={{ fontSize: "12px" }}
                        variant="caption"
                        color="red"
                      >
                        Please select a category
                      </Typography>
                    )}
                  </FormControl>
                </Grid>




                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Construction Date
                  </Typography>
                  <TextField
                    // hiddenTypography
                    type="date"
                    name="constructionDate"
                    variant="outlined"
                    fullWidth
                    placeholder="Start date"
                    className="text_field"
                    inputProps={{ maxLength: 30, max: today }}
                    value={formik.values.constructionDate}
                    onChange={handleDateChange}
                    InputProps={{
                      endAdornment: formik.values.constructionDate && (
                        <InputAdornment position="end" className="eye_btn">
                          <IconButton
                            onClick={() => {
                              setStartDate("");
                              formik.setFieldValue("constructionDate", "");
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.constructionDate &&
                    formik.errors.constructionDate && (
                      <FormHelperText sx={{ color: "red" }}>
                        {String(formik.errors.constructionDate)}
                      </FormHelperText>
                    )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Delivery Date
                  </Typography>
                  <TextField

                    type="date"
                    name="deliveryDate"
                    variant="outlined"
                    fullWidth
                    placeholder="End date"
                    className="text_field"
                    inputProps={{ maxLength: 30, min: today }}
                    value={formik.values.deliveryDate}
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   const year = value.split("-")[0]; 
                    //   if (year.length > 4 || parseInt(year, 10) > 9999) {
                    //     formik.setFieldValue("deliveryDate", ""); 
                    //   } else {
                    //     handleDateChange1(e); 
                    //   }
                    // }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const year = value.split("-")[0]; // Extract the year part
                      if (year.length > 4 || parseInt(year, 10) > 9999) {
                        e.preventDefault(); // Prevent the change
                        return;
                      }
                      formik.setFieldValue("deliveryDate", value); // Update Formik field
                      handleDateChange1(e); // Call your custom handler
                    }}
                    InputProps={{
                      endAdornment: formik.values.deliveryDate && (
                        <InputAdornment position="end" className="eye_btn">
                          <IconButton
                            onClick={() => {
                              setEndDate("");
                              formik.setFieldValue("deliveryDate", "");
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.deliveryDate &&
                    formik.errors.deliveryDate && (
                      <FormHelperText sx={{ color: "red" }}>
                        {String(formik.errors.deliveryDate)}
                      </FormHelperText>
                    )}
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <div className="control_group w_50">
                    <Typography className="custom_label">Address</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                      options={{
                        types: ['establishment', 'geocode'], // Restrict to cities
                        componentRestrictions: { country: 'ae' },
                      }}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        name="address"
                        value={formik?.values?.address || ""}
                        placeholder="Enter Address"
                        fullWidth
                        helperText={
                          formik.touched.address &&
                            typeof formik.errors.address === "string"
                            ? formik.errors.address
                            : undefined
                        }
                        onChange={(e) => {
                          if (
                            e.target?.value.trim() === "" ||
                            e.target?.value === "."
                          ) {
                            formik.setFieldValue("address", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Autocomplete>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Area (Sq.ft)</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="area"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter area"
                    value={formik?.values?.area}
                    onChange={(e) => {
                      const re = /^[0-9]*\.?[0-9]*$/;
                      if (e.target?.value === "" || re.test(e.target?.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.area && Boolean(formik.errors.area)}
                    inputProps={{
                      inputMode: "decimal",
                    }}
                    helperText={
                      Boolean(formik.touched.area) &&
                      formik.errors.area?.toString()
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">Developed by</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="developedBy"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter here"
                    value={formik?.values?.developedBy}
                   onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.developedBy &&
                      Boolean(formik.errors.developedBy)
                    }
                    helperText={
                      Boolean(formik.touched.developedBy) &&
                      formik.errors.developedBy?.toString()
                    }
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Key Feature</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="keyFeature"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Key Feature"
                    value={formik?.values?.keyFeature}
                   onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.keyFeature &&
                      Boolean(formik.errors.keyFeature)
                    }
                    helperText={
                      Boolean(formik.touched.keyFeature) &&
                      formik.errors.keyFeature?.toString()
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Specifications
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="specifications"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Specifications"
                    value={formik?.values?.specifications}
                   onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.specifications &&
                      Boolean(formik.errors.specifications)
                    }
                    helperText={
                      formik.touched.specifications &&
                        typeof formik.errors.specifications === "string"
                        ? formik.errors.specifications
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="custom_label">
                    Payment Plan
                  </Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="2"
                    name="radio-buttons-group"
                    className="checkbox_label"
                    value={paymentPlan}
                    onChange={(val) => setPaymentPlan(val.target.value)}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel value="2" control={<Radio />} label="No" />
                  </RadioGroup>
                </Grid>

                {paymentPlanArray?.length ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="payment_card_main">
                      {paymentPlanArray?.map((item: PaymentPlanNumber, index: number) => {
                        return (
                          <Card className="cards" style={{ width: "30%" }}>
                            <div className="payment_card">
                              <h4>Payment Option {index + 1}</h4>
                              <Button className="option_delete" onClick={() => handleDeletePaymentObject(index)}>Delete</Button>
                            </div>
                            <div>
                              <h6>Down Payment: <span>{item?.downPaymentPercent}% ({item?.downPaymentInstallments} Installments)</span></h6>
                              <h6>During Construction: <span>{item?.duringConsPercent}% ({item?.duringConsInstallments} Installments)</span></h6>
                              <h6>On Handover: <span>{item?.onHandoverPercent}% ({item?.onHandoverInstallments} Installments)</span></h6>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Grid>
                ) : null}



                {paymentPlan === "1" ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className="cards" style={{ width: "50%" }} >
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography className="custom_label">
                            Down Payment
                          </Typography>
                          <Box className="payment_plan">
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 4 }}
                              placeholder="Enter (%)"
                              value={paymentObject.downPaymentPercent}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setPaymentObject({
                                    ...paymentObject,
                                    downPaymentPercent: inputValue,
                                  });
                                }
                              }}
                            />
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 3 }}
                              placeholder="Installments"
                              onBlur={formik.handleBlur}
                              value={paymentObject.downPaymentInstallments}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setPaymentObject({
                                    ...paymentObject,
                                    downPaymentInstallments: inputValue,
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography className="custom_label">
                            During Construction
                          </Typography>
                          <Box className="payment_plan">
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 3 }}
                              placeholder="Enter (%)"
                              value={paymentObject.duringConsPercent}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setPaymentObject({
                                    ...paymentObject,
                                    duringConsPercent: inputValue,
                                  });
                                }
                              }}
                            />
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 3 }}
                              placeholder="Installments"
                              value={paymentObject.duringConsInstallments}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setPaymentObject({
                                    ...paymentObject,
                                    duringConsInstallments: inputValue,
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography className="custom_label">
                            On Handover
                          </Typography>
                          <Box className="payment_plan">
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 3 }}
                              placeholder="Enter (%)"
                              value={paymentObject.onHandoverPercent}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setPaymentObject({
                                    ...paymentObject,
                                    onHandoverPercent: inputValue,
                                  });
                                }
                              }}
                            />
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 3 }}
                              placeholder="Installments"
                              value={paymentObject.onHandoverInstallments}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setPaymentObject({
                                    ...paymentObject,
                                    onHandoverInstallments: inputValue,
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Button className="btn btn_secondary"
                          onClick={() => handleAddPaymentObject()}
                          style={{
                            marginTop: "10px",
                            marginLeft: "3%"
                          }}>Add</Button>
                      </Grid>
                    </Card>
                  </Grid>
                ) : null}


                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className="cards" style={{ width: "100%" }} >
                    <h4>Add Units</h4>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Beds
                        </Typography>
                        <Box className="payment_plan">
                          <TextField
                            hiddenLabel
                            className="text_field"
                            type="text"
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 4 }}
                            placeholder="Enter Beds"
                            value={unitsObject.beds}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*\.?\d*$/.test(inputValue)) {
                                setUnitsObject({
                                  ...unitsObject,
                                  beds: inputValue,
                                });
                              }
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Price
                        </Typography>
                        <Box className="payment_plan">
                          <TextField
                            hiddenLabel
                            className="text_field"
                            type="text"
                            variant="outlined"
                            fullWidth
                            placeholder="Enter Price"
                            value={unitsObject.price}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*\.?\d*$/.test(inputValue)) {
                                setUnitsObject({
                                  ...unitsObject,
                                  price: inputValue,
                                });
                              }
                            }}
                          />

                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Area (Sq ft)
                        </Typography>
                        <Box className="payment_plan">
                          <TextField
                            hiddenLabel
                            className="text_field"
                            type="text"
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 6 }}
                            placeholder="Enter area"
                            value={unitsObject.size}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*\.?\d*$/.test(inputValue)) {
                                setUnitsObject({
                                  ...unitsObject,
                                  size: inputValue,
                                });
                              }
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">Phone Number</Typography>
                        <Box className="payment_plan">
                          <PhoneInput
                            value={unitsObject.dialCode + unitsObject.enquiryNo}
                            country={"ae"}
                            placeholder="0 (000) 000-000"
                            enableSearch={true}
                            inputStyle={{ width: "100%" }}
                            onChange={(phone, country) =>
                              handleChangePhone(phone, country)
                            }
                          />
                        </Box>
                      </Grid>
                      <Button className="btn btn_secondary"
                        onClick={() => handleAddUnits()}
                        style={{
                          marginTop: "10px",
                          marginLeft: "1.5%"
                        }}>Add Unit</Button>
                    </Grid>
                  </Card>
                </Grid>


                {unitsArray?.length ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="payment_card_main">
                      {unitsArray?.map((item: any, index: number) => {
                        return (
                          <Card className="cards" style={{ width: "30%" }} key={`unit-${index}`}>
                            <div className="payment_card">
                              <h4>Unit {index + 1}</h4>
                              <Button className="option_delete" onClick={() => handleDeleteUnit(index)}>
                                <DeleteIcon />
                              </Button>
                              <Button
                                className="option_delete"
                                style={{ background: "black" }}
                                onClick={() => { setIndexForAddLayout(index); setShowLayout(true); }}
                              >
                                Add Layout
                              </Button>
                            </div>
                            <div className="payment_card" style={{ gap: "5px" }}>
                              <h5>{item?.beds || "0"} Beds</h5>
                              <h5>{item?.price || "0"} AED</h5>
                              <h5>{item?.size || "0"} Sq ft</h5>
                              <h5>{item?.dialCode + item?.enquiryNo || "0"} </h5>
                            </div>
                            {item?.layouts?.length ? (
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className="">
                                  {item?.layouts?.map((layoutItem: any, layoutIndex: number) => {
                                    return (
                                      <Card
                                        className="cards"
                                        style={{ width: "100%", marginTop: "10px" }}
                                        key={`layout-${index}-${layoutIndex}`}
                                      >
                                        <div className="payment_card">
                                          <h4>Layout {layoutIndex + 1}</h4>
                                          <Button className="option_delete" onClick={() => handleDeleteLayout(index, layoutIndex)}>
                                            <DeleteIcon />
                                          </Button>
                                        </div>
                                        <div className="payment_card">
                                          <div>
                                            <h5>{layoutItem?.layoutType}</h5>
                                            <h5>{layoutItem?.size}</h5>
                                          </div>
                                          <div >
                                            {layoutItem?.floorImage ? (
                                              <img
                                                onClick={() => window.open(layoutItem?.floorImage)}
                                                width={"60px"}
                                                src={layoutItem?.floorImage}
                                                alt="floor image"
                                              />
                                            ) : null}

                                          </div>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </Grid>
                            ) : null}
                          </Card>
                        );
                      })}
                    </div>
                  </Grid>
                ) : null}
                {showLayout ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className="cards" ref={targetRef} >
                      <h4>Add Layout</h4>
                      <Grid container spacing={2} >
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                          <Typography className="custom_label">
                            Layout Type
                          </Typography>
                          <Box className="payment_plan">
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 30 }}
                              placeholder="Enter Type"
                              value={layoutObject.layoutType}
                              onChange={(e) => {
                                setLayoutObject({
                                  ...layoutObject,
                                  layoutType: e.target.value,
                                });

                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                          <Typography className="custom_label">
                            Layout Size
                          </Typography>
                          <Box className="payment_plan">
                            <TextField
                              hiddenLabel
                              className="text_field"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 10 }}
                              placeholder="Enter size"
                              value={layoutObject?.size}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setLayoutObject({
                                    ...layoutObject,
                                    size: inputValue,
                                  });
                                }
                              }}
                            />

                          </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                          <Typography className="custom_label">
                            Floor Image
                          </Typography>
                          <Box className="upload_image upload_img_dpinf" >
                            <label htmlFor="icon-button-file-vdo" >
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file-vdo"
                                type="file"
                                inputProps={{
                                  type: "file",
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={handleImageUpload1}
                              />
                              <Button component="span" className="upload_image_btn" >
                                <img
                                  style={{
                                    borderRadius: "5px",
                                    width: "220px",
                                    height: "45px",
                                    border: "solid 2px whitesmoke",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    layoutObject.floorImage ? window.open(layoutObject.floorImage) : null
                                  }
                                  src={
                                    layoutObject.floorImage
                                      ? layoutObject.floorImage
                                      : "/static/images/upload.png"
                                  }
                                  alt=""
                                />
                              </Button>
                            </label>
                            <div className="close_btn_abs">
                              {layoutObject.floorImage ? (
                                <IconButton
                                  onClick={() => setLayoutObject({ ...layoutObject, floorImage: "" })}
                                >
                                  <ClearIcon />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </div>
                          </Box>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} xs={12}>
                          <Button style={{ marginTop: "23px" }} onClick={() => handleAddLayout(indexForAddLayout)} className="btn btn_secondary">Add</Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ) : null}




                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography className="custom_label">Company Name</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="companyName"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250, // Adjust height
                          // width: 250,     // Adjust width
                        },
                      },
                    }}
                    fullWidth
                    disabled={_id ? true : false}
                    value={formik?.values?.companyName || ""}
                    onChange={(e) =>
                      formik.setFieldValue("companyName", e.target?.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.companyName &&
                      Boolean(formik.errors?.companyName)
                    }
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {companyListing?.length &&
                      companyListing?.map((item: any) => {
                        return (
                          <MenuItem
                            onClick={() => setCompanyId(item?._id)}
                            value={item?._id}
                          >
                            {item?.companyName + " (" + item?.referenceId + ")"}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {formik.touched?.companyName &&
                    formik.errors?.companyName && (
                      <Typography
                        sx={{ fontSize: "12px" }}
                        variant="caption"
                        color="red"
                      >
                        Please select a company
                      </Typography>
                    )}
                </Grid>
                {agentsData?.length ? (
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Assign Aggent
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={_id ? true : false}
                      name="agentName"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust height
                            // width: 250,     // Adjust width
                          },
                        },
                      }}
                      fullWidth
                      value={formik?.values?.agentName || ""}
                      onChange={(e) =>
                        formik.setFieldValue("agentName", e.target?.value)
                      }
                      onBlur={formik.handleBlur}
                      // error={
                      //   formik.touched?.agentName &&
                      //   Boolean(formik.errors?.agentName)
                      // }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {agentsData?.length &&
                        agentsData?.map((item: any) => {
                          return (
                            <MenuItem
                              onClick={() => setSelectedAgentId(item?._id)}
                              value={item?._id}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                ) : (
                  ""
                )}

              </Grid>
              <div className="form_btn">
                <Button type="submit" className="btn btn_primary">
                  Save
                </Button>
                {Boolean(searchParams?.get("draft")) && (
                  <Button className="btn btn_secondary" onClick={saveDraft}>
                    Save as draft
                  </Button>
                )}
                {!_id && (
                  <Button className="btn btn_secondary" onClick={saveDraft}>
                    Save as draft
                  </Button>
                )}
              </div>
            </CardContent>
          </form>
        </Card>

      </div >
    </div >
  );
};

export default AddOffPlanProperty;
