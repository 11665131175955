import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Input,
    Typography,
} from "@mui/material";
import { useLazyGetSpecificPropertyByIdQuery } from "../../services/property";
import PropertyMessages from "../../features/Property/PropertyMessage";
import { ImageModal } from "../../components";

const ClaimedTransactionDetails = () => {
        const { id } = useParams();
        const { state } = useLocation();
        const [fetchApi, { isLoading }] = useLazyGetSpecificPropertyByIdQuery();
        const navigate = useNavigate();
        const [ByIdDetails, setByIdDetails] = useState<any>({});
        const [openModal, setOpenModal] = useState(false);
        const [selectedImage, setSelectedImage] = useState<string | null>(null);


        const fetchDataById = async (_id: any) => {
            try {
                const response = await fetchApi({ userId: _id }).unwrap();
                if (response.statusCode === 200) {
                    setByIdDetails(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        const handleOpenModal = (imageUrl: string) => {
            setSelectedImage(imageUrl);
            setOpenModal(true);
        };

        const handleCloseModal = () => {
            setOpenModal(false);
            setSelectedImage(null);
        };

        function formatNumberWithCommas(value: string | number) {
            const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
            const [integer, decimal] = valueStr.split('.');
            const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
            return result;
        }

        useEffect(() => {
            fetchDataById(id);
        }, [id]);


        return (
            <div>
                <div className="main_layout">
                    <div className="dashboard">
                        <h1 className="mn_hdng">Claimed Property Details</h1>
                        <Button
                            className="btn btn_primary"
                            onClick={() => navigate("/claimed-transactions")}
                        >
                            Back
                        </Button>
                    </div>
                    <Card className="cards">
                        <CardContent sx={{ p: 1 }}>
                            <Grid container spacing={2} className="view_box">
                                <Grid item xs={12} className="view_box_list">
                                    <Grid container spacing={3}>


                                        {ByIdDetails?.interior?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Box>
                                                    <Typography component="h5">Interior Media</Typography>
                                                    <div className="profile_img_grid">
                                                        {ByIdDetails.interior.map((media: string, index: number) => {
                                                            // Detect file type
                                                            const isImage = /\.(jpg|jpeg|png|webp)$/i.test(media) || media.endsWith('blob'); // Excludes GIFs
                                                            const isVideo = /\.(mp4|webm|ogg)$/i.test(media);

                                                            return (
                                                                <figure className="profile_img" style={{ borderRadius: 0 }} key={index}>
                                                                    {isImage && (
                                                                        <img
                                                                            style={{ borderRadius: 0 }}
                                                                            src={media}
                                                                            alt={`property-${index}`}
                                                                            onClick={() => handleOpenModal(media)} // Trigger modal for images
                                                                        />
                                                                    )}
                                                                    {isVideo && (
                                                                        <video
                                                                            style={{ width: '100%', height: '100%' }}
                                                                            controls
                                                                            src={media}
                                                                            controlsList="nodownload"
                                                                        // alt={`property-${index}`}
                                                                        />
                                                                    )}
                                                                    <div className="overlay">
                                                                        {isImage && (
                                                                            <img
                                                                                onClick={() => handleOpenModal(media)} // Overlay click for images
                                                                                src="/static/images/visibility.png"
                                                                                alt="view"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </figure>
                                                            );
                                                        })}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        )}


                                        {ByIdDetails?.exterior?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Box>
                                                    <Typography component="h5">Exterior Media</Typography>
                                                    <div className="profile_img_grid">
                                                        {ByIdDetails.exterior.map((media: string, index: number) => {
                                                            // Detect file type
                                                            const isImage = /\.(jpg|jpeg|png|webp)$/i.test(media) || media.endsWith('blob'); // Excludes GIFs
                                                            const isVideo = /\.(mp4|webm|ogg)$/i.test(media);

                                                            return (
                                                                <figure className="profile_img" style={{ borderRadius: 0 }} key={index}>
                                                                    {isImage && (
                                                                        <img
                                                                            style={{ borderRadius: 0 }}
                                                                            src={media}
                                                                            alt={`property-${index}`}
                                                                            onClick={() => handleOpenModal(media)} // Trigger modal for images
                                                                        />
                                                                    )}
                                                                    {isVideo && (
                                                                        <video
                                                                            style={{ width: '100%', height: '100%' }}
                                                                            controls
                                                                            src={media}
                                                                            controlsList="nodownload"
                                                                        // alt={`property-${index}`}
                                                                        />
                                                                    )}
                                                                    <div className="overlay">
                                                                        {isImage && (
                                                                            <img
                                                                                onClick={() => handleOpenModal(media)} // Overlay click for images
                                                                                src="/static/images/visibility.png"
                                                                                alt="view"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </figure>
                                                            );
                                                        })}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        )}

                                        {ByIdDetails?.garden?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Box>
                                                    <Typography component="h5">Garden Media</Typography>
                                                    <div className="profile_img_grid">
                                                        {ByIdDetails.garden.map((media: string, index: number) => {
                                                            // Detect file type
                                                            const isImage = /\.(jpg|jpeg|png|webp)$/i.test(media) || media.endsWith('blob'); // Excludes GIFs
                                                            const isVideo = /\.(mp4|webm|ogg)$/i.test(media);

                                                            return (
                                                                <figure className="profile_img" style={{ borderRadius: 0 }} key={index}>
                                                                    {isImage && (
                                                                        <img
                                                                            style={{ borderRadius: 0 }}
                                                                            src={media}
                                                                            alt={`property-${index}`}
                                                                            onClick={() => handleOpenModal(media)} // Trigger modal for images
                                                                        />
                                                                    )}
                                                                    {isVideo && (
                                                                        <video
                                                                            style={{ width: '100%', height: '100%' }}
                                                                            controls
                                                                            src={media}
                                                                            controlsList="nodownload"
                                                                        // alt={`property-${index}`}
                                                                        />
                                                                    )}
                                                                    <div className="overlay">
                                                                        {isImage && (
                                                                            <img
                                                                                onClick={() => handleOpenModal(media)} // Overlay click for images
                                                                                src="/static/images/visibility.png"
                                                                                alt="view"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </figure>
                                                            );
                                                        })}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        )}


                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5" style={{ paddingTop: 10 }}>Property Name</Typography>
                                                <Typography component="p">{ByIdDetails?.name || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5" style={{ paddingTop: 10 }}>Owner</Typography>
                                                <Typography component="p">{ByIdDetails?.userId?.name || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Price</Typography>
                                                <Typography component="p">{ByIdDetails?.price ? formatNumberWithCommas(String(ByIdDetails?.price)) : "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Category</Typography>
                                                <Typography component="p">{ByIdDetails?.categoryId?.name || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Property Type</Typography>
                                                <Typography component="p">Off-Plan</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Total Impressions</Typography>
                                                <Typography component="p">{ByIdDetails?.impressions || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Total Clicks</Typography>
                                                <Typography component="p">{ByIdDetails?.propertyCount || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Total Leads</Typography>
                                                <Typography component="p">{ByIdDetails?.leads?.totalLeads || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Permit number</Typography>
                                                <Typography component="p">{ByIdDetails?.advertisementPermitNo || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Developed By</Typography>
                                                <Typography component="p">{ByIdDetails?.developedBy || "-"}</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box>
                                                <Typography component="h5">Address</Typography>
                                                <Typography component="p">{ByIdDetails?.address || "-"}</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box>
                                                <Typography component="h5">Key Features</Typography>
                                                <Typography component="p">{ByIdDetails?.keyFeature || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Typography component="h5">Specifications</Typography>
                                                <Typography component="p">{ByIdDetails?.specifications || "-"}</Typography>
                                            </Box>
                                        </Grid>

                                        {ByIdDetails?.amenities?.length > 0 && (
                                            <Grid item xs={12} >
                                                <Typography component="h5" style={{
                                                    color: "#717171",
                                                    fontSize: "13px",
                                                    fontWeight: "500",
                                                    marginBottom: "5px",
                                                }} >Amenities</Typography>
                                                <Grid container spacing={2} >
                                                    {ByIdDetails.amenities.map((amenity: any, index: number) => (
                                                        <Grid item lg={3} md={3} sm={3} xs={12} key={index}>
                                                            <Typography style={{ fontSize: "15px", fontWeight: "600" }} component="p">{index + 1 + " ."}{amenity?.name}</Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {ByIdDetails?.validationQr ? (
                                            <Grid item xs={6}>
                                                <Box>
                                                    <Typography component="h5">DLD Permit Number</Typography>
                                                    <div className="qr_img">
                                                        <figure
                                                        >
                                                            <img
                                                                width={"180px"}
                                                                src={`data:image/png;base64,${ByIdDetails?.validationQr || ''}`}
                                                                alt="Image"
                                                            />
                                                        </figure>
                                                    </div>
                                                </Box>
                                            </Grid>
                                        ) : ("")}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        {ByIdDetails?.paymentPlans?.length ? (
                            <CardContent sx={{ p: 2 }}>
                                <h3>Payment Plans</h3>
                                <div className="payment_card_main">
                                    {ByIdDetails?.paymentPlans?.map((item: any, index: number) => {
                                        return (
                                            <Card className="cards" style={{ width: "30%" }}>
                                                <div className="payment_card">
                                                    <h4>Payment Option {index + 1}</h4>
                                                </div>
                                                <div>
                                                    <h6>Down Payment: <span>{item?.downPaymentPercent}% ({item?.downPaymentInstallments} Installments)</span></h6>
                                                    <h6>During Construction: <span>{item?.duringConsPercent}% ({item?.duringConsInstallments} Installments)</span></h6>
                                                    <h6>On Handover: <span>{item?.onHandoverPercent}% ({item?.onHandoverInstallments} Installments)</span></h6>
                                                </div>
                                            </Card>
                                        )
                                    })}
                                </div>
                            </CardContent>
                        ) : ("")}

                        {ByIdDetails?.units?.length ? (
                            <CardContent sx={{ p: 2 }}>
                                <h3>Units</h3>
                                <div className="payment_card_main">
                                    {ByIdDetails?.units?.map((item: any, index: number) => {
                                        return (
                                            <Card className="cards" style={{ width: "30%" }} key={`unit-${index}`}>
                                                <div className="payment_card">
                                                    <h4>Unit {index + 1}</h4>
                                                </div>
                                                <div className="payment_card" style={{ gap: "5px" }}>
                                                    <h5>{item?.beds || "0"} Beds</h5>
                                                    <h5>{item?.price || "0"} AED</h5>
                                                    <h5>{item?.size || "0"} Sq ft</h5>
                                                    <h5>{item?.dialCode + item?.enquiryNo || "0"} </h5>
                                                </div>
                                                {item?.layouts?.length ? (
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <div className="">
                                                            {item?.layouts?.map((layoutItem: any, layoutIndex: number) => {
                                                                return (
                                                                    <Card
                                                                        className="cards"
                                                                        style={{ width: "100%", marginTop: "10px" }}
                                                                        key={`layout-${index}-${layoutIndex}`}
                                                                    >
                                                                        <div className="payment_card">
                                                                            <h4>Layout {layoutIndex + 1}</h4>
                                                                        </div>
                                                                        <div className="payment_card">
                                                                            <div>
                                                                                <h5>{layoutItem?.layoutType}</h5>
                                                                                <h5>{layoutItem?.size}</h5>
                                                                            </div>
                                                                            <div >
                                                                                {layoutItem?.floorImage ? (
                                                                                    <img
                                                                                        width={"60px"}
                                                                                        src={layoutItem?.floorImage}
                                                                                        alt="floor image"
                                                                                    />
                                                                                ) : null}

                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                );
                                                            })}
                                                        </div>
                                                    </Grid>
                                                ) : null}
                                            </Card>
                                        );
                                    })}
                                </div>
                            </CardContent>
                        ) : ("")}
                    </Card>
                   
                </div>
                <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />

            </div >
        );
    };

export default ClaimedTransactionDetails;
