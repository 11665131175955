import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Input,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { useLazyGetSpecificPropertyByIdQuery } from "../../services/property";
import PropertyMessages from "../../features/Property/PropertyMessage";
import { ImageModal } from "../../components";
import Pagination from "../../components/Pagination";
import { useLazyGetAllLeadsQuery } from "../../services/propertyLeads";
import { errorToast } from "../../helpers";
import VisibilityIcon from "@mui/icons-material/Visibility";



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}




const PropertyDetails = () => {
  const { _id } = useParams();
  const { state } = useLocation();
  const [fetchApi] = useLazyGetSpecificPropertyByIdQuery();
  const navigate = useNavigate();
  const [ByIdDetails, setByIdDetails] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [value1, setValue1] = React.useState(0);
  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount1] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [dataCount, setDataCount] = useState({
    totalEmailLeads: 0,
    totalInAppChatLeads: 0,
    totalPhoneLeads: 0,
    totalWhatsappLeads: 0,
  });
  const [getLeads] = useLazyGetAllLeadsQuery();
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getLeads({
        page: page,
        search: "",
        limit: limit,
        startDate: "",
        endDate: "",
        leadType: value1 === 1 ? 1 : value1 === 3 ? 2 : value1 === 2 ? 3 : value1 === 0 ? 4 : 4,
        propertyId: _id
      }).unwrap();
      if (response?.statusCode === 200) {
        setRows(response?.data?.rows);
        setTotalCount1(response?.data?.count);
        setDataCount(
          {
            totalEmailLeads: response?.data?.totalEmailLeads || 0,
            totalInAppChatLeads: response?.data?.totalInAppChatLeads || 0,
            totalPhoneLeads: response?.data?.totalPhoneLeads || 0,
            totalWhatsappLeads: response?.data?.totalWhatsappLeads || 0,
          }
        )
      }
      setIsLoading(false);
    } catch (error: any) {
      errorToast(error?.data?.message)
      setIsLoading(false);
    }
  }

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    return result;
  }

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);

  useEffect(() => {
    if (_id) { fetchData() }
  }, [value1])


  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Property Listing Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => { state?.from === "lead" ? navigate("/manage-leads", { state: state?.tab }) : navigate("/property-listing", { state: state?.tab }) }}
          >
            Back
          </Button>
        </div>


        <Card className="cards">

          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >

              <Tab label="Property Details" {...a11yProps(0)} />
              <Tab label="Property Leads" {...a11yProps(1)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2} className="view_box">
                  <Grid item xs={12} className="view_box_list">
                    <Grid container spacing={3}>
                      {ByIdDetails?.interior?.length > 0 && (
                        <Grid item xs={12}>
                          <Box>
                            <Typography component="h5">Interior Media</Typography>
                            <div className="profile_img_grid">
                              {ByIdDetails.interior.map((media: string, index: number) => {
                                // Detect file type
                                const isImage = /\.(jpg|jpeg|png|webp)$/i.test(media) || media.endsWith('blob'); // Excludes GIFs
                                const isVideo = /\.(mp4|webm|ogg)$/i.test(media);

                                return (
                                  <figure className="profile_img" style={{ borderRadius: 0 }} key={index}>
                                    {isImage && (
                                      <img
                                        style={{ borderRadius: 0 }}
                                        src={media}
                                        alt={`property-${index}`}
                                        onClick={() => handleOpenModal(media)} // Trigger modal for images
                                      />
                                    )}
                                    {isVideo && (
                                      <video
                                        style={{ width: '100%', height: '100%' }}
                                        controls
                                        src={media}
                                        controlsList="nodownload"
                                      // alt={`property-${index}`}
                                      />
                                    )}
                                    <div className="overlay">
                                      {isImage && (
                                        <img
                                          onClick={() => handleOpenModal(media)} // Overlay click for images
                                          src="/static/images/visibility.png"
                                          alt="view"
                                        />
                                      )}
                                    </div>

                                  </figure>
                                );
                              })}
                            </div>
                          </Box>
                        </Grid>
                      )}


                      {ByIdDetails?.exterior?.length > 0 && (
                        <Grid item xs={12}>
                          <Box>
                            <Typography component="h5">Exterior Media</Typography>
                            <div className="profile_img_grid">
                              {ByIdDetails.exterior.map((media: string, index: number) => {
                                // Detect file type
                                const isImage = /\.(jpg|jpeg|png|webp)$/i.test(media) || media.endsWith('blob'); // Excludes GIFs
                                const isVideo = /\.(mp4|webm|ogg)$/i.test(media);

                                return (
                                  <figure className="profile_img" style={{ borderRadius: 0 }} key={index}>
                                    {isImage && (
                                      <img
                                        style={{ borderRadius: 0 }}
                                        src={media}
                                        alt={`property-${index}`}
                                        onClick={() => handleOpenModal(media)} // Trigger modal for images
                                      />
                                    )}
                                    {isVideo && (
                                      <video
                                        style={{ width: '100%', height: '100%' }}
                                        controls
                                        src={media}
                                        controlsList="nodownload"
                                      // alt={`property-${index}`}
                                      />
                                    )}
                                    <div className="overlay">
                                      {isImage && (
                                        <img
                                          onClick={() => handleOpenModal(media)} // Overlay click for images
                                          src="/static/images/visibility.png"
                                          alt="view"
                                        />
                                      )}
                                    </div>
                                  </figure>
                                );
                              })}
                            </div>
                          </Box>
                        </Grid>
                      )}

                      {ByIdDetails?.garden?.length > 0 && (
                        <Grid item xs={12}>
                          <Box>
                            <Typography component="h5">Garden Media</Typography>
                            <div className="profile_img_grid">
                              {ByIdDetails.garden.map((media: string, index: number) => {
                                // Detect file type
                                const isImage = /\.(jpg|jpeg|png|webp)$/i.test(media) || media.endsWith('blob'); // Excludes GIFs
                                const isVideo = /\.(mp4|webm|ogg)$/i.test(media);

                                return (
                                  <figure className="profile_img" style={{ borderRadius: 0 }} key={index}>
                                    {isImage && (
                                      <img
                                        style={{ borderRadius: 0 }}
                                        src={media}
                                        alt={`property-${index}`}
                                        onClick={() => handleOpenModal(media)} // Trigger modal for images
                                      />
                                    )}
                                    {isVideo && (
                                      <video
                                        style={{ width: '100%', height: '100%' }}
                                        controls
                                        src={media}
                                        controlsList="nodownload"
                                      // alt={`property-${index}`}
                                      />
                                    )}
                                    <div className="overlay">
                                      {isImage && (
                                        <img
                                          onClick={() => handleOpenModal(media)} // Overlay click for images
                                          src="/static/images/visibility.png"
                                          alt="view"
                                        />
                                      )}
                                    </div>
                                  </figure>
                                );
                              })}
                            </div>
                          </Box>
                        </Grid>
                      )}

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>Property Name</Typography>
                          <Typography component="p">{ByIdDetails?.name || "-"}

                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>Property Id</Typography>
                          <Typography component="p">{ByIdDetails?.referenceId || "-"}

                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>Badge</Typography>
                          <Typography component="p">
                            <div>
                              {ByIdDetails?.isPropertyBoost ? (
                                <img style={{
                                  maxHeight: "20px",
                                  maxWidth: "20px",
                                }} src="/static/images/boost.png" alt="" />
                              ) : ("")}


                              {ByIdDetails?.isVerified ? (
                                <img
                                  className="property_details_badge" style={{
                                    maxHeight: "20px",
                                    maxWidth: "20px",
                                    marginLeft: "5px"
                                  }} src="/static/images/verfy_icon.svg" alt="" />
                              ) : ("")}

                              {ByIdDetails?.qualityScore >= 85 ? (
                                <img style={{
                                  maxHeight: "20px",
                                  maxWidth: "20px",
                                  marginLeft: "5px"
                                }} src="/static/images/verify_ico.svg" alt="" />
                              ) : ("")}



                            </div>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>Owner</Typography>
                          <Typography component="p">{ByIdDetails?.userId?.name || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Price</Typography>
                          <Typography component="p">{ByIdDetails?.price ? formatNumberWithCommas(String(ByIdDetails?.price)) : "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Category</Typography>
                          <Typography component="p">{ByIdDetails?.categoryId?.name || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Property Type</Typography>
                          <Typography component="p">{ByIdDetails?.propertyTypeId?.name || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Beds</Typography>
                          <Typography component="p">{ByIdDetails?.beds || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Total Impressions</Typography>
                          <Typography component="p">{ByIdDetails?.impressions || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Total Clicks</Typography>
                          <Typography component="p">{ByIdDetails?.propertyCount || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Total Leads</Typography>
                          <Typography component="p">{ByIdDetails?.leads?.totalLeads || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Permit number</Typography>
                          <Typography component="p">{ByIdDetails?.advertisementPermitNo || "-"}</Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Baths</Typography>
                          <Typography component="p">{ByIdDetails?.baths || "-"}</Typography>
                        </Box>
                      </Grid>


                      {ByIdDetails?.serviceCharges ? (
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Service Charges</Typography>
                            <Typography component="p">{ByIdDetails?.serviceCharges || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ("")}
                      {ByIdDetails?.finacialStatus ? (
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Financial Status</Typography>
                            <Typography component="p">{ByIdDetails?.finacialStatus === 1 ? "Cash" : "Mortgage"}</Typography>
                          </Box>
                        </Grid>
                      ) : ("")}

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Address</Typography>
                          <Typography component="p">{ByIdDetails?.address || "-"}</Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">Key Features</Typography>
                          <Typography component="p">{ByIdDetails?.keyFeature || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">Specifications</Typography>
                          <Typography component="p">{ByIdDetails?.specifications || "-"}</Typography>
                        </Box>
                      </Grid>
                      {ByIdDetails?.floorPlan ? (
                        <Grid item xs={6}>
                          <Box>
                            <Typography component="h5">Floor Plan</Typography>
                            {ByIdDetails?.floorPlan ? (
                              <Box className="upload_image">
                                <label htmlFor="icon-button-file-vdo">
                                  <Input
                                    sx={{ display: "none" }}
                                    id="icon-button-file-vdo"
                                    type="file"
                                    inputProps={{
                                      type: "file",
                                      accept: "application/pdf",
                                    }}
                                    disabled={true}
                                  />
                                  <Button
                                    component="span"
                                    className="upload_image_btn"
                                  >
                                    <img
                                      style={{
                                        borderRadius: "5px",
                                        width: "300px",
                                        height: "200px",
                                        border: "solid 2px whitesmoke",
                                        objectFit: "contain"
                                      }}
                                      onClick={() => ByIdDetails?.floorPlan ? window.open(ByIdDetails?.floorPlan) : null}
                                      src="/static/images/pdf.png"
                                      alt=""
                                    />
                                  </Button>
                                </label>
                              </Box>
                            ) : ("")}
                          </Box>

                        </Grid>
                      ) : ("")}
                      {ByIdDetails?.validationQr ? (
                        <Grid item xs={6}>
                          <Box>
                            <Typography component="h5">DLD Permit Number</Typography>
                            <div className="qr_img">
                              <figure
                              >
                                <img
                                  width={"180px"}
                                  src={`data:image/png;base64,${ByIdDetails?.validationQr || ''}`}
                                  alt="Image"
                                />
                              </figure>
                            </div>
                          </Box>
                        </Grid>
                      ) : ("")}
                      {ByIdDetails?.amenities?.length > 0 && (
                        <Grid item xs={12} >
                          <Typography component="h5" style={{
                            color: "#717171",
                            fontSize: "13px",
                            fontWeight: "500",
                            marginBottom: "5px",
                          }} >Amenities</Typography>
                          <Grid container spacing={2} >
                            {ByIdDetails.amenities.map((amenity: any, index: number) => (
                              <Grid item lg={3} md={3} sm={3} xs={12} key={index}>
                                <Typography style={{ fontSize: "15px", fontWeight: "600" }} component="p">{index + 1 + " ."}{amenity?.name}</Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )}

                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <div className="dashboard" style={{ paddingTop: 35 }}>
                <h2 className="mn_hdng">Property Chat Details</h2>
              </div>
              <PropertyMessages />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>

              <Box className="custom_tabs">

                <Tabs
                  value={value1}
                  onChange={handleChange1}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab
                    label={`Whatsapp (${dataCount?.totalWhatsappLeads})`}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={`Calls (${dataCount?.totalPhoneLeads})`}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={`Email (${dataCount?.totalEmailLeads})`}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={`In-app chat (${dataCount?.totalInAppChatLeads})`}
                    {...a11yProps(3)}
                  />
                </Tabs>

                <CustomTabPanel value={value1} index={0}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>User Name</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Property Name</TableCell>
                          <TableCell>Company Name</TableCell>
                          <TableCell>Agent name</TableCell>

                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {!rows?.length ? (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No Lead found
                            </TableCell>
                          </TableRow>
                        ) : (
                          rows?.map((row: any, i: number) => (
                            <TableRow key={row?._id}>
                              {" "}
                              <TableCell align="center">
                                {(page - 1) * 10 + i + 1}
                              </TableCell>

                              <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                              <TableCell>
                                {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                              </TableCell>
                              <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                              <TableCell>
                                {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                              </TableCell>
                              <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3 ? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                              <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>


                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {rows?.length > 0 ? (
                    <Pagination
                      setPage={setPage}
                      module={rows}
                      page={page}
                      onPageChange={onPageChange}
                      totalPages={totalPages}
                      limit={limit}
                      setLimit={setLimit}
                    />
                  ) : (
                    ""
                  )}

                </CustomTabPanel>
                <CustomTabPanel value={value1} index={1}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>User Name</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Property Name</TableCell>
                          <TableCell>Company Name</TableCell>
                          <TableCell>Agent name</TableCell>

                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {!rows?.length ? (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No Lead found
                            </TableCell>
                          </TableRow>
                        ) : (
                          rows?.map((row: any, i: number) => (
                            <TableRow key={row?._id}>
                              {" "}
                              <TableCell align="center">
                                {(page - 1) * 10 + i + 1}
                              </TableCell>

                              <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                              <TableCell>
                                {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                              </TableCell>
                              <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                              <TableCell>
                                {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                              </TableCell>
                              <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3 ? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                              <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>


                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {rows?.length > 0 ? (
                    <Pagination
                      setPage={setPage}
                      module={rows}
                      page={page}
                      onPageChange={onPageChange}
                      totalPages={totalPages}
                      limit={limit}
                      setLimit={setLimit}
                    />
                  ) : (
                    ""
                  )}

                </CustomTabPanel>
                <CustomTabPanel value={value1} index={2}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>User Name</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Property Name</TableCell>
                          <TableCell>Company Name</TableCell>
                          <TableCell>Agent name</TableCell>

                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {!rows?.length ? (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No Lead found
                            </TableCell>
                          </TableRow>
                        ) : (
                          rows?.map((row: any, i: number) => (
                            <TableRow key={row?._id}>
                              {" "}
                              <TableCell align="center">
                                {(page - 1) * 10 + i + 1}
                              </TableCell>

                              <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                              <TableCell>
                                {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                              </TableCell>
                              <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                              <TableCell>
                                {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                              </TableCell>
                              <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3 ? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                              <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>


                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {rows?.length > 0 ? (
                    <Pagination
                      setPage={setPage}
                      module={rows}
                      page={page}
                      onPageChange={onPageChange}
                      totalPages={totalPages}
                      limit={limit}
                      setLimit={setLimit}
                    />
                  ) : (
                    ""
                  )}

                </CustomTabPanel>
                <CustomTabPanel value={value1} index={3}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>User Name</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Property Name</TableCell>
                          <TableCell>Company Name</TableCell>
                          <TableCell>Agent name</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!rows?.length ? (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No Lead found
                            </TableCell>
                          </TableRow>
                        ) : (
                          rows?.map((row: any, i: number) => (
                            <TableRow key={row?._id}>
                              {" "}
                              <TableCell align="center">
                                {(page - 1) * 10 + i + 1}
                              </TableCell>

                              <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                              <TableCell>
                                {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                              </TableCell>
                              <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                              <TableCell>
                                {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                              </TableCell>
                              <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3 ? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                              <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>


                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {rows?.length > 0 ? (
                    <Pagination
                      setPage={setPage}
                      module={rows}
                      page={page}
                      onPageChange={onPageChange}
                      totalPages={totalPages}
                      limit={limit}
                      setLimit={setLimit}
                    />
                  ) : (
                    ""
                  )}

                </CustomTabPanel>
              </Box>

            </CustomTabPanel>

          </Box>

        </Card>

      </div>
      <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />

    </div>
  );
};

export default PropertyDetails;
