import {
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useResetPasswordMutation } from "../services/auth";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { errorToast, successToast } from "../helpers";
import Loader from "../helpers/constants/Loader";
import { generateResponsiveStyle } from "../utils/modalStyle";
import { useUserResetPasswordMutation } from "../services/settings";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    selectedId: string;
    setSelectedId: Dispatch<SetStateAction<string>>;
};

const ResetPasswordModal = ({ open, setOpen, selectedId, setSelectedId }: props) => {
    const navigate = useNavigate();
    const style = generateResponsiveStyle();
    const [resetPassword] = useUserResetPasswordMutation();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleshowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            passwordConfirmation: "",
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .label("New Password")
                .required("New password is required.")
                .matches(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&)"
                )
                .notOneOf(
                    [Yup.ref("oldPassword"), null],
                    "New password cannot be the same as the current password."
                ),

            passwordConfirmation: Yup.string()
                .oneOf(
                    [Yup.ref("newPassword")],
                    "Passwords must match."
                )
                .required("Confirm password is required."),
        }),

        onSubmit: async () => {
            formik.setSubmitting(true);

            let body = {
                newPassword: formik.values.newPassword,
            };

            try {
                let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
                const response = await resetPassword({ body: encryptedBody, id: selectedId }).unwrap();
                if (response?.statusCode === 200) {
                    successToast("Password reset successfully");
                    setOpen(false);
                    formik.resetForm()
                    setSelectedId("")
                }
            } catch (error: any) {
                errorToast(error?.data?.message || "");
                setOpen(false);
                formik.resetForm()
                setSelectedId("")
            }
            formik.setSubmitting(false);

        },
    });




    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    setOpen(false);
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <div className="modal_title">
                    <Typography id="modal-modal-title" component="h2">
                        Reset Password
                    </Typography>
                    <CloseIcon className="cross_icn_logout" onClick={() => {
                        setOpen(false)
                        formik.resetForm()
                        setSelectedId("")
                    }} />
                </div>
                <div className="modal_body">
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl sx={{ mb: 2, width: "100%" }}>
                            <Typography className="custom_label">Password</Typography>
                            <TextField
                                sx={{ m: 0 }}
                                hiddenLabel
                                placeholder="Password"
                                fullWidth
                                margin="normal"
                                name="newPassword"
                                id="newPassword"
                                className="text_field"
                                type={showPassword ? "text" : "password"}
                                variant="outlined"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.newPassword}
                                helperText={
                                    formik.touched.newPassword && formik.errors.newPassword
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment className="eye_btn" position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <FormControl sx={{ width: "100%" }}>
                            <Typography className="custom_label">Confirm Password</Typography>
                            <TextField
                                sx={{ m: 0 }}
                                className="text_field"
                                hiddenLabel
                                placeholder="Confirm Password"
                                fullWidth
                                margin="normal"
                                name="passwordConfirmation"
                                id="passwordConfirmation"
                                type={showConfirmPassword ? "text" : "password"}
                                variant="outlined"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.passwordConfirmation}
                                helperText={
                                    formik.touched.passwordConfirmation &&
                                    formik.errors.passwordConfirmation
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment className="eye_btn" position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleshowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <Box sx={{ pt: 3 }}>
                            <Button
                                className="btn btn_primary"
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Box>
                    </form>
                </div>
            </Box>
        </Modal>
    );
};

export default ResetPasswordModal;
