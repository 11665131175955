import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};


interface CategoryResponse extends CommonResponseType {
  data: any;
}

interface QueryParams {
  userId?: any;
}

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddSubscription: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.subscription,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GETSUBSCRIPTION"]
    }),

    getAllSubscription: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit: number, subscriptionPack?: number | string, forTrialPeriod?: boolean }>({
      query: ({ page, search, limit, subscriptionPack, forTrialPeriod }) => {
        let url = `${END_POINTS.subscription}?revenueType=1&page=${page}&search=${search}&limit=${limit}`;
        if (subscriptionPack) {
          url = url + `&subscriptionPack=${subscriptionPack}`
        }
        if (forTrialPeriod) {
          url = url + `&forTrialPeriod=true`
        }
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 3,
      providesTags: ["GETSUBSCRIPTION"]
    }),


    getSpecificSubscriptionById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId }) => ({
        url: `${END_POINTS.subscription}/${userId}`,
        method: 'GET',
      }),
    }),


    EditSubscription: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.subscription}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GETSUBSCRIPTION"]
    }),



    deleteByIdSubscription: builder.mutation<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.subscription}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GETSUBSCRIPTION"]
    }),

  }),
});

export const {
  usePostAddSubscriptionMutation,
  useLazyGetAllSubscriptionQuery,
  useGetAllSubscriptionQuery,
  useLazyGetSpecificSubscriptionByIdQuery,
  useEditSubscriptionMutation,
  useDeleteByIdSubscriptionMutation,
} = authApi;
