import { useEffect, useState } from 'react';
import { Box, Popover, Tab, Tabs } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}
interface CategoryOne {
  _id: string;
  name: string;
  image: string;
}

interface CategoryName {
  _id: string;
  name: string;
  image: string;
}
interface PropertyGroup {
  categoryName: CategoryName; // Define categoryName separately
  subCategory: { [key: string]: CategoryOne[] }; // Dynamic keys to hold the categories
}

interface props {
  categoryData?:any;
  handleReset: () => void;
  category?: string;
  parentCategory?: string;
  setCategory?: any;
  visibleName: string;
  setVisibleName: any;
  setParentCategory?: any;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CategorySelector = ({
  category,
  categoryData,
  handleReset,
  setCategory,
  setParentCategory,
  visibleName,
  setVisibleName,
}: props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [value, setValue] = React.useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setParentCategory(newValue);
    setVisibleName(
      categoryData?.find((item:any) => item?.categoryName?._id === newValue)
        ?.categoryName?.name || '',
    );
  };

  useEffect(() => {
    if (categoryData?.[0]?.categoryName?._id) {
      setValue(categoryData[0]?.categoryName?._id);
    }
  }, [categoryData]);

  return (
    <>
      <div className="beds_select_home new_beds" onClick={handleOpen}>
        {visibleName || 'Residential'}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="catSelector_pop"
      >
        <Box p={2} className="bed_bath_select text_tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable" // Makes tabs scrollable
            scrollButtons="auto"
          >
            {categoryData?.map((item: any, index: any) => (
              <Tab
                label={item?.categoryName?.name || ''}
                {...a11yProps(item?.categoryName?._id)}
                value={item?.categoryName?._id}
              />
            ))}
          </Tabs>
          <CustomTabPanel value={value} index={value}>
            <ul className="catSelector_list">
              {categoryData
                ?.find((item:any) => item?.categoryName?._id === value)
                ?.subCategory[value]?.map((subCat:any) => (
                  <li
                    key={subCat?._id}
                    className={category === subCat?._id ? 'active' : ''}
                    onClick={() => {
                      setVisibleName(subCat?.name || '');
                      setCategory(subCat?._id);
                      
                      handleClose();
                    }}
                  >
                    {subCat?.name}
                  </li>
                ))}
            </ul>
          </CustomTabPanel>

          
        </Box>
      </Popover>
    </>
  );
};

export default CategorySelector;