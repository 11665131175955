import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { generateResponsiveStyle } from "../utils/modalStyle";
import CloseIcon from "@mui/icons-material/Close";
import { isString } from "../utils/validation";
import { warnToast } from "../helpers";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
  name: string;
};

const WarnModal = ({ open, setOpen, handleDelete, name }: props) => {
  const style = generateResponsiveStyle();
  const deleteKey = process.env.REACT_APP_DELETE_KEY;
  const [input, setInput] = useState<string>("");
  console.log('input :', input);

  const verifyDeleteKey = () => {
    if (input === deleteKey) {
      handleDelete();
      setOpen(false);
    } else {
      warnToast("Invalid delete key");
      return;
    }
  }




  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal_body logout_body">
          <CloseIcon className="cross_icn_logout" onClick={() => setOpen(false)} />
          <Typography
            id="modal-modal-title"
            component="h2"
          >
            Are you sure you want to delete this {name}?
          </Typography>



          <div style={{ marginLeft: "10%", marginTop: "15px", width: "80%" }}>
            <TextField
              hiddenLabel
              type="text"
              className="text_field"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 15 }}
              placeholder="Enter Delete key"
              onChange={(val) => {
                if (
                  val.target.value === " " ||
                  val.target.value === "."
                ) {
                } else if (val.target.value) {
                  setInput(val.target.value)
                }
              }}

            />
          </div>


          <div className="form_btn">
            <Button
              className="btn btn_primary"
              onClick={() => {
                verifyDeleteKey()
              }}
              disabled={input === "" ? true : false}
            >
              Yes
            </Button>

            <Button
              className="btn btn_secondary"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default WarnModal;
