import Compressor from "compressorjs";
import { END_POINTS } from "../helpers";
import { MEDIA_URL } from "../helpers/constants/urls";

interface UploadResponse {
  statusCode: number;
  data: any;
  message: string;
}

export const UploadMedia = (imageObject: any): Promise<UploadResponse> => {
  return new Promise(async (resolve, reject) => {
    new Compressor(imageObject, {
      quality: 0.8,
      success: async (compressedResult: any) => {
        const formData = new FormData();
        formData.append("file", compressedResult as any);
        // const encryptedFormData = generateEncryptedKeyBody(formData);
        let headers = {
          Accept: "application/json",
        };

        try {
          const res = await fetch(MEDIA_URL + END_POINTS?.uploadFiles, {
            method: "POST",
            headers,
            body: formData,
          });
          let response = await res.json();
          resolve(response); // Resolve the Promise with the response
        } catch (error) {
          console.log(error, ">>>>>>>>>");
          reject(error); // Reject the Promise with the error
        }
      },
    });
  });
};
export const UploadVideo = (imageObject: any): Promise<UploadResponse> => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append("file", imageObject as any);
    let headers = {
      Accept: "application/json",
    };

    try {
      const res = await fetch(MEDIA_URL + END_POINTS.uploadFiles, {
        method: "POST",
        headers,
        body: formData,
      });
      let response = await res.json();
      resolve(response);
    } catch (error) {
      console.log(error, ">>>>>>>>>");
      reject(error);
    }
  });
};

export const Uploadpdf = async (imageObject: any) => {
  const formData = new FormData();

  formData.append("file", imageObject as any);

  // const token = getToken ? JSON.parse(`${getToken}`) : null;
  let headers = {
    // "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };
  try {
    const res = await fetch(MEDIA_URL + END_POINTS.uploadFiles, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};

// export const UploadCsv = async (csvObject: any) => {
//   const formData = new FormData();

//   formData.append("file", csvObject as any);

//   const getToken = await getFromStorage(STORAGE_KEYS.token);
//   const token = getToken ? JSON.parse(`${getToken}`) : null;
//   let headers = {
//     // "Content-Type": "multipart/form-data",
//     Accept: "application/json",
//     Authorization: "Bearer " + token,
//   };
//   try {
//     const res = await fetch(MEDIA_URL + END_POINTS.bulkUploadProducts, {
//       method: "POST",
//       headers,
//       body: formData,
//     });
//     let response = await res.json();
//     return response;
//   } catch (error) {
//     console.log(error, ">>>>>>>>>");
//     return error;
//   }
// };
export const handleDownload = async (fileUrl: string, fileName: string) => {
  try {
    const response = await fetch(fileUrl, { mode: "cors", cache: "no-cache" });
    // const response = await fetch(proxyUrl + fileUrl);
    if (!response.ok) throw new Error("Failed to fetch file");
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = `${fileName}_${Date.now()}.${fileUrl.split(".").pop()}`;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }, 100);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}