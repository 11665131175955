import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';

// import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import {
  usePostAddSubscriptionMutation,
  useEditSubscriptionMutation,
  useLazyGetSpecificSubscriptionByIdQuery,
} from "../../services/subscription";
import { errorToast, successToast, warnToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";

import { CommonBody, SubscriptionPackage } from "../../types/General";
import Loader from "../../helpers/constants/Loader";
import { useLazyGetAllTypeOfCompaniesQuery, useLazyGetPendingCompanyQuery } from "../../services/users";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import { isNumber, isNumberPrice } from "../../utils/validation";
import moment from "moment";

const AddTrialSubscription = () => {
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const { _id } = useParams();
  const [contract, setContract] = useState<any>(null);
  const [ByIdDetails, setByIdDetails] = useState<SubscriptionPackage>();
  const [fetchById] = useLazyGetSpecificSubscriptionByIdQuery();
  const [addSubscription, { isLoading }] = usePostAddSubscriptionMutation();
  const [updateSubscription] = useEditSubscriptionMutation();
  const [companies, setCompanies] = useState<any>([]);
  const [getApprovedCompany] = useLazyGetAllTypeOfCompaniesQuery();


  const [endDateSend, setEndDateSend] = useState<string>("");
  console.log('endDateSend2121 :', endDateSend);
  const [startDateSend, setStartDateSend] = useState<string>("");
  console.log('startDateSend 2121:', startDateSend);

  const handleDateChange1 = (event: any) => {
    formik.setFieldValue("trialPeriodEndDate", event.target.value)

    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {

      const utcDate = moment(selectedDate)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]')
      // new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 23, 59, 59));
      setEndDateSend(utcDate);

    } else {
      console.error("Invalid date selected");
    }
  };

  const handleDateChange = (event: any) => {
    formik.setFieldValue("trialPeriodStartDate", event.target.value)
    // setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = moment(selectedDate)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]')
      // new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));
      setStartDateSend(utcDate);

    } else {
      console.error("Invalid date selected");
    }
  };





  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        setContract(null);
        return;
      }

      let res;
      if (file.type === "application/pdf") {
        res = await Uploadpdf(file);
        if (res?.statusCode === 200) {
          setContract(res?.data?.[0]?.location); // Assuming pdfUrl is the URL of the uploaded PDF
        } else {
          errorToast(res?.message);
        }
      } else {
        res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setContract(res?.data?.[0]?.location); // Assuming image is the URL of the uploaded image
        } else {
          errorToast(res?.message);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      errorToast("Error uploading file. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: ByIdDetails?.name || "",
      description: ByIdDetails?.description || "",
      // agents: ByIdDetails?.noOfAgents || "",
      companyId: ByIdDetails?.companyId || "",
      trialPeriodStartDate: "",
      trialPeriodEndDate: "",
      tokens: ByIdDetails?.token || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Subscription Name is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),


      description: Yup.string()
        .required("Description is required")
        .max(300, "Maximum 300 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      tokens: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed"),
      trialPeriodStartDate: Yup.string()
        .required("Start Date is required"),
      trialPeriodEndDate: Yup.string()
        .required("End Date is required")
    }),
    onSubmit: async (values) => {

      if (selectedCompany === "") {
        warnToast("Please select a company");
        return;
      }
      if (!contract) {
        warnToast("Please Upload a contract");
        return;
      }

      let body: any = {
        name: values.name,
        description: values.description,
        userType: "5",
        userId: selectedCompany || "",
        contract: contract || "",
        token: Number(values.tokens),
        trialPeriodStartDate: startDateSend || null,
        trialPeriodEndDate: endDateSend || null,
        // noOfAgents: Number(values.agents),

      };

      if (!_id) {
        body.forTrialPeriod = true
      }

      console.log(body, "body");

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

        if (_id) {
          const response = await updateSubscription({
            id: _id,
            body: encryptedBody,
          }).unwrap();
          if (response.statusCode === 200) {
            successToast("Trial Subscription Updated Successfully");
            navigate("/manage-trial-subscription");
          } else {
            errorToast(response.message);
          }
        } else {
          const response = await addSubscription(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast("Trial Subscription Added Successfully");
            navigate("/manage-trial-subscription");
          } else {
            errorToast(response.message);
          }
        }
      } catch (e: any) {
        console.error("API call failed", e);
        errorToast(e?.data?.message);
      }
    },
  });

  const fetchCompanies = async () => {
    try {
      const response = await getApprovedCompany({}).unwrap();
      if (response.statusCode === 200) {
        setCompanies(response?.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchById({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
        setContract(response?.data?.contract || "");
        setSelectedCompany(response?.data?.userId?.id || "");
        setStartDateSend(response?.data?.trialPeriodStartDate);
        setEndDateSend(response?.data?.trialPeriodEndDate);
        // setEndDate(moment(response.data.bonusEndDate).utc().format("YYYY-MM-DD"));

      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        name: ByIdDetails?.name || "",
        description: ByIdDetails?.description || "",
        companyId: ByIdDetails?.companyId || "",
        tokens: ByIdDetails?.token || "",
        // agents: ByIdDetails?.noOfAgents || "",
        trialPeriodStartDate: ByIdDetails?.trialPeriodStartDate ? moment(ByIdDetails?.trialPeriodStartDate).utc().format("YYYY-MM-DD") : "",
        trialPeriodEndDate: ByIdDetails?.trialPeriodEndDate ? moment(ByIdDetails?.trialPeriodEndDate).utc().format("YYYY-MM-DD") : ""
      });
    }
  }, [ByIdDetails]);
  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  useEffect(() => {
    fetchCompanies();
  }, []);


  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {_id ? `Edit Trial Subscription` : `Add Trial Subscription`}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-trial-subscription")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="custom_label">
                    Upload Contract documents
                  </Typography>
                  {contract ? (
                    contract.endsWith(".pdf") ? (
                      <div className="upload_image_preview2">
                        <CardMedia
                          component="img"
                          image="/static/images/pdf.png"
                          alt="photo"
                          sx={{ width: 200, height: 200, objectFit: "contain" }}
                          onClick={() => window.open(contract)}
                        />
                        <CancelIcon
                          onClick={() => setContract(null)}
                          color="error"
                        />
                      </div>
                    ) : (
                      <div className="upload_image_preview2">
                        <CardMedia
                          component="img"
                          image={contract}
                          alt="photo"
                          sx={{ width: 200, height: 200 }}
                        />
                        <CancelIcon
                          onClick={() => setContract(null)}
                          color="error"
                        />
                      </div>
                    )
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file-vdo">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file-vdo"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg,application/pdf",
                          }}
                          onChange={(e: any) => handleImageUpload(e)}
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Subscription Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="name"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Enter Subscription Name"
                    value={formik.values.name}
                    onChange={(val) => {
                      if (
                        val.target.value === " "

                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name.toString()
                        : ""
                    }
                  />
                </Grid>


                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    No. of Tokens
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="tokens"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter no. of tokens"
                    value={formik?.values?.tokens}
                    onChange={(val) => {
                      if (
                        val.target.value === " "

                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.tokens && Boolean(formik.errors?.tokens)
                    }
                    helperText={
                      formik.touched?.tokens && formik.errors?.tokens
                        ? formik.errors?.tokens.toString()
                        : ""
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Start Date</Typography>
                  <TextField
                    hiddenLabel
                    type="date"
                    name="trialPeriodStartDate"
                    variant="outlined"
                    fullWidth
                    placeholder="End Time"
                    className="text_field"
                    value={formik.values.trialPeriodStartDate}
                    onChange={(e) => {
                      const value = e.target.value;
                      const year = value.split("-")[0];
                      if (year.length > 4 || parseInt(year, 10) > 9999) {
                        formik.setFieldValue("trialPeriodStartDate", "");
                        setStartDateSend("")
                      } else {
                        handleDateChange(e);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        formik.values.trialPeriodStartDate && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => { setStartDateSend(""); formik.setFieldValue("trialPeriodStartDate", "") }}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      ),
                      inputProps: {
                        min: new Date().toISOString().split("T")[0], // Set minimum date to today
                      },
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.trialPeriodStartDate && Boolean(formik.errors?.trialPeriodStartDate)
                    }
                    helperText={
                      formik.touched?.trialPeriodStartDate && formik.errors?.trialPeriodStartDate
                        ? formik.errors?.trialPeriodStartDate.toString()
                        : ""
                    }

                  />
                </Grid>


                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">End Date</Typography>
                  <TextField
                    hiddenLabel
                    type="date"
                    name="trialPeriodEndDate"
                    variant="outlined"
                    fullWidth
                    placeholder="End Time"
                    className="text_field"
                    value={formik.values.trialPeriodEndDate}
                    onChange={(e) => {
                      const value = e.target.value;
                      const year = value.split("-")[0];
                      if (year.length > 4 || parseInt(year, 10) > 9999) {
                        formik.setFieldValue("trialPeriodEndDate", "");
                        setEndDateSend("")
                      } else {
                        handleDateChange1(e);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        formik.values.trialPeriodEndDate && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => { setEndDateSend(""); formik.setFieldValue("trialPeriodEndDate", "") }}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      ),
                      inputProps: {
                        min: new Date().toISOString().split("T")[0], // Set minimum date to today
                      },
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.trialPeriodEndDate && Boolean(formik.errors?.trialPeriodEndDate)
                    }
                    helperText={
                      formik.touched?.trialPeriodEndDate && formik.errors?.trialPeriodEndDate
                        ? formik.errors?.trialPeriodEndDate.toString()
                        : ""
                    }

                  />
                </Grid>


                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select Company
                  </Typography>
                  <Autocomplete
                    options={companies || []}
                    getOptionLabel={(option: any) => option?.companyName + " (" + option?.referenceId + ")" || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Company"
                        variant="outlined"
                      />
                    )}
                    value={
                      companies?.find(
                        (it: any) => it?._id === selectedCompany
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setSelectedCompany(newValue?._id || "");
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?._id === value?._id
                    }
                  />

                </Grid>


                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="description"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={(val) => {
                      if (
                        val.target.value === " "

                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description.toString()
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Box className="form_btn">
              <Button
                className="btn btn_primary"
                type="submit"
                disabled={isLoading}
              >
                {_id ? `Update` : `Save`}
              </Button>
            </Box>
          </form>
        </Card>

      </div>
    </div>
  );
};

export default AddTrialSubscription;
