import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';

import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    TextField,
    Popper,
    ClickAwayListener,
    Paper,
    Chip,
    Tooltip,

} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";


import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";

import { DayPicker } from "react-day-picker";
import moment from "moment";

import { errorToast, successToast } from "../../helpers";
import { useLazyDeleteClaimPropertyQuery, useLazyGetClaimedPropertiesQuery } from "../../services/propertyListing";



const ManageClaimedTransactions = () => {

    const navigate = useNavigate();
    const [open1, setOpen1] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedChip, setSelectedChip] = useState("");
    const [month, setMonth] = useState(new Date());
    const [getDataApi] = useLazyGetClaimedPropertiesQuery();
    const [rows, setRows] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deleteApi] = useLazyDeleteClaimPropertyQuery();
    const [selectedId, setSelectedId] = useState<number>(0);

    let totalPages = Math.ceil(totalCount / limit);

    const fetchTransactions = async () => {
        setIsLoading(true)
        try {
            const res = await getDataApi({
                page: page,
                limit: limit,
                search: debouncedSearchTerm,
                startDate: range?.from
                    ? moment(range?.from).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
                    : "",
                endDate: range?.to === range?.from ? "" :
                    range?.to
                        ? moment(range?.to).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
                        : "",
            }).unwrap();
            if (res?.statusCode === 200) {
                setRows(res?.data?.rows);
                setTotalCount(res?.data?.count)
            }
        } catch (error: any) {
            errorToast(error?.data?.message)
        }
    }

    function formatNumberWithCommas(value: string | number) {
        const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
        const [integer, decimal] = valueStr.split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
        return result;
    }

    const onPageChange1 = (newPage: number) => {
        setPage(newPage);
        setRows([]);
    };





    const [range, setRange] = useState<{
        from: Date | undefined;
        to: Date | undefined;
    }>({
        from: undefined,
        to: undefined,
    });

    const handleClose = () => {
        setOpen1(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen1(true);
    };

    const getFormattedRange = () => {
        if (range?.from && range?.to) {
            return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
                range.to
            ).format("MM/DD/YYYY")}`;
        } else if (range?.from) {
            return moment(range.from).format("MM/DD/YYYY");
        }
        return "";
    };


    const handleSelect = (selectedRange: any) => {
        if (selectedRange === undefined) {
            setRange({ from: undefined, to: undefined });
        } else {
            setRange(selectedRange);
        }
    };

    const handleChipClick = (value: any) => {
        if (value === selectedChip) {
            setSelectedChip("");
            setRange({ from: undefined, to: undefined });
            setMonth(moment().toDate());
            return;
        }
        setSelectedChip(value);

        let newRange = { from: moment().toDate(), to: moment().toDate() };
        const today = moment();

        if (value === "lastMonth") {
            newRange = {
                from: moment().subtract(1, "months").startOf("month").toDate(),
                to: moment().subtract(1, "months").endOf("month").toDate(),
            };
        } else if (value === "lastWeek") {
            newRange = {
                from: moment().subtract(7, "days").startOf("day").toDate(),
                to: today.toDate(),
            };
        } else if (value === "lastYear") {
            newRange = {
                from: moment().subtract(1, "years").startOf("year").toDate(),
                to: moment().subtract(1, "years").endOf("year").toDate(),
            };
        }
        setRange(newRange);
        setMonth(newRange.from);
    };

    const handleMonthChange = (newMonth: any) => {
        setMonth(newMonth);
    };




    const handleDeleteProperty = async (userId: any) => {
        try {
            const response = await deleteApi({ id: userId }).unwrap();
            if (response?.statusCode === 200) {
                successToast("Claimed transaction deleted successfully");
                fetchTransactions()
            }
        } catch (error: any) {
            console.error(error);
            errorToast(error?.data?.message || "");
        }
    };

    useEffect(() => {
        fetchTransactions()
    }, [page, limit, debouncedSearchTerm, range])



    return (
        <div>
            {/* <Loader isLoad={load} /> */}
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">
                        Manage Claimed Transactions

                    </h1>
                </div>
                <Card className="cards">
                    <Box className="cards_header">
                        <Box className="cards_header_left">
                            <SearchBar2
                                value={searchTerm}
                                searchTerm={searchTerm}
                                onCross={() => setSearchTerm("")}
                                setDebouncedSearchTerm={setDebouncedSearchTerm}
                                onChange={(val: any) => {
                                    if (isValidInput(val.target.value)) {
                                        setSearchTerm(val.target.value);
                                    }
                                }}
                            />
                        </Box>
                        <Box className="cards_header_right">
                            <div className="control_group">
                                <TextField
                                    // label="Select Date Range"
                                    variant="outlined"
                                    hiddenLabel
                                    className="text_field-range "
                                    fullWidth
                                    onClick={handleClick}
                                    value={getFormattedRange()}
                                    placeholder="Select Date Range"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <Popper
                                open={open1}
                                anchorEl={anchorEl}
                                placement="bottom-start"
                                disablePortal
                                style={{
                                    position: "relative",
                                    zIndex: 1,
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Paper
                                        elevation={3}
                                        style={{ position: "relative", zIndex: 1 }}
                                    >
                                        <div
                                            style={{
                                                padding: 8,
                                                borderRadius: 10,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    padding: "8px",
                                                }}
                                            >
                                                <Chip
                                                    label="Last Week"
                                                    variant={
                                                        selectedChip === "lastWeek"
                                                            ? "filled"
                                                            : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastWeek")}
                                                />
                                                <Chip
                                                    label="Last Month"
                                                    variant={
                                                        selectedChip === "lastMonth"
                                                            ? "filled"
                                                            : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastMonth")}
                                                />
                                                <Chip
                                                    label="Last Year"
                                                    variant={
                                                        selectedChip === "lastYear"
                                                            ? "filled"
                                                            : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastYear")}
                                                />
                                            </div>

                                            <DayPicker
                                                className="react-day-picker"
                                                mode="range"
                                                selected={range}
                                                onSelect={handleSelect}
                                                // numberOfMonths={10}
                                                month={month}
                                                onMonthChange={handleMonthChange}
                                            // styles={{
                                            //   months: { display: "flex", gap: "16px" },
                                            //   month: { margin: 0 },
                                            // }}
                                            />
                                        </div>
                                    </Paper>
                                </ClickAwayListener>
                            </Popper>
                            {range?.from || range?.to ? (
                                <button onClick={() => setRange({
                                    from: undefined, to: undefined
                                })} className="btn btn-primary cut_btn" > <CloseIcon /> </button>
                            ) : ("")}

                        </Box>

                    </Box>



                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell>Property Id</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Property Name</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Company Name</TableCell>
                                    <TableCell>Agent Name</TableCell>
                                    <TableCell>Date & time</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.length == 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center">
                                            No record found
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    rows?.map((row: any, i: any) => (
                                        <TableRow key={row?.i}>
                                            <TableCell align="center">
                                                {(page - 1) * 10 + i + 1}
                                            </TableCell>
                                            <TableCell>{row?.propertyId?.referenceId || "-"}</TableCell>

                                            <TableCell>
                                                <figure
                                                    onClick={() =>
                                                        navigate(
                                                            `/claimed-transaction-details/${row?.propertyId?._id}`,
                                                        )
                                                    }
                                                    className="user_img"
                                                >
                                                    <img
                                                        style={{ borderRadius: 0 }}
                                                        src={
                                                            row?.propertyId?.images?.[0] || `/static/images/product1.png`
                                                        }
                                                        alt=""
                                                    />
                                                </figure>
                                            </TableCell>
                                            <TableCell>{row?.propertyId?.name || "-"}</TableCell>
                                            <TableCell>
                                                {row?.price
                                                    ? formatNumberWithCommas(String(row?.price))
                                                    : "-"}
                                            </TableCell>
                                            <TableCell>{row?.companyId?.companyName ? row?.companyId?.companyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                                            <TableCell>{row?.userId?.role === 5 || !row?.userId?.role ? "-" : row?.userId?.name + " (" + row?.userId?.referenceId + ")"}</TableCell>
                                            <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                                            <TableCell align="center">
                                                <Box className="table_actions">

                                                    <Tooltip title="View details">
                                                        <IconButton
                                                            onClick={() =>
                                                                navigate(
                                                                    `/claimed-transaction-details/${row?.propertyId?._id}`,
                                                                )
                                                            }
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>


                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen(true);
                                                                setSelectedId(row?._id);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {rows?.length > 0 ? (
                        <Pagination
                            setPage={setPage}
                            module={rows}
                            page={page}
                            onPageChange={onPageChange1}
                            totalPages={totalPages}
                            limit={limit}
                            setLimit={setLimit}
                        />
                    ) : (
                        ""
                    )}
                    <WarnModal
                        open={open}
                        setOpen={setOpen}
                        handleDelete={() => handleDeleteProperty(selectedId)}
                        name="Claimed transaction"
                    />
                </Card>
            </div>
        </div>
    );
};

export default ManageClaimedTransactions;
