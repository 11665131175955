import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    FormHelperText,
    Grid,
    Input,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../helpers/constants/Loader";
import { isString } from "../../utils/validation";
import { useAddLeadsMutation } from "../../services/propertyLeads";
import { useLazyGetPropertiesWithoutPaginationQuery } from "../../services/propertyListing";


const ManageAddLeads = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ByIdDetails, setByIdDetails] = useState<any>(null);
    const navigate = useNavigate();
    const [dialCode, setPhoneCode] = useState("+971");
    const [addLead] = useAddLeadsMutation();
    const { id } = useParams();
    const [getProperties] = useLazyGetPropertiesWithoutPaginationQuery();
    const [properties, setProperties] = useState<any>();
    const [selectedProp, setSelectedProp] = useState<any>();
    console.log('selectedProp :', selectedProp);


    const fetchProperties = async () => {
        try {
            const res = await getProperties({}).unwrap();
            if (res?.statusCode === 200) {
                setProperties(res?.data)
            }
        } catch (error: any) {
            errorToast(error?.data?.message)
        }
    }


    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phoneNo: "",
            dialCode: "",
            propertyName: "",
            leadType: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required("Email is required")
                .matches(
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    "Enter a valid email address"
                )
                .matches(
                    /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/,
                    "Emojis are not allowed in the email address"
                ),
            name: Yup.string()
                .required("Full name is required")
                .min(2, "Minimum 2 characters are required")
                .max(80, "Maximum 80 characters are allowed")
                .matches(
                    /^[a-zA-Z\s]+$/,
                    "Full name must contain only letters and spaces"
                ),
            phoneNo: Yup.string()
                .required("Phone number is required")
                .min(7, "Phone number must be at least 7 characters")
                .max(20, "Phone number must be at most 20 characters"),
            leadType: Yup.string()
                .required("Lead type is required"),

        }),
        onSubmit: async (values, { setSubmitting }) => {
            formik.setSubmitting(true);
            let body: any = {
                userName: values.name || "",
                userEmail: values.email || "",
                userPhoneNo: values.phoneNo || "",
                userDialCode: dialCode,
                propertyId: selectedProp,
                leadType: Number(values.leadType),
            };
            console.log(body, "body");

            let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

            try {
                setIsLoading(true);
                const response = await addLead(encryptedBody).unwrap();
                if (response?.statusCode === 200) {
                    successToast("Lead added successfully");
                    navigate("/manage-leads");
                }
                setIsLoading(false);
            } catch (error: any) {
                console.error(error);
                setIsLoading(false);
                errorToast(error?.data?.message);
            } finally {
                setIsLoading(false);
                setSubmitting(false);
            }

        },
    });

    const handleChangePhone = (phone: any, country: any) => {
        formik.setFieldValue("phoneNo", phone?.replace(country?.dialCode, ""));
        setPhoneCode(
            country?.dialCode.includes("+")
                ? country?.dialCode
                : "+" + country?.dialCode
        );
    };


    useEffect(() => {
        fetchProperties();
    }, [])
    return (
        <div>
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Add Lead</h1>
                    <Loader isLoad={isLoading} />
                    <Button
                        className="btn btn_primary"
                        onClick={() => {
                            navigate("/manage-leads");
                        }}
                    >
                        Back
                    </Button>
                </div>
                <Card className="cards">
                    <form onSubmit={formik.handleSubmit}>
                        <CardContent sx={{ p: 0, pb: 0 }}>
                            <Grid container spacing={2}>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography className="custom_label">User Name</Typography>
                                    <TextField
                                        hiddenLabel
                                        type={"text"}
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Full Name"
                                        inputProps={{ maxLength: 35 }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        className="text_field"
                                        onChange={(val) => {
                                            if (
                                                val.target.value === " " ||
                                                val.target.value === "."
                                            ) {
                                            } else if (isString(val.target.value)) {
                                                formik.handleChange(val);
                                            }
                                        }}
                                        helperText={
                                            formik.touched.name && formik.errors.name
                                                ? formik.errors.name.toString()
                                                : ""
                                        }
                                    ></TextField>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography className="custom_label">Email</Typography>
                                    <TextField
                                        className="text_field"
                                        hiddenLabel
                                        type={"text"}
                                        name="email"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Email"
                                        value={formik.values.email}
                                        onChange={(val) => {

                                            if (
                                                val.target.value == " "
                                            ) {

                                            } else {
                                                formik.handleChange(val);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        helperText={
                                            formik.touched.email && formik.errors.email
                                                ? formik.errors.email.toString()
                                                : ""
                                        }
                                    ></TextField>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography className="custom_label">Phone Number</Typography>
                                    <PhoneInput
                                        value={dialCode + formik.values.phoneNo}
                                        country={"ae"}
                                        placeholder="0 (000) 000-000"
                                        enableSearch={true}
                                        inputStyle={{ width: "100%" }}
                                        onChange={(phone, country) =>
                                            handleChangePhone(phone, country)
                                        }
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.phoneNo && formik.errors.phoneNo ? (
                                        <h6 className="err_msg">
                                            {formik.touched.phoneNo &&
                                                formik.errors.phoneNo.toString()}
                                        </h6>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography className="custom_label">Property Name</Typography>
                                    <Select
                                        className="select_div"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="propertyName"
                                        fullWidth
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 250,

                                                },
                                            },
                                        }}
                                        value={formik?.values?.propertyName || ""}
                                        onChange={(e) =>
                                            formik.setFieldValue("propertyName", e.target?.value)
                                        }
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched?.propertyName &&
                                            Boolean(formik.errors?.propertyName)
                                        }
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Select
                                        </MenuItem>
                                        {properties?.length &&
                                            properties?.map((item: any) => {
                                                return (
                                                    <MenuItem
                                                        onClick={() => setSelectedProp(item?._id)}
                                                        value={item?._id}
                                                    >
                                                        {item?.name + " (" + item?.referenceId + ")"}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                    {formik.touched?.propertyName &&
                                        formik.errors?.propertyName && (
                                            <Typography
                                                sx={{ fontSize: "12px" }}
                                                variant="caption"
                                                color="red"
                                            >
                                                Please select a property
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography className="custom_label">Lead Type</Typography>
                                    <Select
                                        className="select_div"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="beds"
                                        fullWidth
                                        value={formik?.values?.leadType || ""}
                                        onChange={(e) =>
                                            formik.setFieldValue("leadType", e.target?.value)
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 250, // Adjust height
                                                    width: 250,     // Adjust width
                                                },
                                            },
                                        }}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched?.leadType && Boolean(formik.errors?.leadType)}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Select
                                        </MenuItem>
                                        <MenuItem value="1">Phone</MenuItem>
                                        <MenuItem value="2">In-app Chat</MenuItem>
                                        <MenuItem value="3">Email</MenuItem>
                                        <MenuItem value="4">Whatsapp</MenuItem>

                                    </Select>
                                    {formik.touched.leadType && formik.errors.leadType && (
                                        <FormHelperText sx={{ color: "red" }}>
                                            {String(formik.errors.leadType)}
                                        </FormHelperText>
                                    )}
                                </Grid>


                            </Grid>
                            <div className="form_btn">
                                <Button size="large" type="submit" className="btn btn_primary">
                                    Save
                                </Button>
                            </div>
                        </CardContent>
                    </form>
                </Card>
            </div>
        </div>
    );
};

export default ManageAddLeads;
