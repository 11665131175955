import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


export const enquriesApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postEnqReply: builder.mutation<
            CommonResponseType & { data: any },
            { body: CommonBody, id: string }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.contactUs}/${id}/${END_POINTS.reply}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["GETENQURIES"]
        }),
        getAllEnquries: builder.query<CommonResponseType & { data: any },
            {
                page?: number;
                search?: string;
                limit?: number;
                range?: {
                    from: string | undefined;
                    to: string | undefined;
                };
            }>({
                query: ({ page, limit, range, search }) => {
                    let url = `${END_POINTS.contactUs}?page=${page}&limit=${limit}&search=${search}&startDate=${range?.from}&endDate=${range?.to}`;
                    return {
                        url: url,
                        method: "GET",
                    };
                },
                keepUnusedDataFor: 3,
                providesTags: ["GETENQURIES"]
            }),
        deleteEnquiry: builder.mutation<CommonResponseType & { data: any },
            {
                id: string
            }>({
                query: ({ id }) => {
                    let url = `${END_POINTS.contactUs}/${id}`;
                    return {
                        url: url,
                        method: "DELETE",
                    };
                },
                invalidatesTags: ["GETENQURIES"]
            }),

        historyOfEnq: builder.query<
            CommonResponseType & { data: any },
            { id: string }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.EnquriesHistory}/${id}/history`,
                method: "GET",

            }),

        }),




    }),
});

export const {
    usePostEnqReplyMutation,
    useGetAllEnquriesQuery,
    useDeleteEnquiryMutation,
    useLazyHistoryOfEnqQuery,


} = enquriesApi;
