import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import { ImageModal } from "../../components";
import Loader from "../../helpers/constants/Loader";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import moment from "moment";
import { handleDownload } from "../../utils/uploadMedia";

const CompanyDocsDetails = () => {
  const location = useLocation();
  const { state } = location;
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>("");

  const [fetchApi, { isLoading }] = useLazyGetSpecificAgentByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data?.documents || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);



  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };




  const getDocName = (num: number) => {
    switch (num) {
      case 1:
        return "Trade License";
      case 2:
        return "RERA Registration Documents (For Dubai Companies)";
      case 3:
        return "Brokerage License (Other than Dubai Companies)";
      case 4:
        return "Emirate Id documents";
      case 5:
        return "Power of Attorney documents";
      case 17:
        return "Emirate Id documents";
      case 18:
        return "DTCM Number";
      default:
        return "Invalid Document Number";
    }
  };

  return (
    <>
      <Card className="cards">
        <Loader isLoad={isLoading} />
        <CardContent sx={{ p: 1 }}>
          <h2 className="card_title">Company Documents</h2>
          {ByIdDetails?.length
            ? ByIdDetails.filter((item: any) => {
              if (ByIdDetails?.agentEmirate === 3 && item.documentType === 2)
                return false;
              if (ByIdDetails?.agentEmirate === 1 && item.documentType === 3)
                return false;
              return true;
            })?.map((item: any, index: number) => {
              return item?.frontImage || item?.backImage ? (
                <div className="single_doc">
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="heading">
                      <Typography
                        className="document_head"
                        variant="h5"
                        fontSize={"18px"}
                      >
                        {/* {item?.name ? "Document Name: " + item?.name : ""} */}

                        {item?.documentType
                          ? getDocName(item?.documentType)
                          : ""}
                      </Typography>
                      <p className="doc_details" style={{ fontSize: "16px" }}>
                        {item?.documentNumber
                          ? item?.documentNumber
                          : ""}
                      </p>
                      <p className="doc_details" style={{ fontSize: "16px" }}>
                        {item?.name?.trim()
                          ? item.name.charAt(0).toUpperCase() + item.name.slice(1)
                          : ""}
                      </p>
                      <p className="doc_details" style={{ fontSize: "16px" }}>
                        {item?.ORN !== ""
                          ? "Expiry Date: " +
                          moment(item?.expiryDate).format("DD/MM/YYYY")
                          : ""}
                      </p>
                    </Grid>
                    {item?.frontImage ? (
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <div className="control_group">
                          {/* <Box className="docs_div v2 pdf_vw">
                              <figure>
                                <img
                                  onClick={() => window.open(item?.frontImage)}
                                  src={"/static/images/pdf_image.png"}
                                  alt="Front Document"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "300px",
                                  }}
                                />
                              </figure>
                            </Box> */}

                          <Box
                            display="flex"
                            alignItems="center"
                            border="1px dashed #ccc"
                            borderRadius="4px"
                            padding="8px"
                            gap="8px"
                            width="100%"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              width="100%"
                            >
                              <img
                                src={"/static/images/pdf_image.png"}
                                alt="Preview"
                                onClick={() => { window.open(item?.frontImage) }}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  objectFit: "contain",
                                  marginRight: "8px",
                                }}
                              />

                              <Box
                                display="flex"
                                flexGrow={1}
                                justifyContent="flex-end"
                                gap={1}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    // window?.open(item?.frontImage)
                                    handleDownload(item?.frontImage, Date.now().toString())
                                  }
                                  style={{ textTransform: "none" }}
                                >
                                  Download
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {item?.backImage ? (
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <div className="control_group">
                          <Box
                            display="flex"
                            alignItems="center"
                            border="1px dashed #ccc"
                            borderRadius="4px"
                            padding="8px"
                            gap="8px"
                            width="100%"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              width="100%"
                            >
                              <img
                                src={"/static/images/pdf_image.png"}
                                alt="Preview"
                                onClick={() => { window.open(item?.backImage) }}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  objectFit: "contain",
                                  marginRight: "8px",
                                }}
                              />

                              <Box
                                display="flex"
                                flexGrow={1}
                                justifyContent="flex-end"
                                gap={1}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    // window?.open(item?.backImage)
                                    handleDownload(item?.backImage, Date.now().toString())
                                  }

                                  style={{ textTransform: "none" }}
                                >
                                  Download
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </div>
              ) : null;
            })
            : "No documents Provided"}
        </CardContent>
        <ImageModal
          open={openModal}
          handleClose={handleCloseModal}
          image={selectedImage}
        />

      </Card>
    </>
  );
};

export default CompanyDocsDetails;
