import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MouseIcon from "@mui/icons-material/Mouse";
import {
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  LineChart,
  Line,
} from "recharts";
import { useLazyGetAgentInsightsQuery } from "../../services/users";
import { errorToast } from "../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../helpers/constants/Loader";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarIcon from "@mui/icons-material/Star";

const AgentInsights = () => {
  const { _id } = useParams();
  const [getData] = useLazyGetAgentInsightsQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getData({ userId: _id }).unwrap();
      setIsLoading(false);
      if (res?.statusCode === 200) {
        setData(res?.data);
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const GreetingCard = () => (
    <Card className="cards greeting-card">
      <CardContent>
        <Typography variant="h6">
          Great job, {data?.superAgent?.name || ""}!
        </Typography>
        <Typography variant="body2">
          Maintain all the criteria till the end of the month.
        </Typography>
        <Box className="progress-bar">
          <Box
            className="progress-fill"
            style={{
              width: `${data?.superAgent?.currentCriteria ? Number(data.superAgent.currentCriteria) * 20 : 0}%`,
            }}
          ></Box>
        </Box>
        <Typography variant="caption">
          Titan criteria: {data?.superAgent?.currentCriteria || "0"}/
          {data?.superAgent?.totalCriteria || "0"}
        </Typography>
        <img
          src="/static/images/broker.png"
          alt="Agent"
          className="agent-image"
        />
      </CardContent>
    </Card>
  );

  const PerformanceCard = () => (
    <Card className="cards performance-card">
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Agent Performance</Typography>
          <EmojiEventsIcon />
        </Box>
        <Box className="performance-bar">
          <Box
            className="performance-fill"
            style={{ width: `${data?.performance || 0}%` }}
          ></Box>
        </Box>
        <Typography variant="body2">0.9% last week ↗</Typography>
      </CardContent>
    </Card>
  );

  const FeedbackCard = () => (
    <Card className="cards feedback-card">
      <CardContent>
        <Typography variant="h6">Customer Feedback</Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h3">
            {data?.customerFeedbackUnanswered || "0"}
          </Typography>
          <Typography variant="body2" ml={1}>
            Unanswered Enquiries
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <StarIcon />
          <Typography variant="body2" ml={1}>
            {data?.rating || "0"}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const ResponseRateCard = () => {
    const data1 = [
      { name: "Apr 28", value: 65 },
      { name: "May 5", value: 59 },
      { name: "May 12", value: 80 },
      { name: "May 19", value: 81 },
      { name: "Jun 2", value: 56 },
      { name: "Jun 9", value: 55 },
      { name: "Jun 16", value: 40 },
    ];

    return (
      <Card className="cards response-rate-card">
        <CardContent>
          <Typography variant="h6">Response Rate</Typography>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data1}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
          <Typography variant="body2">
            Your Overall response rate: {data?.responseRate || 0}%
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            style={{ cursor: "pointer" }}
          >
            See details →
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body2" color="success.main">
              🚀 Good job! Your responsiveness has paid off, keep it up!
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const PropertiesCard = ({ heading, details, type }: any) => {
    const data = [
      { name: "Verified", value: details?.verified || 0 },
      { name: "Not verified", value: details?.notVerified || 0 },
    ];
    const COLORS = ["#8884d8", "#E0E7FF"];

    return (
      <Card className="cards properties-card">
        <CardContent>
          <Typography variant="h6">{heading}</Typography>
          <Box display="flex" justifyContent="space-between">
            <PieChart width={130} height={130}>
              <Pie
                data={data}
                cx={60}
                cy={60}
                innerRadius={45}
                outerRadius={60}
                paddingAngle={0}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <Box>
              <Typography variant="h4">{details?.count || 0}</Typography>
              <Typography variant="body2">Total</Typography>
            </Box>
          </Box>
          <Box mt={2}>
            {data.map((entry, index) => (
              <Box
                key={entry.name}
                display="flex"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="body2">{entry.name}</Typography>
                <Typography variant="body2">
                  {entry.value} (
                  {Math.round((entry.value / details?.count) * 100) || 0}%)
                </Typography>
              </Box>
            ))}
          </Box>
          <Button
            variant="outlined"
            fullWidth
            onClick={() =>
              navigate("/property-listing", {
                state: { agentId: _id, type: type },
              })
            }
          >
            Verify properties
          </Button>
        </CardContent>
      </Card>
    );
  };

  const TransactionsCard = () => {
    const Data = [
      { name: "Completed", value: data?.transactions?.completed || 0 },
      { name: "Pending transactions", value: data?.transactions?.pending || 0 },
    ];
    const COLORS = ["#8884d8", "#E0E7FF"];

    return (
      <Card className="cards transactions-card">
        <CardContent>
          <Typography variant="h6">Transactions</Typography>
          <Box display="flex" justifyContent="space-between">
            <PieChart width={120} height={120}>
              <Pie
                data={Data}
                cx={60}
                cy={60}
                innerRadius={45}
                outerRadius={60}
                paddingAngle={5}
                dataKey="value"
              >
                {Data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <Box>
              <Typography variant="h4">
                {data?.transactions?.count || 0}
              </Typography>
              <Typography variant="body2">Total</Typography>
            </Box>
          </Box>
          <Box mt={2}>
            {Data?.map((entry: any, index: number) => (
              <Box
                key={entry.name}
                display="flex"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="body2">{entry.name}</Typography>
                <Typography variant="body2">
                  {entry.value} ({Math.round((entry.value / 14) * 100) || 0}%)
                </Typography>
              </Box>
            ))}
          </Box>
          <Button variant="outlined" fullWidth>
            Claim transactions
          </Button>
        </CardContent>
      </Card>
    );
  };

  const DummyCard = ({ heading }: any) => {
    const data = [{ name: "No data Available", value: 100 }];
    const COLORS = ["#8884d8", "#E0E7FF"];

    return (
      <Card className="cards properties-card">
        <CardContent>
          <Typography variant="h6">{heading}</Typography>
          <Box display="flex" justifyContent="space-between">
            <PieChart width={130} height={130}>
              <Pie
                data={data}
                cx={60}
                cy={60}
                innerRadius={45}
                outerRadius={60}
                paddingAngle={0}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <Box>
              <Typography variant="h4">{0}</Typography>
              <Typography variant="body2">Total</Typography>
            </Box>
          </Box>
          <Box mt={2}>
            {data.map((entry, index) => (
              <Box
                key={entry.name}
                display="flex"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="body2">{entry.name}</Typography>
                {/* <Typography variant="body2">{entry.value} ({Math.round(entry.value / details?.count * 100) || 0}%)</Typography> */}
              </Box>
            ))}
          </Box>
          <Button disabled variant="outlined" fullWidth>
            Verify properties
          </Button>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Box className="dashboardAgent">
        <Loader isLoad={isLoading} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GreetingCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <PerformanceCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <FeedbackCard />
          </Grid>
          <Grid item xs={12}>
            <ResponseRateCard />
          </Grid>
          <Grid item xs={12} md={6}>
            {data?.totalProperties?.count ? (
              <PropertiesCard
                heading="Total Properties"
                details={data?.totalProperties}
                type={1}
              />
            ) : (
              <DummyCard heading="Total Properties" />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {data?.liveProperties?.count ? (
              <PropertiesCard
                heading="Live Properties"
                details={data?.liveProperties}
                type={2}
              />
            ) : (
              <DummyCard heading="Live Properties" />
            )}
          </Grid>
          {/* <Grid item xs={12} md={6}>
                        <PropertiesCard heading="Publish Properties" details={data?.totalProperties} />
                    </Grid> */}
          <Grid item xs={12} md={6}>
            <TransactionsCard />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box className="cards criteria_card">
              <h3>Titan Criteria</h3>
              <ul>
                <li>Profile should be verified </li>
                <li>
                  Average quality score of all agent listings should be 85%
                </li>
                <li>
                  Active listing count – Total number of listed properties
                  should be minimum 5{" "}
                </li>
                <li>
                  Listing compliance – Should not have any listings in action
                  tracker for more than 24 hours
                </li>
                <li>
                  Response rate – Response time on all communication channels
                  should be 80%{" "}
                </li>
                <li>
                  WhatsApp and in-app response time – Should reply to WhatsApps
                  within 2 hours
                </li>
                <li>
                  Lead Generation – Agents should generate at least 10 leads in
                  the last 60 days{" "}
                </li>
                <li>
                  Transaction requirement – Should claim a transaction in the
                  last 60 days
                </li>
                <li>
                  Customer ratings – A minimum average rating of 4.0 based on at
                  least two customer ratings is required in the last 90 days
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AgentInsights;
