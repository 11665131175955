import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getAllReports: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit?: number, startDate?: any, endDate?: any }>({
            query: ({ page, search, limit, startDate, endDate }) => {


                let url = `${END_POINTS.reportProperty}?page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;

                return {
                    url: url,
                    method: "GET",
                };
            },
        }),



        getReportById: builder.query<CommonResponseType & { data: any }, { id: any }>
            ({
                query: ({ id }) => ({
                    url: `${END_POINTS.propertyreportPropertyById}/${id}`,
                    method: "GET"
                })
            }),

        deleteReportById: builder.query<CommonResponseType & { data: any }, { id: string }>
            ({
                query: ({ id }) => ({
                    url: `${END_POINTS.propertyreportPropertyById}/${id}`,
                    method: "DELETE"
                })
            }),


    }),
});

export const {
    useLazyGetAllReportsQuery,
    useLazyGetReportByIdQuery,
    useLazyDeleteReportByIdQuery

} = authApi;
