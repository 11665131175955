import React from 'react';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  useDroppable,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import DraggableImage from './DragableImages';

interface DragAndDropListProps {
  myImages: string[];
  handleCrossClick: (event: React.MouseEvent, index: number) => void;
  setMyImages: React.Dispatch<React.SetStateAction<string[]>>;
}

const DragAndDrop = ({
  myImages,
  handleCrossClick,
  setMyImages,
}: DragAndDropListProps) => {
  const handleDragEnd = (event: any)=>{
    const { active, over } = event;
    if (over && active.id !== over.id) {
      // Update image order based on drag result
      // const oldIndex = parseInt(String(active.id), 10);
      // const newIndex = parseInt(String(over.id), 10);
      const oldIndex = myImages.findIndex((item) => item === active.id);
      const newIndex = myImages.findIndex((item) => item === over.id);
      setMyImages((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={myImages} strategy={rectSortingStrategy}>
        {myImages.map((item, index) => (
          <DraggableImage
            key={index}
            id={item}
            item={item}
            index={index}
            handleCrossClick={handleCrossClick}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default DragAndDrop;