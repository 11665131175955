import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

import {
  Card,
  Box,
  Typography,
  Tabs,
  Tab,

} from "@mui/material";

import { useLazyGetLeadsInsightsGraphsQuery, useLazyGetPerformanceInsightsQuery } from "../../services/performace";
import AgentInsights from "../../features/agentInsights";
import LeadsInsight from "../../features/leadsInsight";
import moment from "moment";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageSuperDashboard = () => {
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [getData] = useLazyGetPerformanceInsightsQuery();
  const [getLeadsGraphAPi] = useLazyGetLeadsInsightsGraphsQuery();
  const [graphData, setGraphData] = useState<any>();
  const [agentData, setAgentData] = useState({
    leads: [],
    score: [],
    listing: [],
    agentsCount: {},
    totalAgentsPercentage: 0,
  });
  const getAgentsInsight = async ({ startDate, endDate }: any) => {
    try {
      const response = await getData({
        type: 3,
        superAgent: true,
        startDate: startDate
          ? moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: endDate === startDate ? "" : endDate
          ? moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();

      if (response?.statusCode == 200) {
        setAgentData({
          leads: response?.data?.leads?.data,
          listing: response?.data?.liveListing?.data,
          score: response?.data?.qualityScore?.data,
          agentsCount: {
            total: response?.data?.totalAgents,
            super: response?.data?.superAgents,
            verified: response?.data?.verifiedAgents,
          },
          totalAgentsPercentage: response?.data?.totalAgentsPercentage,
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const [pieChartData, setPieChartData] = useState<
    { label: string; value: number; color: string }[]
  >([]);
  const [majorLineChartData, setMajorLineChartData] = useState<
    { name: string; count: number }[]
  >([]);
  const [displayTextData, setDisplayTextData] = useState<any>({
    whatsapp: {},
    phone: {},
    email: {},
  });
  const convertPieChartPercent = (
    data: { label: string; value: number; color: string }[]
  ) => {
    const total = data.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.value);
    }, 0);

    if (total === 0) return data;
    return data?.map((data2) => ({
      label: data2?.label,
      value: ((data2?.value / total) * 100).toFixed(2) || 0,
      color: data2?.color,
    }));
  };
  const [leadsSelectedTime, setLeadsSelectedTime] = useState("3");
  const [leadsSelectedApp, setLeadsSelectedApp] = useState("");
  const getLeadsInsight = async ({ startDate, endDate }: any) => {
    try {
      const response = await getData({
        leadsFilterType: leadsSelectedTime,
        leadType: leadsSelectedApp,
        superAgent: true,
        type: 4,
        startDate: startDate
          ? moment(startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: endDate === startDate ? "" : endDate
          ? moment(endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();
      if (response?.statusCode == 200) {
        setPieChartData([
          {
            label: "Whatsapp",
            value: response?.data?.whatAppPercentage,
            color: "#8884d8",
          },
          {
            label: "Calls",
            value: response?.data?.phoneLeadsPercentage,
            color: "#83a6ed",
          },
          {
            label: "In-App Chat",
            value: response?.data?.inAppChatLeadsPercentage,
            color: "#8dd1e1",
          },
          {
            label: "Email",
            value: response?.data?.emailLeadsPercentage,
            color: "#8dd1e8",
          },
        ]);
        setMajorLineChartData(response?.data?.graphData?.userData);
        setDisplayTextData({
          email: {
            emailLeadsNotreplied: response?.data?.emailLeadsNotreplied,
            emailLeadsReplied: response?.data?.emailLeadsReplied,
            emailLeadsResponseRate: response?.data?.emailLeadsResponseRate,
            emailLeadsResponseTime: response?.data?.emailLeadsResponseTime,
          },
          whatsapp: {
            whatAppNotreplied: response?.data?.whatAppNotreplied,
            whatAppReplied: response?.data?.whatAppReplied,
            whatAppResponseRate: response?.data?.whatAppResponseRate,
            whatAppResponseTime: response?.data?.whatAppResponseTime,
          },
          phone: {
            phoneLeadsAns: response?.data?.phoneLeadsAns,
            phoneLeadsAvg: response?.data?.phoneLeadsAvg,
            phoneLeadsCancel: response?.data?.phoneLeadsCancel,
            phoneLeadsUnans: response?.data?.phoneLeadsUnans,
          },
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };


  const fetchGraphs = async () => {
    try {
      const res = await getLeadsGraphAPi({
        forSuperAgent: true
      }).unwrap();

      if (res?.statusCode === 200) {
        setGraphData(res?.data)
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchGraphs();
  }, [])
  return (
    <div>
      <div className="main_loyout">
        <div className="dashBoard">
          <h2 className="mn_hdng">Super Agent Dashboard</h2>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              {/* <Tab label="Overview" {...a11yProps(0)} /> */}
              <Tab label="Agent insights" {...a11yProps(0)} />
              <Tab label="Leads insights" {...a11yProps(1)} />
            </Tabs>
            {/* <CustomTabPanel value={value} index={0}>
              <SuperAgentOverview />
            </CustomTabPanel> */}
            <CustomTabPanel value={value} index={0}>
              <AgentInsights
                getAgentsInsight={getAgentsInsight}
                agentData={agentData}
                superAgent={true}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <LeadsInsight
                filter={leadsSelectedTime}
                setFilter={setLeadsSelectedTime}
                pieChartData={convertPieChartPercent(pieChartData)}
                majorLineChartData={majorLineChartData}
                getLeadsInsight={getLeadsInsight}
                setLeadsSelectedApp={setLeadsSelectedApp}
                displayTextData={displayTextData}
                leadsSelectedApp={leadsSelectedApp}
                barGraphData={graphData}
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default ManageSuperDashboard;
