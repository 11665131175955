import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card, Chip, ClickAwayListener, IconButton, InputAdornment, Paper, Popper, Tab, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import Box from "@mui/material/Box";
import { errorToast, successToast } from "../../helpers/toast";
import { Amenities } from "../../types/General";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetSpecificReelsByIdQuery, useLazyGetAllReelsQuery, useDeleteReelsByIdMutation, useGetAllReelsQuery } from "../../services/reels";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


interface Count {
  active: number,
  inactive: number
}
const ManageReels = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<Amenities[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [value, setValue] = useState<number>(1)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [fetchAllReels, { isLoading }] = useLazyGetAllReelsQuery();
  const [deleteRoles] = useDeleteReelsByIdMutation();
  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });
  const [showCount, setShowCount] = useState<Count>({
    active: 0, inactive: 0
  })


  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };


  const { data, isError, isSuccess, isLoading: load } = useGetAllReelsQuery({
    page: page,
    search: debouncedSearchTerm.trim(),
    limit: limit,
    type: value === 0 ? "active" : "inactive",
    startDate: range?.from
      ? moment(range?.from).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
      : "",
    endDate: range?.to === range?.from ? "" :
      range?.to
        ? moment(range?.to).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
        : "",
  })





  const handleDeleteReels = async (userId: any) => {
    try {
      const response = await deleteRoles({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Reels deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };


  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.reels || []);
      setTotalCount(res?.count);
      setShowCount({
        active: res?.activeCount || 0,
        inactive: res?.inactiveCount || 0,
      })
    }
  }, [isSuccess, data]);


  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Reels</h1>
        </div>
        <Loader isLoad={isLoading || load} />
        <Card className="cards">
          <Box className="cards_header" marginBottom={'20px'}>
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <div className="control_group">
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range"
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined
                            });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Popper
                open={open1}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                    <div
                      style={{
                        padding: 16,
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Chip
                          label="Last Week"
                          variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                          onClick={() => handleChipClick("lastWeek")}
                        />
                        <Chip
                          label="Last Month"
                          variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                          onClick={() => handleChipClick("lastMonth")}
                        />
                        <Chip
                          label="Last Year"
                          variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                          onClick={() => handleChipClick("lastYear")}
                        />
                      </div>

                      <DayPicker
                        className="react-day-picker"
                        mode="range"
                        selected={range}
                        onSelect={handleSelect}
                        // numberOfMonths={10}
                        month={month}
                        onMonthChange={handleMonthChange}
                      // styles={{
                      //   months: { display: "flex", gap: "16px" },
                      //   month: { margin: 0 },
                      // }}
                      />
                    </div>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </Box>
          </Box>

          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >

              <Tab label={`Active Reels(${showCount?.active || 0})`}{...a11yProps(0)} />
              <Tab label={`Inactive Reels(${showCount?.inactive || 0})`} {...a11yProps(1)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Property Name</TableCell>
                      <TableCell>Posted By</TableCell>
                      <TableCell>Date & time</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.length == 0 ? (<TableRow>
                      <TableCell colSpan={12} align="center">
                        No reels found
                      </TableCell>
                    </TableRow>) :
                      (rows?.map((row: any, index: any) => (
                        <TableRow key={row?._id}>
                          <TableCell align="center">{index + 1}</TableCell>

                          <TableCell>{row?.name || "-"}</TableCell>
                          <TableCell>{row?.users?.name || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              <Tooltip title="View Details">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manage-reels/details/${row?._id}`)
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer className="table_container">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>

                      <TableCell>Property Name</TableCell>
                      <TableCell>Posted By</TableCell>
                      <TableCell>Date & time</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.length == 0 ? (<TableRow>
                      <TableCell colSpan={12} align="center">
                        No reels found
                      </TableCell>
                    </TableRow>) :
                      (rows?.map((row: any, index: any) => (
                        <TableRow key={row?._id}>
                          <TableCell align="center">{index + 1}</TableCell>

                          <TableCell>{row?.name || "-"}</TableCell>
                          <TableCell>{row?.users?.name || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <Tooltip title="View details">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manage-reels/details/${row?._id}`)
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>

        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteReels(selectedId)}
        name="Reels"
      />
    </div>
  );
};

export default ManageReels;
