import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import MainContainer from "../../layout/MainContainer";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  NativeSelect,
  Paper,
  Popper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import {
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  BarChart,
  Legend,
  Rectangle,
  Bar,
  PieChart,
  Cell,
  Tooltip,
  CartesianGrid,
  Pie,
} from "recharts";

import { LineChart } from "@mui/x-charts";
import { LineChartComponent } from "../../components/lineComponent";
import { MarginOutlined } from "@mui/icons-material";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

interface displayTextData {
  email: Email;
  whatsapp: Whatsapp;
  phone: Phone;
  inApp: InApp;
}
interface Email {
  emailLeads: string;
  emailLeadsNotreplied: string;
  emailLeadsReplied: string;
  emailLeadsResponseRate: string;
  emailLeadsResponseTime: string;
}
interface InApp {
  inAppChatLeadsResponseRate: string;
  inAppChatLeadsNotreplied: string;
  inAppChatLeadsResponseTime: string;
  inAppChatLeads: string;
  inAppChatLeadsPercentage: string;
}

interface Whatsapp {
  whatsappLeads: string;
  whatAppNotreplied: string;
  whatAppReplied: string;
  whatAppResponseRate: string;
  whatAppResponseTime: string;
}

interface Phone {
  phoneLeads: string;
  phoneLeadsAns: string;
  phoneLeadsAvg: string;
  phoneLeadsCancel: string;
  phoneLeadsUnans: string;
}

const LeadsInsight = ({
  filter,
  setFilter,
  pieChartData,
  getLeadsInsight,
  setLeadsSelectedApp,
  leadsSelectedApp,
  majorLineChartData,
  displayTextData,
  barGraphData
}: {
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
  pieChartData: any;
  getLeadsInsight: any;
  setLeadsSelectedApp: any;
  leadsSelectedApp: any;
  majorLineChartData: any;
  displayTextData: displayTextData;
  barGraphData?: any;
}) => {
  const targetRef1: any = useRef(null);
  const targetRef2: any = useRef(null);
  const targetRef3: any = useRef(null);
  const targetRef4: any = useRef(null);
  const [open, setOpen] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("");
  const [month, setMonth] = useState(new Date());
  console.log(barGraphData, "barGraphData");

  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const scrollToElement = (target: any) => {
    window.scrollTo({
      top: target,
      behavior: "smooth",
    });
  };

  const graphData1 = [
    { name: "22 ", count: 0 },
    { name: "26 ", count: 0 },
    { name: "30 ", count: 0 },
    { name: "4 ", count: 0 },
    { name: "8 ", count: 0 },
    { name: "12 ", count: 0 },
    { name: "16 ", count: 0 },
    { name: "20 ", count: 0 },
    { name: "30 ", count: 0 },
    { name: "4 ", count: 0 },
    { name: "8 ", count: 0 },
    { name: "12 ", count: 0 },
    { name: "16 ", count: 0 },
    { name: "20 ", count: 0 },
  ];

  const barGraphData1 = [
    { name: "Mon", count: 0 },
    { name: "Tue", count: 0 },
    { name: "Wed", count: 0 },
    { name: "Thu", count: 0 },
    { name: "Fri", count: 0 },
    { name: "Sat", count: 0 },
    { name: "Sun", count: 0 },
  ];
  const [field, setField] = useState<string>("count");

  const DonutChart = ({ data }: { data: any }) => {
    const viewData = data?.map((item: any) => {
      return {
        label: item?.label,
        value: Number(item?.value),
        color: item?.color,
      };
    });

    return (
      <ResponsiveContainer width="100%" height={250}>
        <PieChart>
          <Pie
            data={viewData}
            dataKey="value"
            nameKey="label"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
          >
            {data.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };

  let pieTotal = 0;

  pieChartData?.map((item: any) => {
    pieTotal += Number(item?.value);
  });

  const pieChartDataDummy = [
    {
      label: "No data available",
      value: 100,
      color: "#B0BEC5",
    },
  ];

  useEffect(() => {
    getLeadsInsight({ startDate: range?.from, endDate: range?.to });
  }, [filter, range]);



  return (
    <>
      <div className="page_heading flex main">
        <h3>Leads Insights</h3>
        <div className="flex_end">
          <div className="control_group">
            <TextField
              // label="Select Date Range"
              variant="outlined"
              hiddenLabel
              className="text_field-range"
              fullWidth
              onClick={handleClick}
              value={getFormattedRange()}
              placeholder="Select Date Range"
              InputProps={{
                readOnly: true,
                endAdornment: range?.from ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setRange({
                          from: undefined,
                          to: undefined,
                        });
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                <div
                  style={{
                    padding: 8,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "8px",
                    }}
                  >
                    <Chip
                      label="Last Week"
                      variant={
                        selectedChip === "lastWeek" ? "filled" : "outlined"
                      }
                      onClick={() => handleChipClick("lastWeek")}
                    />
                    <Chip
                      label="Last Month"
                      variant={
                        selectedChip === "lastMonth" ? "filled" : "outlined"
                      }
                      onClick={() => handleChipClick("lastMonth")}
                    />
                    <Chip
                      label="Last Year"
                      variant={
                        selectedChip === "lastYear" ? "filled" : "outlined"
                      }
                      onClick={() => handleChipClick("lastYear")}
                    />
                  </div>

                  <DayPicker
                    className="react-day-picker"
                    mode="range"
                    selected={range}
                    onSelect={handleSelect}
                    // numberOfMonths={10}
                    month={month}
                    onMonthChange={handleMonthChange}
                  // styles={{
                  //   months: { display: "flex", gap: "16px" },
                  //   month: { margin: 0 },
                  // }}
                  />
                </div>
              </Paper>
            </ClickAwayListener>
          </Popper>

          <div className="control_group">
            <Select
              className="form-control"
              labelId="category-label"
              id="category-select"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              displayEmpty
              hiddenLabel
            >
              <MenuItem value={"1"}>Yesterday</MenuItem>
              <MenuItem value={"2"}>Last 7 days</MenuItem>
              <MenuItem value={"3"}>Last 30 days</MenuItem>
              <MenuItem value={"4"}>Last 90 days</MenuItem>
              {/* <MenuItem value={"5"}>Last week</MenuItem>
          <MenuItem value={"6"}>Last month</MenuItem> */}
            </Select>
          </div>
        </div>
      </div>
      <div className="led_insgts_mn">
        {pieTotal === 0 ? (
          <Box className="bx_shdw">
            <DonutChart data={pieChartDataDummy} />
            <div className="pie_legend">
              {pieChartDataDummy.map((entry: any, index: number) => (
                <p key={index}>
                  <span style={{ color: entry.color }}>●</span> {entry.label} (
                  {entry.value}%)
                </p>
              ))}
            </div>
          </Box>
        ) : (
          <Box className="bx_shdw">
            <DonutChart data={pieChartData} />
            <div className="pie_legend">
              {pieChartData.map((entry: any, index: number) => (
                <p key={index}>
                  <span style={{ color: entry.color }}>●</span> {entry.label} (
                  {entry.value}%)
                </p>
              ))}
            </div>
          </Box>
        )}

        <Box className="led_insgts_ryt">
          <div className="lds_tbs_mn">
            <p>Direct leads over time</p>
            <div className="tbs_lds">
              <Button
                className={leadsSelectedApp == "" ? "active" : ""}
                onClick={() => {
                  setLeadsSelectedApp("");
                  setField("count");
                }}
              >
                All leads
              </Button>
              <Button
                className={leadsSelectedApp == "1" ? "active" : ""}
                onClick={() => {
                  setLeadsSelectedApp("1");
                  setField("phoneCount");
                  scrollToElement(targetRef1.current.offsetTop - 100);
                }}
              >
                Calls
              </Button>
              <Button
                className={leadsSelectedApp == "2" ? "active" : ""}
                onClick={() => {
                  setLeadsSelectedApp("2");
                  setField("inAppChatCount");
                  scrollToElement(targetRef2.current.offsetTop - 100);
                }}
              >
                In-App Chat
              </Button>
              <Button
                className={leadsSelectedApp == "3" ? "active" : ""}
                onClick={() => {
                  setLeadsSelectedApp("3");
                  setField("emailCount");
                  scrollToElement(targetRef3.current.offsetTop - 100);
                }}
              >
                Emails
              </Button>
              <Button
                className={leadsSelectedApp == "4" ? "active" : ""}
                onClick={() => {
                  setLeadsSelectedApp("4");
                  setField("whatAppCount");
                  scrollToElement(targetRef4.current.offsetTop - 100);
                }}
              >
                WhatsApp{" "}
              </Button>
            </div>
          </div>
          {/* <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            // series={[
            //   {
            //     // label:["first","second","third","fourth","five","six","seven"],

            //     data: [2, 5.5, 2, 8.5, 1.5, 5],
            //     color: "#3568c1",
            //   },
            // ]}
            height={300}
            grid={{ vertical: false, horizontal: true }}
          /> */}
          <LineChartComponent
            data={majorLineChartData}
            num={field}
            height={350}
          />
        </Box>
      </div>
      <div className="page_heading main" ref={targetRef1}>
        <h3>Call Insights</h3>
      </div>
      <div className="insights led_insgts_mn">
        <Box className=" w_50 crd_ui cl_insgts">
          <div className="insights">
            <div>
              <h4>Total calls</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.phone?.phoneLeads || "0"}
              </h3>
              {/* <p className="clrd"> +0%</p> */}
            </div>
            {/* <div>
              <h4>Average rate</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.phone?.phoneLeadsAvg
                  ? Number(displayTextData?.phone?.phoneLeadsAvg)
                  : "0"}
                %
              </h3>
              <p className="clrd"> +0%</p>
            </div>
            <div>
              <h4>Answered</h4>
              <h3 className="lrg_fnt">
                {Number(displayTextData?.phone?.phoneLeads) -
                  Number(displayTextData?.phone?.phoneLeadsUnans) || "0"}
              </h3>
            </div>
            <div>
              <h4>Unanswered</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.phone?.phoneLeadsUnans || "0"}
              </h3>
            </div>
            <div>
              <h4>Cancelled</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.phone?.phoneLeadsCancel || "0"}
              </h3>
            </div> */}
          </div>
        </Box>
        <Box className="crd_ui  w_50">
          <div className="lds_tbs_mn">
            <div className="tbs_lds">
              <Button style={{background:"#3568c1",color:"white"}}>Total calls</Button>
              {/* <Button>Answering rate</Button> */}
            </div>
          </div>
          <LineChartComponent
            data={majorLineChartData}
            num="phoneCount"
            height={250}
          />
        </Box>
        <ResponsiveContainer height={300} className="bar-graph crd_ui w_50">
          <BarChart data={barGraphData?.phoneLeadsGraphData?.dailyPerWeek?.userData?.length ? barGraphData?.phoneLeadsGraphData?.dailyPerWeek?.userData : barGraphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Tooltip />

            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {barGraphData?.phoneLeadsGraphData?.dailyPerWeek?.userData?.length
                ? barGraphData.phoneLeadsGraphData.dailyPerWeek.userData.map((entry: any, index: number) => (
                  <Cell
                    key={index}
                    name={entry.name}
                    fill={index % 2 === 0 ? "#222" : "#555"}
                  />
                ))
                : barGraphData1?.map((entry: any, index: number) => (
                  <Cell
                    key={index}
                    name={entry.name}
                    fill={index % 2 === 0 ? "#222" : "#555"}
                  />
                ))}
            </Bar>

          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer height={300} className="bar-graph crd_ui w_50">
          <BarChart data={barGraphData?.phoneLeadsGraphData?.hourly?.leadsData?.length ? barGraphData?.phoneLeadsGraphData?.hourly?.leadsData?.length : graphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {barGraphData?.phoneLeadsGraphData?.hourly?.leadsData?.length ? (
                barGraphData?.phoneLeadsGraphData?.hourly?.leadsData?.map((entry: any, index: number) => (
                  <Cell
                    key={index}
                    name={entry.name}
                    fill={index % 2 === 0 ? "#222" : "#555"}
                  />
                ))
              ) : (
                graphData1?.map((entry: any, index: number) => (
                  <Cell
                    key={index}
                    name={entry.name}
                    fill={index % 2 === 0 ? "#222" : "#555"}
                  />
                ))
              )

              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="page_heading main" ref={targetRef2}>
        <h3>In-App Chat Insights</h3>
      </div>
      <div className="insights led_insgts_mn">
        <Box className="crd_ui cl_insgts w_50">
          <div className="insights">
            <div>
              <h4>In-app Chat leads</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.inApp?.inAppChatLeads || "0"}
              </h3>
              {/* <p className="clrd"> +0%</p> */}
            </div>
            <div>
              <h4>Response rate</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.inApp?.inAppChatLeadsResponseRate
                  ? Number(
                    displayTextData?.inApp?.inAppChatLeadsResponseRate
                  ).toFixed(2)
                  : "0"}
                %
              </h3>
              {/* <p className="clrd"> +0%</p> */}
            </div>
            <div>
              <h4>Replied</h4>
              <h3 className="lrg_fnt">
                {Number(displayTextData?.inApp?.inAppChatLeads) -
                  Number(displayTextData?.inApp?.inAppChatLeadsNotreplied) ||
                  "0"}
              </h3>
            </div>
            <div>
              <h4>Not replied</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.inApp?.inAppChatLeadsNotreplied || "0"}
              </h3>
            </div>
            <div>
              <h4>Response time</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.inApp?.inAppChatLeadsResponseTime
                  ? Number(
                    displayTextData?.inApp?.inAppChatLeadsResponseTime
                  ).toFixed(2)
                  : "0"}
                m
              </h3>
            </div>
          </div>
        </Box>
        <Box className=" w_50 crd_ui">
          <div className="lds_tbs_mn">
            <div className="tbs_lds">
              <Button>In-app Chat leads</Button>
              <Button>Response rate</Button>
              <Button>Response time</Button>
            </div>
          </div>
          <LineChartComponent
            data={majorLineChartData}
            num="inAppChatCount"
            height={250}
          />
        </Box>
        <ResponsiveContainer height={300} className="bar-graph crd_ui w_50">
          <BarChart data={barGraphData?.inAppChatLeadsGraphData?.dailyPerWeek?.userData?.length ? barGraphData?.inAppChatLeadsGraphData?.dailyPerWeek?.userData : barGraphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Tooltip />

            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {
                barGraphData?.inAppChatLeadsGraphData?.dailyPerWeek?.userData?.length ? (
                  barGraphData?.inAppChatLeadsGraphData?.dailyPerWeek?.userData?.map((entry: any, index: number) => (
                    <Cell
                      key={index}
                      name={entry.name}
                      fill={index % 2 === 0 ? "#222" : "#555"}
                    />
                  ))
                ) : (
                  barGraphData1?.map((entry: any, index: number) => (
                    <Cell
                      key={index}
                      name={entry.name}
                      fill={index % 2 === 0 ? "#222" : "#555"}
                    />
                  ))

                )

              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer height={300} className="bar-graph crd_ui  w_50">
          <BarChart data={barGraphData?.inAppChatLeadsGraphData?.hourly?.leadsData?.length ? barGraphData?.inAppChatLeadsGraphData?.hourly?.leadsData : graphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {
                barGraphData?.inAppChatLeadsGraphData?.hourly?.leadsData?.length ? (
                  barGraphData?.inAppChatLeadsGraphData?.hourly?.leadsData?.map((entry: any, index: number) => (
                    <Cell
                      key={index}
                      name={entry.name}
                      fill={index % 2 === 0 ? "#222" : "#555"}
                    />
                  ))

                ) : (
                  graphData1?.map((entry: any, index: number) => (
                    <Cell
                      key={index}
                      name={entry.name}
                      fill={index % 2 === 0 ? "#222" : "#555"}
                    />
                  ))

                )
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="page_heading main" ref={targetRef4}>
        <h3>WhatsApp Insights</h3>
      </div>
      <div className="insights led_insgts_mn">
        <Box className="crd_ui cl_insgts w_50">
          <div className="insights">
            <div>
              <h4>WhatsApp leads</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.whatsapp?.whatsappLeads || "0"}
              </h3>
              {/* <p className="clrd"> +0%</p> */}
            </div>
            <div>
              <h4>Response rate</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.whatsapp?.whatAppResponseRate
                  ? Number(
                    displayTextData?.whatsapp?.whatAppResponseRate
                  ).toFixed(2)
                  : "0"}
                %
              </h3>
              {/* <p className="clrd"> +0%</p> */}
            </div>
            <div>
              <h4>Replied</h4>
              <h3 className="lrg_fnt">
                {Number(displayTextData?.whatsapp?.whatsappLeads) -
                  Number(displayTextData?.whatsapp?.whatAppNotreplied) || "0"}
              </h3>
            </div>
            <div>
              <h4>Not replied</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.whatsapp?.whatAppNotreplied || "0"}
              </h3>
            </div>
            <div>
              <h4>Response time</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.whatsapp?.whatAppResponseTime
                  ? Number(
                    displayTextData?.whatsapp?.whatAppResponseTime
                  ).toFixed(2)
                  : "0"}
                m
              </h3>
            </div>
          </div>
        </Box>
        <Box className=" w_50 crd_ui">
          <div className="lds_tbs_mn">
            <div className="tbs_lds">
              <Button>WhatsApp leads</Button>
              <Button>Response rate</Button>
              <Button>Response time</Button>
            </div>
          </div>
          <LineChartComponent
            data={majorLineChartData}
            num="whatAppCount"
            height={250}
          />
        </Box>
        <ResponsiveContainer height={300} className="bar-graph crd_ui w_50">
          <BarChart data={barGraphData?.whatsappLeadsGraphData?.dailyPerWeek?.userData?.length ? barGraphData?.whatsappLeadsGraphData?.dailyPerWeek?.userData : barGraphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {
                barGraphData?.whatsappLeadsGraphData?.dailyPerWeek?.userData?.length ? (barGraphData?.whatsappLeadsGraphData?.dailyPerWeek?.userData?.map((entry: any, index: number) => (
                  <Cell
                    key={index}
                    name={entry.name}
                    fill={index % 2 === 0 ? "#222" : "#555"}
                  />
                ))) : (barGraphData1?.map((entry: any, index: number) => (
                  <Cell
                    key={index}
                    name={entry.name}
                    fill={index % 2 === 0 ? "#222" : "#555"}
                  />
                )))


              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer height={300} className="bar-graph crd_ui  w_50">
          <BarChart data={barGraphData?.whatsappLeadsGraphData?.hourly?.leadsData?.length ? barGraphData?.whatsappLeadsGraphData?.hourly?.leadsData : graphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {barGraphData?.whatsappLeadsGraphData?.hourly?.leadsData?.length ? (
                barGraphData?.whatsappLeadsGraphData?.hourly?.leadsData?.map((entry: any, index: number) => (
                  <Cell
                    key={index}
                    name={entry.name}
                    fill={index % 2 === 0 ? "#222" : "#555"}
                  />
                ))
              ) : (graphData1?.map((entry: any, index: number) => (
                <Cell
                  key={index}
                  name={entry.name}
                  fill={index % 2 === 0 ? "#222" : "#555"}
                />
              )))

              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="page_heading main" ref={targetRef3}>
        <h3>Email Insights</h3>
      </div>
      <div className="insights led_insgts_mn">
        <Box className=" w_50 crd_ui cl_insgts">
          <div className="insights">
            <div>
              <h4>Email leads</h4>
              <h3 className="lrg_fnt">
                {" "}
                {displayTextData?.email?.emailLeads || "0"}
              </h3>
              {/* <p className="clrd"> +0%</p> */}
            </div>
            {/* <div>
              <h4>Response rate</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.email?.emailLeadsResponseRate || "0"}%
              </h3>
               <p className="clrd"> +0%</p> 
            </div>*/}
            <div>
              <h4>Replied</h4>
              <h3 className="lrg_fnt">
                {Number(displayTextData?.email?.emailLeads) -
                  Number(displayTextData?.email?.emailLeadsNotreplied) || "0"}
              </h3>
            </div>
            <div>
              <h4>Not replied</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.email?.emailLeadsNotreplied || "0"}
              </h3>
            </div>
            {/* <div>
              <h4>Response time</h4>
              <h3 className="lrg_fnt">
                {displayTextData?.email?.emailLeadsResponseTime || "0"}m
              </h3>
            </div> */}
          </div>
        </Box>
        <Box className=" w_50 crd_ui">
          <div className="lds_tbs_mn">
            <div className="tbs_lds">
              <Button style={{background:"#3568c1",color:"white"}}>Email leads</Button>
              
            </div>
          </div>
          <LineChartComponent
            data={majorLineChartData}
            num="emailCount"
            height={250}
          />
        </Box>
        <ResponsiveContainer height={300} className="bar-graph crd_ui  w_50">
          <BarChart data={barGraphData?.emailLeadsGraphData?.dailyPerWeek?.userData?.length ? barGraphData?.emailLeadsGraphData?.dailyPerWeek?.userData : barGraphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {barGraphData?.emailLeadsGraphData?.dailyPerWeek?.userData?.length ? (barGraphData?.emailLeadsGraphData?.dailyPerWeek?.userData?.map((entry: any, index: number) => (
                <Cell
                  key={index}
                  name={entry.name}
                  fill={index % 2 === 0 ? "#222" : "#555"}
                />
              ))
              ) : (barGraphData1?.map((entry: any, index: number) => (
                <Cell
                  key={index}
                  name={entry.name}
                  fill={index % 2 === 0 ? "#222" : "#555"}
                />
              ))
              )
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer height={300} className="bar-graph crd_ui  w_50">
          <BarChart data={barGraphData?.emailLeadsGraphData?.hourly?.leadsData?.length ? barGraphData?.emailLeadsGraphData?.hourly?.leadsData : graphData1} width={500} height={300}>
            <XAxis dataKey="name" tick={{ fill: "#000000" }} stroke="red" />
            {/* <YAxis /> */}
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="uv" radius={[10, 10, 0, 0]}>
              {barGraphData?.emailLeadsGraphData?.hourly?.leadsData?.length ? (barGraphData?.emailLeadsGraphData?.hourly?.leadsData?.map((entry: any, index: number) => (
                <Cell
                  key={index}
                  name={entry.name}
                  fill={index % 2 === 0 ? "#222" : "#555"}
                />
              ))) : (graphData1?.map((entry: any, index: number) => (
                <Cell
                  key={index}
                  name={entry.name}
                  fill={index % 2 === 0 ? "#222" : "#555"}
                />
              )))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default LeadsInsight;
