import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,

    Typography,
    Tabs,
    Tab,
    Button,

} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";

import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, CompanyRequest } from "../../types/General";

import Pagination from "../../components/Pagination";
import Loader from "../../helpers/constants/Loader";
import { useLazyGetAllUserQuery } from "../../services/users";
import { errorToast } from "../../helpers";
import ResetPasswordModal from "../../components/ResetPasswordModal";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ManageResetPassword = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [getUsersApi] = useLazyGetAllUserQuery();
    const [users, setUsers] = useState<any>([])
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [limit, setLimit] = useState<number>(10);
    const [value, setValue] = React.useState(0);
    let totalPages = Math.ceil(totalCount / limit);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>("")
    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const res = await getUsersApi({
                page: page,
                search: debouncedSearchTerm,
                limit: limit,
                role: value === 0 ? 4 : value === 1 ? 5 : value === 2 ? 3 : 4,
                startDate: "",
                endDate: ""
            }).unwrap();
            if (res?.statusCode === 200) {
                setUsers(res?.data?.user);
                setTotalCount(res?.data?.totalCount);
            }
            setIsLoading(false);
        } catch (error: any) {
            errorToast(error?.data?.message)
            setIsLoading(false);
        }
    }

    const onPageChange = (newPage: number) => {
        setPage(newPage);
    };


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setPage(1);
    };


    useEffect(() => {
        fetchUsers()
    }, [page, limit, debouncedSearchTerm, value])

    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Manage Reset Password</h1>
                </div>
                <Card className="cards">
                    <Box className="custom_tabs">
                        <Box className="cards_header" marginBottom={"20px"}>
                            <Box className="cards_header_left">
                                <SearchBar2
                                    value={searchTerm}
                                    searchTerm={searchTerm}
                                    onCross={() => setSearchTerm("")}
                                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                                    onChange={(val: any) => {
                                        if (isValidInput(val.target.value)) {
                                            setSearchTerm(val.target.value);
                                        }
                                    }}
                                />
                            </Box>

                        </Box>

                        <Box className="custom_tabs">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="basic tabs example"
                                className="custom_tabs_links"
                            >
                                <Tab
                                    label={`Users`}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={`Companies`}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    label={`Agents`}
                                    {...a11yProps(2)}
                                />
                            </Tabs>

                            <CustomTabPanel value={value} index={0}>
                                <TableContainer className="table_container">
                                    <Box className="heading"></Box>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">S.No</TableCell>
                                                <TableCell>User id</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Phone no</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users?.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={9} align="center">
                                                        No user found
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                users?.map((row: any, i: any) => (
                                                    <TableRow key={i}>
                                                        <TableCell align="center">
                                                            {(page - 1) * 10 + i + 1}
                                                        </TableCell>
                                                        <TableCell>{row?.referenceId || "-"}</TableCell>
                                                        <TableCell>{row?.name || "-"}</TableCell>
                                                        <TableCell>
                                                            {row?.dialCode + row?.phoneNo || "-"}
                                                        </TableCell>
                                                        <TableCell>{row?.email || "-"}</TableCell>

                                                        <TableCell>
                                                            <Button onClick={() => { setSelectedId(row?._id); setOpen(true) }} className="btn_primary btn reset_btn">
                                                                Reset Password
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {users?.length > 0 ? (
                                    <Pagination
                                        setPage={setPage}
                                        limit={limit}
                                        setLimit={setLimit}
                                        module={users}
                                        page={page}
                                        onPageChange={onPageChange}
                                        totalPages={totalPages}
                                    />
                                ) : (
                                    ""
                                )}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <TableContainer className="table_container">
                                    <Box className="heading"></Box>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">S.No</TableCell>
                                                <TableCell>Company id</TableCell>
                                                <TableCell>Image</TableCell>
                                                <TableCell>Company Name</TableCell>
                                                <TableCell>Phone no</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users?.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={9} align="center">
                                                        No company found
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                users?.map((row: any, i: any) => (
                                                    <TableRow key={i}>
                                                        <TableCell align="center">
                                                            {(page - 1) * 10 + i + 1}
                                                        </TableCell>
                                                        <TableCell>{row?.referenceId || "-"}</TableCell>
                                                        <TableCell>
                                                            <figure
                                                                className="user_img"
                                                            >
                                                                <img
                                                                    style={{ borderRadius: "50%" }}
                                                                    src={
                                                                        row?.image ||
                                                                        `/static/images/user_placeholder.png`
                                                                    }
                                                                    alt=""
                                                                />
                                                            </figure>
                                                        </TableCell>
                                                        <TableCell>{row?.companyName || "-"}</TableCell>
                                                        <TableCell>
                                                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                                                        </TableCell>
                                                        <TableCell>{row?.email || "-"}</TableCell>

                                                        <TableCell>
                                                            <Button onClick={() => { setSelectedId(row?._id); setOpen(true) }} className="btn_primary btn reset_btn">
                                                                Reset Password
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {users?.length > 0 ? (
                                    <Pagination
                                        setPage={setPage}
                                        limit={limit}
                                        setLimit={setLimit}
                                        module={users}
                                        page={page}
                                        onPageChange={onPageChange}
                                        totalPages={totalPages}
                                    />
                                ) : (
                                    ""
                                )}

                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <TableContainer className="table_container">
                                    <Box className="heading"></Box>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">S.No</TableCell>
                                                <TableCell>Agent id</TableCell>
                                                <TableCell>Image</TableCell>
                                                <TableCell>Agent Name</TableCell>
                                                <TableCell>Phone no</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Company Name</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users?.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={9} align="center">
                                                        No agent found
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                users?.map((row: any, i: any) => (
                                                    <TableRow key={i}>
                                                        <TableCell align="center">
                                                            {(page - 1) * 10 + i + 1}
                                                        </TableCell>
                                                        <TableCell>{row?.referenceId || "-"}</TableCell>
                                                        <TableCell>
                                                            <figure
                                                                className="user_img"
                                                            >
                                                                <img
                                                                    style={{ borderRadius: "50%" }}
                                                                    src={
                                                                        row?.image ||
                                                                        `/static/images/user_placeholder.png`
                                                                    }
                                                                    alt=""
                                                                />
                                                            </figure>
                                                        </TableCell>
                                                        <TableCell>{row?.name || "-"}</TableCell>
                                                        <TableCell>
                                                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                                                        </TableCell>
                                                        <TableCell>{row?.email || "-"}</TableCell>
                                                        <TableCell>{row?.companyName ? row?.companyName : "-"}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => { setSelectedId(row?._id); setOpen(true) }} className="btn_primary btn reset_btn">
                                                                Reset Password
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {users?.length > 0 ? (
                                    <Pagination
                                        setPage={setPage}
                                        limit={limit}
                                        setLimit={setLimit}
                                        module={users}
                                        page={page}
                                        onPageChange={onPageChange}
                                        totalPages={totalPages}
                                    />
                                ) : (
                                    ""
                                )}
                            </CustomTabPanel>

                        </Box>
                    </Box>
                </Card>
            </div>
            <ResetPasswordModal
                open={open}
                setOpen={setOpen}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
            />
        </div>
    );
};

export default ManageResetPassword;
