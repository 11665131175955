import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


export const homeDesignApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postDesign: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.homeDesign}`,
                method: "POST",
                body,
            }),
        }),
        getAllDesign: builder.query<CommonResponseType & { data: any },
            {
                page?: number;
                search?: string;
                limit?: number;
            }>({
                query: ({ page, limit, search }) => {
                    let url = `${END_POINTS.homeDesign}?page=${page}&limit=${limit}&search=${search}`;
                    return {
                        url: url,
                        method: "GET",
                    };
                },
            }),

        deleteDesign: builder.query<CommonResponseType & { data: any },
            {
                id: string
            }>({
                query: (id) => {
                    let url = `${END_POINTS.homeDesign}/${id}`;
                    return {
                        url: url,
                        method: "DELETE",
                    };
                },
            }),




    }),
});

export const {
    usePostDesignMutation,
    useLazyGetAllDesignQuery,
    useLazyDeleteDesignQuery
} = homeDesignApi;
