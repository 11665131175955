import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Chip,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDeleteChatByIdMutation, useGetAllChatsQuery, useLazyGetAllChatsQuery } from "../../services/chat";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import { errorToast, successToast } from "../../helpers/toast";
import { format } from 'date-fns';
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import { Clear } from "@mui/icons-material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ManageChat = () => {
  const navigate = useNavigate();
  const [chatListing, { isLoading }] = useLazyGetAllChatsQuery();
  const [rows, setRows] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteChatById] = useDeleteChatByIdMutation();
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [type, setType] = useState("");
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });


  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };




  // const fetchApi = async (page: any, search: any) => {
  //   try {
  //     const response = await chatListing({
  //       page: page, limit: limit, chatType: type, search: debouncedSearchTerm,
  //       range: {
  //         from: range?.from ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
  //         to: range?.to ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : ""
  //       }
  //     }).unwrap();
  //     if (response?.statusCode === 200) {
  //       setRows(response?.data?.chats || []);
  //       setTotalCount(response?.data?.count);
  //     }
  //     else {

  //     }
  //   }
  //   catch (e) {

  //   }
  // }


  const { data, isError, isSuccess, isLoading: load } = useGetAllChatsQuery({
    page: page, limit: limit, chatType: type, search: debouncedSearchTerm.trim(),
    range: {
      from: range?.from ? moment(range?.from).startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
      to: range?.from === range?.to ? "" : range?.to ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
    }
  })


  const formatDateTime = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = format(new Date(dateString), 'yyyy-MM-dd');
    const time = format(new Date(dateString), 'HH:mm:ss');
    return ` ${date}, Time: ${time}`;
  };

  const handleDeleteChat = async (userId: any) => {
    try {
      const response = await deleteChatById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Chat deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.chats || []);
      setTotalCount(res?.count);
    }
  }, [isSuccess, data]);
  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Chat</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
            </Box>
            <Box className="cards_header_right">
              <div className="control_group">
                <Select
                  className="childFilter"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  <MenuItem value="1">Company to Agent </MenuItem>
                  <MenuItem value="2">Agent to Company</MenuItem>
                  <MenuItem value="3">Lead to Company</MenuItem>
                  <MenuItem value="4">Lead to Agent</MenuItem>
                </Select>
              </div>
              <div className="control_group">
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range "
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end" style={{ marginLeft: -50 }}>
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Popper
                  open={open1}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  disablePortal
                  style={{
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                      <div
                        style={{
                          padding: 8,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: "8px",
                          }}
                        >
                          <Chip
                            label="Last Week"
                            variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastWeek")}
                          />
                          <Chip
                            label="Last Month"
                            variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastMonth")}
                          />
                          <Chip
                            label="Last Year"
                            variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastYear")}
                          />
                        </div>

                        <DayPicker
                          className="react-day-picker"
                          mode="range"
                          selected={range}
                          onSelect={handleSelect}
                          // numberOfMonths={10}
                          month={month}
                          onMonthChange={handleMonthChange}
                        // styles={{
                        //   months: { display: "flex", gap: "16px" },
                        //   month: { margin: 0 },
                        // }}
                        />
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
              <Button
                className="btn btn_danger"
                onClick={() => {
                  setType("");
                  setRange({ from: undefined, to: undefined })
                }}
              >
                Clear filters
              </Button>
            </Box>
          </Box>

          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  {/* <TableCell>Company Name</TableCell>
                  <TableCell>Agent Name</TableCell> */}
                  <TableCell>Date</TableCell>
                  <TableCell>Property </TableCell>
                  {/* <TableCell>Title</TableCell> */}


                  <TableCell align="center">Action</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No chat found
                    </TableCell>
                  </TableRow>
                ) : (rows.map((row: any, index: any) => (
                  <TableRow key={row?._id}>
                    <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                    <TableCell>{row?.senders?.role === 3 ? row?.senders?.name + " (" + row?.senders?.referenceId + ")" : row?.senders?.role === 5 ? row?.senders?.companyName + " (" + row?.senders?.referenceId + ")" : row?.senders?.name + " (" + row?.senders?.referenceId + ")"}</TableCell>
                    <TableCell>{row?.receivers?.role === 3 ? row?.receivers?.name + " (" + row?.receivers?.referenceId + ")" : row?.receivers?.role === 5 ? row?.receivers?.companyName + " (" + row?.receivers?.referenceId + ")" : row?.receivers?.name + " (" + row?.receivers?.referenceId + ")"}</TableCell>

                    {/* <TableCell>Company Name</TableCell>
                    <TableCell>Agent Name</TableCell> */}
                    <TableCell>{formatDateTime(row?.createdAt) || "-"}</TableCell>
                    <TableCell>{row?.properties?.name || "Personal chat"}</TableCell>


                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate(`/manage-chat/details/${row?.connectionId}`)}
                        >
                          <VisibilityIcon />

                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedId(row?.chatId);
                            setOpen(true)
                          }
                          }>
                          <DeleteIcon />
                        </IconButton>

                      </Box>
                    </TableCell>

                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteChat(selectedId)}
        name="Chat"
      />
    </div>
  );
};

export default ManageChat;
