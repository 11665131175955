import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getAllLeads: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit?: number, startDate?: any, endDate?: any, leadType: number, propertyId?: string }>({
            query: ({ page, search, limit, startDate, endDate, leadType, propertyId }) => {
                let url = `${END_POINTS.propertyLeads}?page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&leadType=${leadType}${propertyId ? `&propertyId=${propertyId}` : ""}`;

                return {
                    url: url,
                    method: "GET",
                };
            },
        }),


        addLeads: builder.mutation<CommonResponseType & { data: any }, CommonBody>({
            query: (body) => {
                let url = `${END_POINTS.addLeads}`;

                return {
                    url: url,
                    method: "POST",
                    body
                };
            },
        }),

        DeleteLeads: builder.query<CommonResponseType & { data: any }, { id: string }>({
            query: ({ id }) => {
                let url = `${END_POINTS.deleteLeads}/${id}`;
                return {
                    url: url,
                    method: "DELETE",
                };
            },
        }),


    }),
});

export const {
    useLazyGetAllLeadsQuery,
    useAddLeadsMutation,
    useLazyDeleteLeadsQuery
} = authApi;
