import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Autocomplete } from "@react-google-maps/api";
import PhoneInput from "react-phone-input-2";
import { useAddUserApiMutation, useEditUserMutation } from "../../../services/users";
import { UploadMedia } from "../../../utils/uploadMedia";
import { errorToast, successToast } from "../../../helpers";
import { useLazyGetSpecificAgentByIdQuery } from "../../../services/agents";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import Loader from "../../../helpers/constants/Loader";
import { isNumber } from "../../../utils/validation";
import { usePostDesignMutation } from "../../../services/homeDesign";

const AddHomeDesign = () => {
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState("");

  const [addUser] = usePostDesignMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        formik.setFieldValue("profile_picture", imageUrl);
        setProfilePicture(imageUrl);
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };



  const formik = useFormik({
    initialValues: {
      name: "",
      profile_picture: "",
    },
    validationSchema: Yup.object({

      name: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed")



    }),
    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);
      let body: any = {
        name: values.name || "",
        image: values?.profile_picture || "",
      };


      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        setIsLoading(true);
        const response = await addUser(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Design added successfully");
          navigate("/home-design");
        }
        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        setIsLoading(false);
        errorToast(error?.data?.message);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }

    },
  });



  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Home Design</h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/home-design")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit} >
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {formik.values.profile_picture ? (
                    <div className="upload_image_preview">
                      <CardMedia
                        component="img"
                        style={{ borderRadius: 0 }}
                        image={formik.values.profile_picture}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={(e) => {
                          e.preventDefault();
                          formik.setFieldValue("profile_picture", "");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={handleImageUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          <img style={{ borderRadius: "0" }} src="/static/images/product1.png" alt="" />
                          <CameraAltIcon />
                        </Button>
                      </label>

                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Name</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="name"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Name"
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name.toString()
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default AddHomeDesign;
