import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody, CompanyRequest } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string;
};

type Res = {
  pendingRequests: number;
  aproovedRequests: number;
  rejectedRequests: number;
  totalCount(totalCount: any): unknown;
  adminRequests: CompanyRequest[];
  count: number;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRequest: builder.query<
      CommonResponseType & { data: Res },
      {
        page?: number;
        search?: string;
        limit: number;
        status?: number;
        range?: {
          from: string | undefined;
          to: string | undefined;
        };
        agencyType?: number;
      }
    >({
      query: ({ page, search, limit, status, range, agencyType }) => {
        let url = `${END_POINTS.adminRequests}?status=${status}&page=${page}&search=${search}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}${agencyType ? `&agencyType=${agencyType}` : ''}`;
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["GETCOMPANYREQ"],
    }),
    getRequestById: builder.query<
      CommonResponseType & { data: CompanyRequest },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.adminRequests}/${userId}`,
        method: "GET",
      }),
    }),

    PostReason: builder.mutation<
      CommonResponseType & { data: any },
      { body: CommonBody; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.adminRequests}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["GETCOMPANYREQ"],
    }),


    deleteRequestById: builder.mutation<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.adminRequests}/delete/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GETCOMPANYREQ"],
    }),
  }),
});

export const {
  useGetAllRequestQuery,
  useLazyGetRequestByIdQuery,
  usePostReasonMutation,
  useDeleteRequestByIdMutation
} = authApi;
