import io from "socket.io-client";

// This is the socket URL development link
export const Socket_URL = process.env.REACT_APP_SOCKET_URL

// staging links
// export const Socket_URL = `https://getprop-admin-staging.appgrowthcompany.com/`;

//sandbox
// export const Socket_URL = `https://sandbxadminsocket.getprop.ae/`;


export const socket = io(Socket_URL, {
  autoConnect: false,
});
