import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";

type props = {
  module: any;
  page: number;
  onPageChange: (newPage: number) => void;
  totalPages: number;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  setPage: Dispatch<SetStateAction<number>>;
};

const Pagination = ({
  module,
  page,
  onPageChange,
  totalPages,
  setPage,
  limit,
  setLimit
}: props) => {
  
  return (
    <div className="pagination" style={{ marginTop: "20px" }}>
      <p>
        <span>Showing {module?.length ? `${module?.length}` : 0} items </span>
      </p>
      <div className="pages">
        {totalPages >= 1 ? (
          <FormControl fullWidth>
            <Select
              className="dropse"
              style={{ width: 60 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              value={limit}
              onChange={(flag: any) => { setPage(1); setLimit(flag.target.value) }}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value={10}>
                10
              </MenuItem>
              <MenuItem value={25}>
                25
              </MenuItem>
              <MenuItem value={50}>
                50
              </MenuItem>
              <MenuItem value={100}>
                100
              </MenuItem>

            </Select>
          </FormControl>
        ) : null}

        <button
          className="prevbtn"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
          type="button"
        >
          <ArrowBackIosIcon />
        </button>
        <div className="count">
          {totalPages > 6
            ? Array.from({ length: totalPages })
              .slice(0, 5)
              .map((_, index) => (
                <button
                  className={page === index + 1 ? "active" : ""}
                  key={index}
                  onClick={() => onPageChange(index + 1)}
                  disabled={page === index + 1}
                  type="button"
                >
                  {index + 1}
                </button>
              ))
              .concat(
                <span
                  key="ellipsis"
                  className="ellipsis"
                  style={{ color: "black" }}
                >
                  ...
                </span>,
                <button
                  className="active"
                  type="button"
                  style={{
                    display:
                      page < 6 || page === totalPages ? "none" : undefined,
                  }}
                  key={totalPages}
                >
                  {page}
                </button>,
                <button
                  className={page === totalPages ? "active" : "inActv"}
                  key={totalPages}
                  type="button"
                  onClick={() => onPageChange(totalPages)}
                  disabled={page === totalPages}
                >
                  {totalPages}
                </button>
              )
            : Array.from({ length: totalPages }).map((_, index) => (
              <button
                className={page === index + 1 ? "active" : "inActv"}
                type="button"
                key={index}
                onClick={() => onPageChange(index + 1)}
                disabled={page === index + 1}
              >
                {index + 1}
              </button>
            ))}
        </div>
        <button
          className="prevbtn"
          type="button"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  );
};

export default Pagination;