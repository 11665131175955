import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";


type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};


export const emailApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getAllNotifyEmail: builder.query<CommonResponseType & { data: any }, {
      page?: number; search?: string; limit?: number, range?: {
        from: string | undefined;
        to: string | undefined;
      };
    }>({
      query: ({ page, search, limit, range }) => {
        let url = `${END_POINTS.notifyList}?page=${page}&search=${search}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}`;

        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 3,
      providesTags: ["GETEMAIL"]
    }),

    exportNotify: builder.query<CommonResponseType & { data: any }, {}>({
      query: ({ }) => {
        let url = END_POINTS.notifyExport;
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getAllSubscribeEmail: builder.query<CommonResponseType & { data: any }, {
      page?: number; limit?: number, range?: {
        from: string | undefined;
        to: string | undefined;
      };
    }>({
      query: ({ page, limit, range }) => {
        let url = `${END_POINTS.getSubscribedEmailsList}?page=${page}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}`;
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    deleteEmail: builder.query<CommonResponseType & { data: any }, {
      id: string
    }>({
      query: ({ id }) => {
        let url = `${END_POINTS.getSubscribedEmailsList}/delete/${id}`;
        return {
          url: url,
          method: "DELETE",
        };
      },
    }),



  }),
});

export const {
  useLazyExportNotifyQuery,
  useGetAllNotifyEmailQuery,
  useLazyGetAllSubscribeEmailQuery,
  useLazyDeleteEmailQuery
} = emailApi;
