import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};


export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getSubscriptionListing: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit: number; startDate: string, endDate: string, revenueType: number }>({
      query: ({ page, search, limit, startDate, endDate, revenueType }) => {
        let url = `${END_POINTS.manageRevenue}?revenueType=${revenueType}&page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["GETREVENUE"]
    }),

    getSuscriptionDetails: builder.query<CommonResponseType & { data: any }, { page?: number;  limit?: number; id?: string }>({
      query: ({ page, limit, id }) => {
        let url = `${END_POINTS.manageRevenue}/${id}?page=${page}&limit=${limit}`;
        return {
          url: url,
          method: "GET",
        };
      },
     
    }),


    exportRevenueCsvSubscribe: builder.query<CommonResponseType & { data: any }, { revenueType: number }>({
      query: ({ revenueType }) => {
        let url = END_POINTS.manageRevenue;
        url += `?revenueType=${revenueType}&isExport=true`;

        return {
          url: url,
          method: "GET",
        };
      },
    }),


  }),
});

export const {
  useLazyGetSubscriptionListingQuery,
  useGetSubscriptionListingQuery,
  useLazyGetSuscriptionDetailsQuery,
  useLazyExportRevenueCsvSubscribeQuery,

} = authApi;
