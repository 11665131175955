import { CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSortable } from '@dnd-kit/sortable';
import { useAppDispatch } from '../hooks/store';

interface DraggableImageProps {
  item: string;
  index: number;
  handleCrossClick: (e: React.MouseEvent, index: number) => void;
  id: string; // Change to string type for unique ID
}

const DraggableImage: React.FC<DraggableImageProps> = ({
  item,
  index,
  handleCrossClick,
  id,
}) => {
  const dispatch = useAppDispatch();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
      transition: {
          duration: 200,
          easing: ''
      },
    });

  const style = {
    // transition,
    transform: transform
      ? `translate(${transform.x}px, ${transform.y}px)`
      : undefined,
    padding: '10px',

    boxSizing: 'border-box',
    backgroundColor: transform ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
    cursor: 'grab',
    zIndex: transform ? 1000 : 1,
  };



  return (
    <div className="upload_image upload_property w_25">
      <div className="upload_image_holder">
        {/* <div > */}
        <figure ref={setNodeRef} style={style} {...attributes} {...listeners}>
          {item.endsWith('.mp4') ||
          item.endsWith('.avi') ||
          item.endsWith('.mkv') ? (
            <CardMedia
              component="video"
              src={item}
              controls
              autoPlay
              loop
              sx={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <CardMedia component="img" image={item} alt="photo" />
          )}
        </figure>
        {/* </div> */}
        <CloseIcon onClick={(e) => handleCrossClick(e, index)} />
        {/* <AutoFixHighIcon onClick={() => openRedesignModal(item)} /> */}
      </div>
    </div>
  );
};

export default DraggableImage;