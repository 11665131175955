import { useEffect, useState } from "react";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { errorToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import { useLazyGetAllTransactionsQuery } from "../../services/agents";
import moment from "moment";
import { PropertyType } from "../../types/General";
import { useLazyGetAllpropertyTypeQuery } from "../../services/propertyType";
import { useLazyGetEmailHistoryQuery } from "../../services/users";

const EmailHistory = () => {


    const [transactionsApi, { isLoading }] = useLazyGetEmailHistoryQuery();
    const { _id } = useParams();
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    let totalPages = Math.ceil(totalCount / limit);
    const [records, setRecords] = useState<any[]>([]);

    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setRecords([]);
    };


    const fetchApi = async () => {
        try {
            const response = await transactionsApi({ page: page, userId: _id, limit: limit }).unwrap();
            setRecords(response?.data?.rows || [])
            setTotalCount(response?.data?.count);
        } catch (e: any) {
            errorToast(e?.data?.message);
        }
    };







    useEffect(() => {
        if (_id) {
            fetchApi()
        }
    }, [page, limit]);

    return (
        <>
            <Card className="cards">

                <TableContainer className="table_container">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">S.No</TableCell>
                                <TableCell>Email Subject</TableCell>
                                <TableCell>Date & time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={10} align="center">
                                        No History found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                records?.map((item: any, index: number) => (
                                    <TableRow key={item?._id}>
                                        <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>

                                        <TableCell>
                                            {item?.emailSubject || "-"}
                                        </TableCell>

                                        <TableCell>{moment(item?.emailSentDate).format("LLL") || "-"}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card> {records?.length > 0 ? (
                <Pagination
                    limit={limit}
                    setLimit={setLimit}
                    setPage={setPage}
                    module={records}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                />
            ) : (
                ""
            )}
        </>
    );
};

export default EmailHistory;
