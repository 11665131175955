import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  Box,
  Button,
  Card,
  Chip,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import Loader from "../../helpers/constants/Loader";
import RevertQueryModal from "../../components/RevertFeedbackModal";
import FeedbackDetails from "../../components/FeedbackDetails";
import { isValidInput } from "../../utils/validation";
import SearchBar2 from "../../components/SearchBar2";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import { Clear } from "@mui/icons-material";
import { useDeleteEnquiryMutation, useGetAllEnquriesQuery } from "../../services/enquries";
import { getFeedbackCount, getNewListingActionTracker, getNewPropertyListing, getNewPropertyReports, getNewUsers, getRecentNotificationCount, setNotificationCount } from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import WarnModal from "../../components/WarnModal";


const ManageEnqeries = () => {
  const navigate = useNavigate();
  const [rows1, setRows] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useAppDispatch();
  const newUsers = useAppSelector(getNewUsers);
  const newPropertyReports = useAppSelector(getNewPropertyReports);
  const newPropertyListing = useAppSelector(getNewPropertyListing);
  const newListingActionTracker = useAppSelector(getNewListingActionTracker);
  const newNotificationCount = useAppSelector(getRecentNotificationCount);
  const newFeedback = useAppSelector(getFeedbackCount);
  const [deletAPi] = useDeleteEnquiryMutation();
  const [deleteId, setDeleteId] = useState<string>("");
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [msg, setMsg] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [open2, setOpen2] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });


  const handleClose = () => {
    setOpen2(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen2(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleDeleteRequest = async (userId: any) => {
    try {
      const response = await deletAPi({ id: userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Enquiry deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.data?.message || "");
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };


  const { data, isError, isSuccess, isLoading } = useGetAllEnquriesQuery({
    page: page, limit: limit, search: debouncedSearchTerm,
    range: {
      from: range?.from ? moment(range?.from).startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
      to: range?.from === range?.to ? "" : range?.to ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : ""
    }
  })

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.contactUs || []);
      setTotalCount(res?.count);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    dispatch(
      setNotificationCount({
        newNotificationCount: newNotificationCount,
        newUsers: newUsers,
        newPropertyListing: newPropertyListing,
        newPropertyReports: newPropertyReports,
        newListingActionTracker: newListingActionTracker,
        newEnquriesCount: 0,
        newFeedbackCount: newFeedback,
      })
    );
  }, []);


  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Enquiries </h1>
        </div>

        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              /><div className="control_group ">
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range"
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end" style={{ marginLeft: -50 }}>
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Popper
                  open={open2}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  disablePortal
                  style={{
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                      <div
                        style={{
                          padding: 16,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Chip
                            label="Last Week"
                            variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastWeek")}
                          />
                          <Chip
                            label="Last Month"
                            variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastMonth")}
                          />
                          <Chip
                            label="Last Year"
                            variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastYear")}
                          />
                        </div>

                        <DayPicker
                          className="react-day-picker"
                          mode="range"
                          selected={range}
                          onSelect={handleSelect}
                          // numberOfMonths={10}
                          month={month}
                          onMonthChange={handleMonthChange}
                        // styles={{
                        //   months: { display: "flex", gap: "16px" },
                        //   month: { margin: 0 },
                        // }}
                        />
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>

                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Phone no.</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Date & time</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No enquries found
                    </TableCell>
                  </TableRow>
                ) : (
                  rows1?.map((row1: any, i: number) => (
                    <TableRow key={row1?._id || i}>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell>{row1?.name || "-"}</TableCell>
                      <TableCell>{row1?.dialCode + row1?.phoneNo || "-"}</TableCell>
                      <TableCell>{row1?.email || "-"}</TableCell>
                      <TableCell>
                        {row1?.message
                          ? row1.message.length > 35
                            ? row1.message.slice(0, 35) + "..."
                            : row1.message
                          : "-"}
                      </TableCell>
                      <TableCell>{row1?.createdAt ? moment(row1?.createdAt).format("LLL") : "-"}</TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title="View message">
                            <IconButton onClick={() => {
                              setOpen1(true);
                              setMsg(row1?.message)
                            }}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Reply">
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row1?._id);
                              }}
                            >
                              <ReplyIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="History">
                            <IconButton
                              onClick={() =>  navigate(`/enquiry-history/${row1?._id}`, { state: row1?.message }) }
                            >
                              <HistoryToggleOffIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete enquiry">
                            <IconButton
                              onClick={() => {
                                setOpenDelete(true);
                                setDeleteId(row1?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>


                        </Box>
                      </TableCell>
                    </TableRow>

                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows1?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows1}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <RevertQueryModal
        open={open}
        setOpen={setOpen}
        id={selectedId}
        label="Enquries"
      />
      <FeedbackDetails
        open={open1}
        setOpen={setOpen1}
        details={msg}
        msg="User Enquiry Message"
      />
      <WarnModal
        open={openDelete}
        setOpen={setOpenDelete}
        handleDelete={() => handleDeleteRequest(deleteId)}
        name="Enquiry"
      />
    </div>
  );
};

export default ManageEnqeries;
