import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    Switch,
    Button,
    TextField,
    Popper,
    ClickAwayListener,
    Paper,
    Chip,
    InputAdornment,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Pagination from "../../components/Pagination";

import { errorToast, successToast } from "../../helpers/toast";
import Loader from "../../helpers/constants/Loader";

import moment from "moment";
import { DayPicker } from "react-day-picker";
import { Clear } from "@mui/icons-material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useLazyGetSuscriptionDetailsQuery } from "../../services/revenue";

const ManageRevenueDetails = () => {
    const { id } = useParams();
    const [rows, setRows] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    let totalPages = Math.ceil(totalCount / limit);

    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setRows([]);
    };
    const [detailsApi] = useLazyGetSuscriptionDetailsQuery();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    function formatNumberWithCommas(value: string | number) {
        const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
        const [integer, decimal] = valueStr.split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
        return result;
    }

    const fetchDetails = async () => {
        setIsLoading(true)
        try {
            const res = await detailsApi({
                limit: limit,
                page: page,
                id: id
            }).unwrap();
            if (res?.statusCode === 200) {
                setRows(res?.data?.rows)
                setTotalCount(res?.data?.count)
                setIsLoading(false)
            }
        } catch (error: any) {
            errorToast(error?.data?.message)
            setIsLoading(false)
        }
    }




    useEffect(() => {
        fetchDetails()
    }, [])

    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Manage Details</h1>
                </div>
                <Loader isLoad={isLoading} />
                <Card className="cards">

                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell>Transaction Id</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Payment Status</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!rows?.length ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No data found
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    rows?.map((row: any, i: any) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                            <TableCell>{row?.transactionNo || "-"}</TableCell>
                                            <TableCell>{row?.price ? formatNumberWithCommas(row?.price) : "-"}</TableCell>
                                            <TableCell>{row?.paymentStatus || "-"}</TableCell>
                                            <TableCell>{row?.startDate?  moment(row?.startDate).format("DD/MM/YYYY") : "-"}</TableCell>
                                            <TableCell>{row?.endDate? moment(row?.endDate).format("DD/MM/YYYY") : "-"}</TableCell>
                                        </TableRow>
                                    ))
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
            {rows?.length > 0 ? (
                <Pagination
                    setPage={setPage}
                    module={rows}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                    limit={limit}
                    setLimit={setLimit}
                />
            ) : (
                ""
            )}

        </div>
    );
};

export default ManageRevenueDetails;
