import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia, FormHelperText,
  Grid,
  Input, MenuItem, Select,
  TextField,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "react-phone-input-2";
import {
  useAddUserApiMutation,
  useEditUserMutation,
  useLazyGetSpecificUserByIdQuery,
} from "../../services/users";
import { errorToast, successToast, warnToast } from "../../helpers/toast";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, User } from "../../types/General";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import { Autocomplete } from "@react-google-maps/api";
import Loader from "../../helpers/constants/Loader";
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment";

const CompanyProfile = ({ setValue }: any) => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [profile_picture, setProfilePicture] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editUSERbyId] = useEditUserMutation();
  const [addCompany] = useAddUserApiMutation();
  const [ByIdDetails, setByIdDetails] = useState<User>();
  const [fetchApi] = useLazyGetSpecificAgentByIdQuery();
  const [autocomplete, setAutocomplete] = useState<any>();
  const [dialCode, setPhoneCode] = useState("+971");
  const [countryIso, setCountryIso] = useState<string>("AE");
  const [places, setPlaces] = useState<any[]>([]);
  const [location1, setLocation1] = useState<any[]>([]);
  const today = new Date().toISOString().split('T')[0];
  const fetchDataById = async (id: any) => {
    setIsLoading(true);
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setIsLoading(false);
        setPhoneCode(response?.data?.dialCode || "");
        setByIdDetails(response?.data);
        formik.setFieldValue("fullName", response?.data?.companyName || "");
        formik.setFieldValue("firstName", response?.data?.name || "");
        formik.setFieldValue("email", response?.data?.email || "");
        formik.setFieldValue("phoneNo", response?.data?.phoneNo || "");
        formik.setFieldValue("profile_picture", response?.data?.image || "");
        formik.setFieldValue("dialCode", response?.data?.dialCode || "");
        setCountryIso(response?.data?.countryISO);
        formik.setFieldValue("address", response?.data?.address || "");
        formik.setFieldValue("city", response?.data?.city || "");
        formik.setFieldValue("country", response?.data?.country || "");
        formik.setFieldValue("latitude", response?.data?.latitude || 0);
        formik.setFieldValue("longitude", response?.data?.longitude || 0);
        formik.setFieldValue("area", response?.data?.area || 0);
        formik.setFieldValue("buildingName", response?.data?.buildingName || 0);
        formik.setFieldValue("officeNumber", response?.data?.officeNumber || 0);
        formik.setFieldValue("agentEmirate", String(response?.data?.agentEmirate) || "");
        const ORN_object = response?.data?.documents?.length
          ? response?.data?.documents?.find((item: any) => item?.isAdditionalDocument === false)
          : null;
        formik.setFieldValue("orn", ORN_object?.ORN ? String(ORN_object?.ORN) : "");
        formik.setFieldValue("frontImage", ORN_object?.frontImage || 0);
        formik.setFieldValue("name", ORN_object?.name || 0);
        formik.setFieldValue("expiryDate", moment(ORN_object?.expiryDate).format("YYYY-MM-DD") || 0);


      }
    } catch (error) {
      setIsLoading(false);

      console.error(error);
    }
  };

  const [error, setError] = useState(false);
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>, str: string) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file && str === "image") {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        return;
      } else if (!file && str === "pdf") {
        formik.setFieldValue("frontImage", "");
      }

      if (str === "image") {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          const imageUrl = res?.data[0]?.location;
          formik.setFieldValue("profile_picture", imageUrl);
          setProfilePicture(imageUrl);
          successToast("Image uploaded successfully.");
        } else {
          errorToast(res?.message);
        }
      } else if (str === "pdf") {
        const res = await Uploadpdf(file);
        if (res?.statusCode === 200) {
          const imageUrl = res?.data[0]?.location;
          formik.setFieldValue("frontImage", imageUrl);
        } else {
          errorToast(res?.message);
        }
      }

    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };
  const fetchPlaces = async () => {
    try {
      const response = await fetch(
        'https://getpropmicroapi.appgrowthcompany.com/common/places.json',
      );
      const data = await response.json();
      setLocation1(data);
      const matchedCity = Object.keys(data).find(
        (city) => city.toLowerCase() === formik?.values?.country?.toLowerCase(),
      );
      if (matchedCity) {
        const arr = data[matchedCity]?.map(
          (item: { key: any; value: any }) => ({
            label: item?.value,
            value: item?.key || "",
          }),
        );
        setLocation1(arr);
      } else {
        console.log('City not found in data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await fetch(
        'https://getpropmicroapi.appgrowthcompany.com/common/locations.json',
      );
      const data = await response.json();
      const arr = data?.map((item: { key: any; value: any }) => ({
        label: item?.value,
        value: item?.key || "",
      }));
      setPlaces(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNo: "",
      address: "",
      dialCode: dialCode,
      profile_picture: profile_picture,
      latitude: 0,
      longitude: 0,
      orn: "",
      frontImage: "",
      expiryDate: "",
      agentEmirate: "",
      name: "",
      country: "",
      officeNumber: ""

    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Company name is required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          "Enter a valid email address"
        )
        .matches(
          /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/,
          "Emojis are not allowed in the email address"
        ),
      phoneNo: Yup.string().required("Phone number is required"),
      address: Yup.string().required("Address is required"),
      // orn: Yup.string().required("ORN number is required"),
      expiryDate: Yup.string().required("Expiry Date is required"),
      agentEmirate: Yup.string().required("Please Select Company emirate"),
      name: Yup.string().required("Document name is required"),
      country: Yup.string().required("Location is required"),
      officeNumber: Yup.string().required("Office number is required"),

    }),
    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);

      if (formik.values.frontImage === "") {
        warnToast("Please upload document PDF")
        return;
      }

      let body: any = {
        companyName: values.fullName || "",
        email: values.email || "",
        phoneNo: values.phoneNo || "",
        image: values?.profile_picture || "",
        dialCode: dialCode,
        address: values?.address || "",
        agentEmirate: Number(values?.agentEmirate) || "",
        longitude: values?.longitude || "",
        latitude: values?.latitude || "",
        country: values?.country || "",
        countryISO: countryIso,
        officeNumber: values?.officeNumber || "",
        profileSteps: 1,
        documents: [
          {
            ...(formik.values.agentEmirate === "1" && { ORN: Number(values?.orn) }),
            frontImage: values?.frontImage || "",
            expiryDate: values?.expiryDate || "",
            agentEmirate: Number(values?.agentEmirate) || "",
            name: values?.name || ""
          }
        ]

      };
      if (!id) {
        body = { ...body, role: 5 };
      }
      console.log(body, "body");

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      if (id) {
        try {
          setIsLoading(true);

          const response = await editUSERbyId({
            id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Company updated successfully");
            navigate("/manage-company");
          }
          setIsLoading(false);
        } catch (error: any) {
          setIsLoading(false);

          console.error(error);
          errorToast(error?.data?.message);
        } finally {
          setIsLoading(false);

          setSubmitting(false);
        }
      } else {
        try {
          setIsLoading(true);

          const response = await addCompany({ body: encryptedBody }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Company added successfully");
            navigate(`/manage-company/add/${response?.data?._id}/${1}`);
          }
          setIsLoading(false);
        } catch (error: any) {
          console.error(error);
          setIsLoading(false);

          errorToast(error?.data?.message);
        } finally {
          setIsLoading(false);

          setSubmitting(false);
        }
      }
    },
  });


  const handleChangePhone = (phone: any, country: any) => {
    console.log("Country Object:", country?.countryCode);

    formik.setFieldValue("phoneNo", phone?.replace(country?.dialCode, ""));
    setPhoneCode(
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    setCountryIso(country?.countryCode ? country?.countryCode.toUpperCase() : "")
  };

  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, []);



  useEffect(() => {

    fetchLocations();

  }, []);

  useEffect(() => {
    if (formik.values.country) {
      fetchPlaces();
    }
  }, [formik.values.country]);

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />

      <form onSubmit={formik.handleSubmit}>
        <CardContent sx={{ p: 1 }}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography className="custom_label">Image</Typography>
              {formik.values.profile_picture ? (
                <div className="upload_image_preview">
                  <CardMedia
                    component="img"
                    image={formik.values.profile_picture}
                    alt="photo"
                  />
                  <CancelIcon
                    onClick={(e) => {
                      e.preventDefault();
                      setProfilePicture("");
                      formik.setFieldValue("profile_picture", "");
                    }}
                  />
                </div>
              ) : (
                <Box className="upload_image">
                  <label htmlFor="icon-button-file">
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg",
                      }}
                      onChange={(event) => handleImageUpload(event as ChangeEvent<HTMLInputElement>, "image")}
                    />
                    <Button
                      component="span"
                      className="upload_image_btn"
                    >
                      <img
                        src="/static/images/picture.png"
                        alt=""
                      />
                      <CameraAltIcon />
                    </Button>
                  </label>
                </Box>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography className="custom_label">Company Name</Typography>

              <TextField
                hiddenLabel
                type="text"
                className="text_field"
                name="fullName"
                variant="outlined"
                fullWidth
                placeholder="Company Name"
                onChange={(val) => {
                  if (
                    val.target.value === " "

                  ) {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.fullName}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
              />
            </Grid>


            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography className="custom_label">Email</Typography>

              <TextField
                hiddenLabel
                type="text"
                name="email"
                className="text_field"
                variant="outlined"
                fullWidth
                placeholder="Email"
                onChange={(val) => {
                  if (
                    val.target.value === " "

                  ) {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>


            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Phone Number</Typography>
              <PhoneInput
                value={dialCode + formik.values.phoneNo}
                country={"ae"}
                placeholder="0 (000) 000-000"
                enableSearch={true}
                inputStyle={{ width: "100%" }}
                onChange={(phone, country) => handleChangePhone(phone, country)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phoneNo && formik.errors.phoneNo ? (
                <h6 className="err_msg">
                  {formik.touched.phoneNo && formik.errors.phoneNo.toString()}
                </h6>
              ) : (
                ""
              )}
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="control_group w_50">
                <Typography className="custom_label">Address</Typography>
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={() => onPlaceChanged()}
                  options={{
                    types: ['establishment', 'geocode'], // Restrict to cities
                    componentRestrictions: { country: 'ae' },
                  }}
                >
                  <TextField
                    className="text_field"
                    hiddenLabel
                    name="address"
                    value={formik.values?.address}
                    placeholder="Enter Address"
                    fullWidth
                    helperText={
                      formik.touched.address &&
                        typeof formik.errors.address === "string"
                        ? formik.errors.address
                        : undefined
                    }
                    onChange={(e) => {
                      if (
                        e.target.value.trim() === "" ||
                        e.target.value === "."
                      ) {
                        formik.setFieldValue("address", "");
                      } else {
                        formik.handleChange(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Autocomplete>
              </div>
            </Grid>


            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography className="custom_label">Office Number</Typography>

              <TextField
                hiddenLabel
                type="text"
                className="text_field"
                name="officeNumber"
                variant="outlined"
                fullWidth
                placeholder="Office Number"
                onChange={(val) => {
                  if (
                    val.target.value === " "

                  ) {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.officeNumber}
                error={
                  formik.touched.officeNumber && Boolean(formik.errors.officeNumber)
                }
                helperText={formik.touched.officeNumber && formik.errors.officeNumber}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="control_group w_50">
                <Typography className="custom_label">Select city</Typography >
                <Select
                  labelId="category-label"
                  value={formik.values.country || ""}
                  id="category-select"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250, // Adjust height
                        width: 250,     // Adjust width
                      },
                    },
                  }}
                  name="country"
                  fullWidth
                  displayEmpty
                  onChange={(e) => {
                    formik.setFieldValue('city', '');
                    // handleValueChange({ key: 'country', value: e.target.value });
                    formik.setFieldValue("country", e.target.value)
                  }}
                >
                  <MenuItem value={''} disabled>
                    Select
                  </MenuItem>
                  {places?.length
                    ? places.map((item: any, index) =>
                      item?.value && item?.label ? (
                        <MenuItem value={item.value} key={index}>
                          {item.label}
                        </MenuItem>
                      ) : null // Skip items without value or label
                    )
                    : ""}
                </Select>
                {formik.touched.country && formik.errors.country && (
                  <FormHelperText sx={{ color: "red" }}>{String(formik.errors.country)}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Company Emirate number and docs</Typography>
              <Select
                className="select_div"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="agentEmirate"
                fullWidth
                value={formik?.values?.agentEmirate || ""}
                onChange={(e) =>
                  formik.setFieldValue("agentEmirate", e.target?.value)
                }
                onBlur={formik.handleBlur}
                error={formik.touched?.agentEmirate && Boolean(formik.errors?.agentEmirate)}

                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value="1">Dubai</MenuItem>
                <MenuItem value="3">Other</MenuItem>

              </Select>
              {formik.touched.agentEmirate && formik.errors.agentEmirate && (
                <FormHelperText sx={{ color: "red" }}>{String(formik.errors.agentEmirate)}</FormHelperText>
              )}
            </Grid>

          </Grid>
          <div className="single_doc" style={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="heading">
                <Typography className="document_head" variant="h5">
                  Documents
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>

                {formik.values.agentEmirate == "3" ? ("") : (
                  <div className="control_group">
                    <Typography className="custom_label">ORN</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="orn"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter ORN Number"
                      value={formik.values.orn}
                      disabled={id ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.orn && Boolean(formik.errors.orn)}
                      helperText={
                        formik.touched.orn &&
                        formik.errors.orn
                      }
                    />
                  </div>
                )}



                <div className="control_group">
                  <Typography className="custom_label">Expiry Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="expiryDate"
                    className="text_field"
                    variant="outlined"
                    inputProps={{ min: today }}
                    fullWidth
                    placeholder="Enter Expiry Date"
                    value={formik.values.expiryDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.expiryDate && Boolean(formik.errors.expiryDate)}
                    helperText={
                      formik.touched.expiryDate &&
                      formik.errors.expiryDate
                    }
                  />
                </div>
                <div className="control_group">
                  <Typography className="custom_label">Select Document</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="name"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250, // Adjust height
                          width: 250,     // Adjust width
                        },
                      },
                    }}
                    fullWidth
                    value={formik?.values?.name || ""}
                    onChange={(e) =>
                      formik.setFieldValue("name", e.target?.value)
                    }
                    onBlur={formik.handleBlur}
                    error={formik.touched?.name && Boolean(formik.errors?.name)}

                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="Company Id">Company Id</MenuItem>
                    <MenuItem value="Passport">Passport</MenuItem>

                  </Select>
                  {formik.touched.name && formik.errors.name && (
                    <FormHelperText sx={{ color: "red" }}>{String(formik.errors.name)}</FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="control_group">
                  <Typography className="custom_label">Document PDF</Typography>

                  <Box className="docs_div v2">
                    {formik.values.frontImage ? (
                      <>
                        <figure onClick={() => window.open(formik.values.frontImage)}>
                          <img
                            src={formik.values.frontImage ? "/static/images/pdf.png" : "/static/images/upload.png"}
                            alt=""
                          />
                        </figure>
                        {formik.values.frontImage ? (
                          <span onClick={() => formik.setFieldValue('frontImage', "")} className="clear_icon"><ClearIcon /></span>
                        ) : ("")}
                      </>
                    ) : (
                      <figure>
                        <img
                          src={formik.values.frontImage ? "/static/images/pdf.png" : "/static/images/upload.png"}
                          alt=""
                        />
                        <input
                          type="file"
                          id="upload-front-image"
                          style={{ display: "none" }}
                          accept="application/pdf"
                          onChange={(event) => handleImageUpload(event, "pdf")}
                        />

                      </figure>
                    )}

                  </Box>
                </div>
              </Grid>


            </Grid>
          </div>
          <div className="form_btn">
            <Button
              size="large"
              type="submit"
              className="btn btn_primary"
              onClick={() => setError(true)}
            >
              Submit
            </Button>
          </div>
        </CardContent>
      </form>

    </div>
  );
};

export default CompanyProfile;
