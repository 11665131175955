import React, { useEffect, useLayoutEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    Switch,
    Button,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography,
    Tabs,
    Tab,
    Popper,
    ClickAwayListener,
    Paper,
    Chip,
    TextField,
    InputAdornment,
    Tooltip,
} from "@mui/material";
import {

    useLocation,
    useNavigate,

} from "react-router-dom";


import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { errorToast, successToast } from "../../helpers/toast";
import Pagination from "../../components/Pagination";

import Loader from "../../helpers/constants/Loader";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { useAppDispatch } from "../../hooks/store";

import { useLazyDeleteLeadsQuery, useLazyGetAllLeadsQuery } from "../../services/propertyLeads";
import WarnModal from "../../components/WarnModal";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ManageLeads = () => {
    const dispatch = useAppDispatch();


    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [rows, setRows] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount1] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [open, setOpen] = useState<boolean>(false);
    let totalPages = Math.ceil(totalCount / limit);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [selectedId, setSelectedId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataCount, setDataCount] = useState({
        totalEmailLeads: 0,
        totalInAppChatLeads: 0,
        totalPhoneLeads: 0,
        totalWhatsappLeads: 0,
    });
    const [deleteLeadsApi] = useLazyDeleteLeadsQuery();
    const [getLeads] = useLazyGetAllLeadsQuery();
    const [open1, setOpen1] = useState(false); // Control calendar visibility
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedChip, setSelectedChip] = useState("");
    const [month, setMonth] = useState(new Date());
    const [value, setValue] = React.useState(0);
    const [range, setRange] = useState<{
        from: Date | undefined;
        to: Date | undefined;
    }>({
        from: undefined,
        to: undefined,
    });

    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setRows([]);
    };


    const handleDeleteLead = async (userId: any) => {
        setIsLoading(true);
        try {
            const response = await deleteLeadsApi({ id: userId }).unwrap();
            if (response?.statusCode === 200) {
                setIsLoading(false);
                successToast("Lead deleted successfully");
                fetchData()
            }
        } catch (error: any) {
            setIsLoading(false);
            console.error(error);
            errorToast(error?.message || "");
        }
    };


    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await getLeads({
                page: page,
                search: debouncedSearchTerm,
                limit: limit,
                startDate: range?.from
                    ? moment(range?.from).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
                    : "",
                endDate: range?.to === range?.from ? "" :
                    range?.to
                        ? moment(range?.to).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
                        : "",
                leadType: value === 1 ? 1 : value === 3 ? 2 : value === 2 ? 3 : value === 0 ? 4 : 4,
            }).unwrap();
            if (response?.statusCode === 200) {
                setRows(response?.data?.rows);
                setTotalCount1(response?.data?.count);
                setDataCount(
                    {
                        totalEmailLeads: response?.data?.totalEmailLeads || 0,
                        totalInAppChatLeads: response?.data?.totalInAppChatLeads || 0,
                        totalPhoneLeads: response?.data?.totalPhoneLeads || 0,
                        totalWhatsappLeads: response?.data?.totalWhatsappLeads || 0,
                    }
                )
            }
            setIsLoading(false);
        } catch (error: any) {
            errorToast(error?.data?.message)
            setIsLoading(false);
        }
    }




    const handleClose = () => {
        setOpen1(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen1(true);
    };

    const getFormattedRange = () => {
        if (range?.from && range?.to) {
            return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
                range.to
            ).format("MM/DD/YYYY")}`;
        } else if (range?.from) {
            return moment(range.from).format("MM/DD/YYYY");
        }
        return "";
    };

    const handleSelect = (selectedRange: any) => {
        if (selectedRange === undefined) {
            setRange({ from: undefined, to: undefined });
        } else {
            setRange(selectedRange);
        }
    };

    const handleChipClick = (value: any) => {
        if (value === selectedChip) {
            setSelectedChip("");
            setRange({ from: undefined, to: undefined });
            setMonth(moment().toDate());
            return;
        }
        setSelectedChip(value);

        let newRange = { from: moment().toDate(), to: moment().toDate() };
        const today = moment();

        if (value === "lastMonth") {
            newRange = {
                from: moment().subtract(1, "months").startOf("month").toDate(),
                to: moment().subtract(1, "months").endOf("month").toDate(),
            };
        } else if (value === "lastWeek") {
            newRange = {
                from: moment().subtract(7, "days").startOf("day").toDate(),
                to: today.toDate(),
            };
        } else if (value === "lastYear") {
            newRange = {
                from: moment().subtract(1, "years").startOf("year").toDate(),
                to: moment().subtract(1, "years").endOf("year").toDate(),
            };
        }
        setRange(newRange);
        setMonth(newRange.from);
    };

    const handleMonthChange = (newMonth: any) => {
        setMonth(newMonth);
    };



    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setPage(1);
    };

    useLayoutEffect(() => {
        if (state) {

            setValue(state)
        }
    }, [])


    useEffect(() => {
        fetchData()
    }, [page, debouncedSearchTerm, limit, value, range])


    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">
                        Manage Leads
                    </h1>
                </div>
                <Card className="cards">
                    <Box className="cards_header" marginBottom={"20px"}>
                        <Box className="cards_header_left">
                            <SearchBar2
                                value={searchTerm}
                                searchTerm={searchTerm}
                                onCross={() => setSearchTerm("")}
                                setDebouncedSearchTerm={setDebouncedSearchTerm}
                                onChange={(val: any) => {
                                    if (isValidInput(val.target.value)) {
                                        setSearchTerm(val.target.value);
                                    }
                                }}
                            />
                            <div className="control_group">
                                <TextField
                                    // label="Select Date Range"
                                    variant="outlined"
                                    hiddenLabel
                                    className="text_field-range"
                                    fullWidth
                                    onClick={handleClick}
                                    value={getFormattedRange()}
                                    placeholder="Select Date Range"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: range?.from ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        setRange({
                                                            from: undefined,
                                                            to: undefined,
                                                        });
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <Popper
                                open={open1}
                                anchorEl={anchorEl}
                                placement="bottom-start"
                                disablePortal
                                style={{
                                    position: "relative",
                                    zIndex: 1,
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Paper
                                        elevation={3}
                                        style={{ position: "relative", zIndex: 1 }}
                                    >
                                        <div
                                            style={{
                                                padding: 16,
                                                borderRadius: 10,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <Chip
                                                    label="Last Week"
                                                    variant={
                                                        selectedChip === "lastWeek" ? "filled" : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastWeek")}
                                                />
                                                <Chip
                                                    label="Last Month"
                                                    variant={
                                                        selectedChip === "lastMonth" ? "filled" : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastMonth")}
                                                />
                                                <Chip
                                                    label="Last Year"
                                                    variant={
                                                        selectedChip === "lastYear" ? "filled" : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastYear")}
                                                />
                                            </div>

                                            <DayPicker
                                                className="react-day-picker"
                                                mode="range"
                                                selected={range}
                                                onSelect={handleSelect}
                                                // numberOfMonths={10}
                                                month={month}
                                                onMonthChange={handleMonthChange}
                                            // styles={{
                                            //   months: { display: "flex", gap: "16px" },
                                            //   month: { margin: 0 },
                                            // }}
                                            />
                                        </div>
                                    </Paper>
                                </ClickAwayListener>
                            </Popper>
                        </Box>
                        <Box className="cards_header_right">
                            <Button
                                className="btn btn_primary"
                                onClick={() => navigate("/add-leads")}
                            >
                                <AddIcon /> Add Leads
                            </Button>

                        </Box>
                    </Box>
                    <Box className="custom_tabs">

                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="basic tabs example"
                            className="custom_tabs_links"
                        >
                            <Tab
                                label={`Whatsapp (${dataCount?.totalEmailLeads})`}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={`Calls (${dataCount?.totalInAppChatLeads})`}
                                {...a11yProps(1)}
                            />
                            <Tab
                                label={`Email (${dataCount?.totalPhoneLeads})`}
                                {...a11yProps(2)}
                            />
                            <Tab
                                label={`In-app chat (${dataCount?.totalWhatsappLeads})`}
                                {...a11yProps(3)}
                            />
                        </Tabs>

                        <CustomTabPanel value={value} index={0}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table style={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>User Name</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Property Name</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Agent name</TableCell>
                                            <TableCell>Date & time</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!rows?.length ? (
                                            <TableRow>
                                                <TableCell colSpan={11} align="center">
                                                    No Lead found
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows?.map((row: any, i: number) => (
                                                <TableRow key={row?._id}>
                                                    {" "}
                                                    <TableCell align="center">
                                                        {(page - 1) * 10 + i + 1}
                                                    </TableCell>

                                                    <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                                                    </TableCell>
                                                    <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                                                    </TableCell>
                                                    <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")":"-"}</TableCell>
                                                    <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>
                                                    <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                                                    <TableCell>
                                                        <Box className="table_actions">
                                                            <Tooltip title="View property details">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/property-listing/details/${row?.propertyId?._id}`,
                                                                            { state: { from: "lead", tab: value } }
                                                                        )
                                                                    }
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete lead">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedId(row?._id);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {rows?.length > 0 ? (
                                <Pagination
                                    setPage={setPage}
                                    module={rows}
                                    page={page}
                                    onPageChange={onPageChange}
                                    totalPages={totalPages}
                                    limit={limit}
                                    setLimit={setLimit}
                                />
                            ) : (
                                ""
                            )}
                            <WarnModal
                                open={open}
                                setOpen={setOpen}
                                handleDelete={() => handleDeleteLead(selectedId)}
                                name="Lead"
                            />

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table style={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>User Name</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Property Name</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Agent name</TableCell>
                                            <TableCell>Date & time</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!rows?.length ? (
                                            <TableRow>
                                                <TableCell colSpan={11} align="center">
                                                    No Lead found
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows?.map((row: any, i: number) => (
                                                <TableRow key={row?._id}>
                                                    {" "}
                                                    <TableCell align="center">
                                                        {(page - 1) * 10 + i + 1}
                                                    </TableCell>

                                                    <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                                                    </TableCell>
                                                    <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                                                    </TableCell>
                                                    <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")":"-"}</TableCell>
                                                    <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>
                                                    <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                                                    <TableCell>
                                                        <Box className="table_actions">
                                                            <Tooltip title="View property details">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/property-listing/details/${row?.propertyId?._id}`,
                                                                            { state: { from: "lead", tab: value } }
                                                                        )
                                                                    }
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete lead">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedId(row?._id);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {rows?.length > 0 ? (
                                <Pagination
                                    setPage={setPage}
                                    module={rows}
                                    page={page}
                                    onPageChange={onPageChange}
                                    totalPages={totalPages}
                                    limit={limit}
                                    setLimit={setLimit}
                                />
                            ) : (
                                ""
                            )}
                            <WarnModal
                                open={open}
                                setOpen={setOpen}
                                handleDelete={() => handleDeleteLead(selectedId)}
                                name="Lead"
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table style={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>User Name</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Property Name</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Agent name</TableCell>
                                            <TableCell>Date & time</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!rows?.length ? (
                                            <TableRow>
                                                <TableCell colSpan={11} align="center">
                                                    No Lead found
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows?.map((row: any, i: number) => (
                                                <TableRow key={row?._id}>
                                                    {" "}
                                                    <TableCell align="center">
                                                        {(page - 1) * 10 + i + 1}
                                                    </TableCell>

                                                    <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                                                    </TableCell>
                                                    <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                                                    </TableCell>
                                                    <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")":"-"}</TableCell>
                                                    <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>
                                                    <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                                                    <TableCell>
                                                        <Box className="table_actions">
                                                            <Tooltip title="View property details">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/property-listing/details/${row?.propertyId?._id}`,
                                                                            { state: { from: "lead", tab: value } }
                                                                        )
                                                                    }
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete lead">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedId(row?._id);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {rows?.length > 0 ? (
                                <Pagination
                                    setPage={setPage}
                                    module={rows}
                                    page={page}
                                    onPageChange={onPageChange}
                                    totalPages={totalPages}
                                    limit={limit}
                                    setLimit={setLimit}
                                />
                            ) : (
                                ""
                            )}
                            <WarnModal
                                open={open}
                                setOpen={setOpen}
                                handleDelete={() => handleDeleteLead(selectedId)}
                                name="Lead"
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table style={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>User Name</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Property Name</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Agent name</TableCell>
                                            <TableCell>Date & time</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!rows?.length ? (
                                            <TableRow>
                                                <TableCell colSpan={11} align="center">
                                                    No Lead found
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows?.map((row: any, i: number) => (
                                                <TableRow key={row?._id}>
                                                    {" "}
                                                    <TableCell align="center">
                                                        {(page - 1) * 10 + i + 1}
                                                    </TableCell>

                                                    <TableCell>{row?.userId?.name ? row?.userId?.name : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.userId?.userDialCode ? row?.userId?.userDialCode : row?.userId?.dialCode}{row?.userId?.userPhoneNo ? row?.userId?.userPhoneNo : row?.userId?.phoneNo || "-"}
                                                    </TableCell>
                                                    <TableCell>{row?.userId?.userEmail ? row?.userId?.userEmail : row?.userId?.email ? row?.userId?.email : "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.propertyId?.name ? row?.propertyId?.name + " (" + row?.propertyId?.referenceId + ")" : "-"}
                                                    </TableCell>
                                                    <TableCell>{!row?.agentId?.role ? "-" : row?.agentId?.role === 5 ? row?.agentId?.companyName + " (" + row?.agentId?.referenceId + ")" : row?.agentId?.role === 3? row?.companyId?.CompanyName + " (" + row?.companyId?.referenceId + ")":"-"}</TableCell>
                                                    <TableCell>{row?.agentId?.role === 5 || !row?.agentId?.role ? "-" : row?.agentId?.name + " (" + row?.agentId?.referenceId + ")"}</TableCell>
                                                    <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                                                    <TableCell>
                                                        <Box className="table_actions">
                                                            <Tooltip title="View property details">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/property-listing/details/${row?.propertyId?._id}`,
                                                                            { state: { from: "lead", tab: value } }
                                                                        )
                                                                    }
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete lead">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedId(row?._id);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {rows?.length > 0 ? (
                                <Pagination
                                    setPage={setPage}
                                    module={rows}
                                    page={page}
                                    onPageChange={onPageChange}
                                    totalPages={totalPages}
                                    limit={limit}
                                    setLimit={setLimit}
                                />
                            ) : (
                                ""
                            )}
                            <WarnModal
                                open={open}
                                setOpen={setOpen}
                                handleDelete={() => handleDeleteLead(selectedId)}
                                name="Lead"
                            />

                        </CustomTabPanel>
                    </Box>
                </Card>
            </div>

        </div>
    );
};

export default ManageLeads;
