import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";

import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";

import Loader from "../../helpers/constants/Loader";
import {
  useEditpropertyTypeMutation,
  useLazyGetSpecificpropertyTypeByIdQuery,
  usePostAddpropertyTypeMutation,
} from "../../services/propertyType";
import { features } from "process";

const AddPropertyType = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState("");
  const [fetchApi, { data }] = useLazyGetSpecificpropertyTypeByIdQuery();
  const [fetchAddCategory, { isLoading }] = usePostAddpropertyTypeMutation();
  const [updateCategory] = useEditpropertyTypeMutation();
  const [mode, setMode] = useState<string>("");
  const [disable, setDisable] = useState<boolean>(false);

  const handleFields = (event: SelectChangeEvent<string[]>) => {
    if (event?.target?.value) {
      formik.setFieldValue("fields", event.target?.value);

    }
  };

  const optionsArray = [
    {
      id: 1,
      value: "property_available_from",
      label: "Property available from"
    }, {
      id: 2,
      value: "property_available_to",
      label: "Property available to"
    }, {
      id: 3,
      value: "floor_plan",
      label: "Floor plan"
    }, {
      id: 4,
      value: "service_charges",
      label: "Service charges"
    }, {
      id: 5,
      value: "financial_status",
      label: "Financial status"
    }
    , {
      id: 6,
      value: "furnishing_type",
      label: "Furnishing type"
    }, {
      id: 7,
      value: "deliveryDate",
      label: "Delivery Date"
    }
  ]


  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setProfilePicture(response.data.image || "");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      mode: "",
      fields: []
      // premium: "",
      // featured: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      mode: Yup.string()
        .required("Please select Property mode"),


    }),
    onSubmit: async (values) => {
      const body = {
        name: values.title,
        propertyMode: Number(values?.mode),
        optionsArray: values?.mode === "3" ? [] : values.fields,
      };
      console.log(body, "body");

      if (!_id)
        try {
          const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await fetchAddCategory(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast(
              response.data.message || "Property Type Added Successfully"
            );
            navigate("/manage-propertyType/");
          } else {
            errorToast("error");
          }
        } catch (e: any) {
          errorToast(e?.data?.message);
        }
      else {
        try {
          const encryptedBody2 = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateCategory({
            id: _id,
            body: encryptedBody2,
          }).unwrap();
          if (response.statusCode === 200) {
            successToast("Property Type Updated Successfully");
            navigate("/manage-propertyType/");
          } else {
            errorToast(response?.message);
          }
        } catch (e: any) {
          console.log("Error1:", e);
          errorToast(e?.data?.message);
        }
      }
    },
  });


  useEffect(() => {
    if (data && data.statusCode === 200) {
      if (data?.data?.name?.toLocaleLowerCase() === "buy" || data?.data?.name?.toLocaleLowerCase() === "rent") {
        setDisable(true);
      }
      formik.setValues({
        title: data?.data?.name || "",
        mode: String(data?.data?.propertyMode) || "",
        fields: data?.data?.optionsArray || [],

      });
    }
  }, [data]);
  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  return (
    <>
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{_id ? "Edit Property Type" : "Add Property Type"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-propertyType");
              }}
            >
              Back
            </Button>
          </div>
          <Loader isLoad={isLoading} />
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Property Type Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      inputProps={{ maxLength: 40 }}
                      variant="outlined"
                      fullWidth
                      disabled={disable}
                      // disabled={formik.values.title === "Buy" && _id || formik.values.title && _id === "Rent" ? true : false}
                      value={formik.values.title}
                      placeholder="Property Type Name"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " "

                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.title && formik.errors.title
                          ? formik.errors.title.toString()
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Select Property Mode
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        value={formik.values.mode}
                        onChange={(e) => formik.setFieldValue("mode", e.target.value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched?.mode && Boolean(formik.errors?.mode)}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="1">Buy</MenuItem>
                        <MenuItem value="2">Rent</MenuItem>
                        <MenuItem value="3">Under Construction</MenuItem>
                      </Select>
                      {formik.touched.mode && formik.errors.mode && (
                        <FormHelperText sx={{ color: "red" }}>
                          {String(formik.errors.mode)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {formik.values.mode === "3" ? ("") : (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">Select hidden fields</Typography>
                        <Select
                          className="select_div"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="fields"
                          value={formik?.values?.fields || []}
                          onChange={handleFields}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust height
                                width: 250,     // Adjust width
                              },
                            },
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched?.fields &&
                            Boolean(formik.errors?.fields)
                          }
                          displayEmpty
                          multiple
                          renderValue={(selected) =>
                            selected?.length > 0
                              ? selected
                                ?.map((value) => {
                                  const amenity = optionsArray?.find(
                                    (it) => it?.value === value
                                  );
                                  console.log('amenity?.label: ', amenity?.label);
                                  return amenity?.label;
                                })
                                .join(", ")
                              : "Select"
                          }
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {optionsArray?.length > 0
                            ? optionsArray?.map((it) => (
                              <MenuItem key={it?.id} value={it?.value}>
                                {it?.label}
                              </MenuItem>
                            ))
                            : ""}
                        </Select>

                      </FormControl>
                    </Grid>

                  )}



                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddPropertyType;
