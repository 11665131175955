import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
import MainContainer from "../../layout/MainContainer";

import { errorToast } from "../../helpers/toast";
import Loader from "../../helpers/constants/Loader";
import { useLazyGetSpecificReelsByIdQuery } from "../../services/reels";

const AgentReelById = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [getReelsById, { isLoading }] = useLazyGetSpecificReelsByIdQuery();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    if (_id) {
      (async () => {
        try {
          const response = await getReelsById({ userId: _id }).unwrap();
          setByIdDetails(response?.data || []);
        } catch (e: any) {
          errorToast(e?.message);
        }
      })();
    }
  }, [_id, getReelsById]);

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Reels Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-agents")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Reels Video</Typography>
                      <Grid item lg={2} md={2} sm={6} xs={12}>
                        <ReactPlayer
                          url={ByIdDetails?.reel}
                          controls={true}
                          width="300%"
                          height="200%"
                          className="video_box"
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Name</Typography>
                      <Typography component="p">
                        {ByIdDetails?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Reels Posted By</Typography>
                      <Typography component="p">
                        {ByIdDetails?.userId?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            width: 600,
            height: 400,
            maxWidth: "unset",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent sx={{ p: 0, position: "relative", overflow: "hidden" }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "red",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={selectedImage || ""}
              alt="Selected"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AgentReelById;
