import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,

  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";

import { errorToast, successToast } from "../../helpers";

import { isValidInput } from "../../utils/validation";
import SearchBar2 from "../../components/SearchBar2";
import Pagination from "../../components/Pagination";
import {
  useGetSubscriptionListingQuery,
  useLazyGetSubscriptionListingQuery,
}
  from "../../services/revenue";
import ClearIcon from '@mui/icons-material/Clear';

import {
  useLazyExportRevenueCsvSubscribeQuery,
}
  from "../../services/revenue";
import Loader from "../../helpers/constants/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const ManageRevenue = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [rev1, setRev1] = useState<number>(0);
  const [rev2, setRev2] = useState<number>(0);
  const [exportCsv] = useLazyExportRevenueCsvSubscribeQuery();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };
  const navigate = useNavigate()
  const today = new Date().toISOString().split('T')[0];
  const [isLoading, setISLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [startDateSend, setStartDateSend] = useState<string>("");
  const [endDateSend, setEndDateSend] = useState<string>("");
  let totalPages = Math.ceil(totalCount / limit);
  const [subscriptionRows, setSubscriptionRows] = useState<any>([]);
  const [value, setValue] = React.useState(0);
  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };



  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    return result;
  }

  const onPageChange1 = (newPage: number) => {
    setPage(newPage);
    setSubscriptionRows([]);
  };


  const handleDateChange = (event: any) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));

      setStartDateSend(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };

  const handleDateChange1 = (event: any) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 23, 59, 59));

      setEndDateSend(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };





  const { data, isError, isSuccess, isLoading: load } = useGetSubscriptionListingQuery({
    page: page,
    search: debouncedSearchTerm.trim(),
    limit: limit,
    startDate: startDateSend,
    endDate: endDateSend,
    revenueType: value + 1
  })

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setSubscriptionRows(res?.rows)
      setRev1(res?.totalRevenue);
      setRev2(res?.overallRevenue)
      setTotalCount(res?.count);
    }
  }, [isSuccess, data]);

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({
        revenueType: value === 0 ? 1 : value === 1 ? 2 : 3
      }).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };


  function formatRevenue(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }
  console.log('subscriptionRows :', subscriptionRows);


  return (
    <div>
      <Loader isLoad={isLoading || load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Revenue</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header" style={{ marginBottom: 20 }}>
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
            </Box>
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={handleExportCsv}
                disabled={subscriptionRows?.length === 0 || subscriptionRows === undefined}
              >
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <Box className="crds_date" style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="rev_cards">
              <div className="control_group">
                <Typography className="custom_label">Start Date</Typography>
                <TextField
                  // hiddenTypography
                  type="date"
                  name="startDate"
                  variant="outlined"
                  fullWidth
                  placeholder="Start Time"
                  className="text_field"
                  inputProps={{ maxLength: 30, max: today }}
                  sx={{ maxWidth: "260px" }}
                  value={startDate}
                  onChange={handleDateChange}
                  InputProps={{
                    endAdornment: (
                      startDate && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => { setStartDate(""); setStartDateSend("") }}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    ),
                  }}
                />
              </div>
              <div className="control_group">
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  hiddenLabel
                  type="date"
                  name="endDate"
                  variant="outlined"
                  fullWidth
                  placeholder="End Time"
                  className="text_field"
                  sx={{ maxWidth: "260px" }}
                  inputProps={{ maxLength: 30, max: today }}
                  value={endDate}
                  onChange={handleDateChange1}
                  InputProps={{
                    endAdornment: (
                      endDate && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => { setEndDateSend(""); setEndDate("") }}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    ),
                  }}

                />
              </div>
            </div>

            <div className="rev_cards">
              <p>OverAll Revenue: <strong>AED {" " + rev2 && !isNaN(rev2) ? formatRevenue(rev2) : "0"}</strong></p>
              <p>Total Revenue: <strong>AED {" " + rev1 && !isNaN(rev1) ? formatRevenue(rev1) : "0"}</strong></p>
            </div>

          </Box>
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Subscription" {...a11yProps(0)} />
              <Tab label="Boost Plan" {...a11yProps(1)} />
              <Tab label="Topup Token" {...a11yProps(2)} />
              <Tab label="Banner" {...a11yProps(3)} />
              <Tab label="Premium" {...a11yProps(4)} />
              <Tab label="Featured" {...a11yProps(5)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Subscription Type</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Date & time</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!subscriptionRows?.length ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in subscription
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.users?.companyName ? row?.users?.companyName + " (" + row?.users?.referenceId + ")" : "-"}</TableCell>

                          <TableCell>{row?.subscriptions?.subscriptionPack == 1 ? "Monthly" : row?.subscriptions?.subscriptionPack == 3 ? "Quarterly" : row?.subscriptions?.subscriptionPack == 12 ? "Yearly" : "-"}</TableCell>
                          <TableCell>AED {" " + row?.price ? formatNumberWithCommas(String(row?.price)) : "-"}</TableCell>
                          <TableCell>{formatDate(row?.subscriptionStartDate) || "-"}</TableCell>
                          <TableCell>{formatDate(row?.subscriptionEndDate) || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() => navigate(`/manage-revenue/${row?.users?._id}`)}
                              >
                                <VisibilityIcon />
                              </IconButton>

                            </Box>
                          </TableCell>

                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Boost Name</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Date & time</TableCell>
                      <TableCell>Token</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!subscriptionRows?.length ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in boost profile
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.users?.companyName ? row?.users?.companyName + " (" + row?.users?.referenceId + ")" : "-"}</TableCell>

                          <TableCell>{row?.boostProfile?.name || "-"}</TableCell>
                          <TableCell>{formatDate(row?.boostProfileStartDate) || "-"}</TableCell>
                          <TableCell>{formatDate(row?.boostProfileEndDate) || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                          {/* <TableCell>AED {" " + row?.price ? formatNumberWithCommas(String(row?.price)) : "-"}</TableCell> */}
                          <TableCell>{row?.token || "0"}</TableCell>


                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Tokens Purchased</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Transaction Id</TableCell>
                      <TableCell>Date & time</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!subscriptionRows?.length ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in Token
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.users?.companyName ? row?.users?.companyName + " (" + row?.users?.referenceId + ")" : "-"}</TableCell>
                          <TableCell> {" " + row?.token ? formatNumberWithCommas(String(row?.token)) : "-"}</TableCell>
                          <TableCell>AED {" " + row?.price ? formatNumberWithCommas(String(row?.price)) : "-"}</TableCell>
                          <TableCell>{row?.transactionNo || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>

                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Tokens</TableCell>

                      <TableCell>Transaction Id</TableCell>
                      <TableCell>Date & time</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!subscriptionRows?.length ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in banner
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userId?.role === 5 ? row?.userId?.companyName + " (" + row?.userId?.referenceId + ")" :row?.userId?.role===3 ? row?.companyId?.companyName + " (" + row?.companyId?.referenceId + ")":"-" }</TableCell>
                          <TableCell> {" " + row?.token ? formatNumberWithCommas(String(row?.token)) : "-"}</TableCell>
                          <TableCell>{row?.transactionNo || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>

                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Tokens </TableCell>

                      <TableCell>Transaction Id</TableCell>
                      <TableCell>Date & time</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!subscriptionRows?.length ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in premium
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userId?.role === 5 ? row?.userId?.companyName + " (" + row?.userId?.referenceId + ")" :row?.userId?.role===3 ? row?.companyId?.companyName + " (" + row?.companyId?.referenceId + ")":"-" }</TableCell>
                          <TableCell> {" " + row?.token ? formatNumberWithCommas(String(row?.token)) : "-"}</TableCell>
                          <TableCell>{row?.transactionNo || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>

                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Tokens </TableCell>

                      <TableCell>Transaction Id</TableCell>
                      <TableCell>Date & time</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!subscriptionRows?.length ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in featured
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userId?.role === 5 ? row?.userId?.companyName + " (" + row?.userId?.referenceId + ")" :row?.userId?.role===3 ? row?.companyId?.companyName + " (" + row?.companyId?.referenceId + ")":"-" }</TableCell>
                          <TableCell> {" " + row?.token ? formatNumberWithCommas(String(row?.token)) : "-"}</TableCell>
                          <TableCell>{row?.transactionNo || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>

                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>
          </Box>
        </Card>
      </div >
    </div>
  );
};

export default ManageRevenue;
