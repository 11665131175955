import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper, TextField, Chip, ClickAwayListener, Popper, InputAdornment, IconButton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MouseIcon from "@mui/icons-material/Mouse";
import {
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const CompanyInsights = ({
  getOverallInsight,
  graphData,
  showRanks,
  barRankData,
}: {
  getOverallInsight: any;
  graphData: any;
  showRanks?: boolean;
  barRankData?: any;
}) => {

  const [open, setOpen] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  function formatRevenue(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }


  const handleClose = () => {
    setOpen(false);
  };

  const MetricCircle = ({ title, total, data }: any) => {
    const colors = ["#daded9", "#b2b9b2", "#1f201e"];

    return (
      <>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Box className="chart_container">
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx={95}
              cy={95}
              innerRadius={60}
              outerRadius={80}
              paddingAngle={2}
              dataKey="value"
            >
              {data.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
          </PieChart>
          <Box className="chart_total">
            <h3>{formatRevenue(total)}</h3>
            <p>Total</p>
          </Box>
        </Box>
        <div className="legend">
          {data.map((entry: any, index: number) => (
            <p key={`legend-${index}`} className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: colors[index] }}
              ></span>
              {entry.name}
              <span>
                {entry.value?.toString()} ({entry.percentage?.toFixed(2)}%)
              </span>
            </p>
          ))}
        </div>
      </>
    );
  };

  const MetricCircleDummy = ({ title, data }: any) => {
    const radius = 80;
    const circumference = 2 * Math.PI * radius;
    const colors = ["#daded9", "#b2b9b2", "#1f201e"];

    return (
      <>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Box className="chart_container" display="flex" justifyContent="center" alignItems="center">
          <svg width={200} height={200}>
            <circle
              cx="100"
              cy="100"
              r={radius}
              stroke="#D3D3D3"
              strokeWidth={20}
              fill="none"
            />
          </svg>
        </Box>

        <div className="legend">
          {data.map((entry: any, index: number) => (
            <p key={`legend-${index}`} className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: colors[index] }}
              ></span>
              {entry.name}
              <span>
                {entry.value?.toString()} ({entry.percentage?.toFixed(2)}%)
              </span>
            </p>
          ))}
        </div>
      </>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const RankBox = ({ icon, rank, action }: any) => (
    <div className="rank_box">
      {icon}
      <p>Your rank is {rank}</p>
      {/* <Box component="a">{action}</Box> */}
    </div>
  );

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  useEffect(() => {
    getOverallInsight({ startDate: range?.from, endDate: range?.to });
  }, [range]);

  return (
    <>
      <div className="dashboardCompany">

        <div className="page_heading main flex">
          <h3>Overall performance</h3>
          <div className="control_group">
            <TextField
              // label="Select Date Range"
              variant="outlined"
              hiddenLabel
              className="text_field-range w_25"
              fullWidth
              onClick={handleClick}
              value={getFormattedRange()}
              placeholder="Select Date Range"
              InputProps={{
                readOnly: true,
                endAdornment: range?.from ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setRange({
                          from: undefined,
                          to: undefined
                        });
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          disablePortal
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
              <div
                style={{
                  padding: 16,
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around"
                  }}
                >
                  <Chip
                    label="Last Week"
                    variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                    onClick={() => handleChipClick("lastWeek")}
                  />
                  <Chip
                    label="Last Month"
                    variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                    onClick={() => handleChipClick("lastMonth")}
                  />
                  <Chip
                    label="Last Year"
                    variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                    onClick={() => handleChipClick("lastYear")}
                  />
                </div>

                <DayPicker
                  className="react-day-picker"
                  mode="range"
                  selected={range}
                  onSelect={handleSelect}
                  // numberOfMonths={1}
                  month={month}
                  onMonthChange={handleMonthChange}
                // styles={{
                //   months: { display: "flex", gap: "16px" },
                //   month: { margin: 0 },
                // }}
                />
              </div>
            </Paper>
          </ClickAwayListener>
        </Popper>

        <div className="overall_grid">
          <div className="overall_item bx_shdw">
            {graphData?.listingsDataTotal === 0 || !graphData?.listingsDataTotal ? (
              <MetricCircleDummy
                title="Listings"
                data={graphData?.listingsData}

              />
            ) : (
              <MetricCircle
                title="Listings"
                total={graphData?.listingsDataTotal}
                data={graphData?.listingsData}
              />
            )}

            {showRanks && (
              <RankBox
                icon={<PersonIcon />}
                rank={barRankData?.rank?.listing}
                action="Add listings"
              />
            )}
          </div>
          <div className="overall_item bx_shdw">
            {graphData?.impressionsDataTotal === 0 || !graphData?.impressionsDataTotal ? (
              <MetricCircleDummy
                title="Impressions"
                data={graphData?.impressionsData}
              />
            ) : (
              <MetricCircle
                title="Impressions"
                total={graphData?.impressionsDataTotal}
                data={graphData?.impressionsData}
              />
            )}

            {showRanks && (
              <RankBox
                icon={<VisibilityIcon />}
                rank={barRankData?.rank?.impression}
                action="Boost your listings"
              />
            )}
          </div>
          <div className="overall_item bx_shdw">
            {graphData?.clicksDataTotal === 0 || !graphData?.clicksDataTotal ? (
              <MetricCircleDummy
                title="Clicks"
                data={graphData?.clicksData}
              />
            ) : (
              <MetricCircle
                title="Clicks"
                total={graphData?.clicksDataTotal}
                data={graphData?.clicksData}
              />
            )}

            {showRanks && (
              <RankBox
                icon={<MouseIcon />}
                rank={barRankData?.rank?.click}
                action="Raise your quality score"
              />
            )}
          </div>
        </div>
      </div>

      {showRanks && (
        <div className="insights led_insgts_mn">
          {/* First BarChart */}
          <div className="crd_ui w_50">
            <div  style={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={[
                    {
                      userProperties:
                        barRankData?.bars?.userProperties?.toFixed(2),
                      averageProperties:
                        barRankData?.bars?.averageProperties?.toFixed(2),
                    },
                  ]}
                  barGap={5}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={[
                      0,
                      Math.ceil(
                        Math.max(
                          barRankData?.bars?.userProperties?.toFixed(2),
                          barRankData?.bars?.averageProperties?.toFixed(2)
                        ) + 10
                      ),
                    ]}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="averageProperties"
                    fill="#4B2E83"
                    name="Average properties"
                  />
                  <Bar
                    dataKey="userProperties"
                    fill="#B8A0FF"
                    name="User properties"
                  />
                </BarChart>
              </ResponsiveContainer>
              </div>
              <p style={{ textAlign: "center", margin: "0px", padding: "10px 0 0" }}>Properties →</p>
          </div>

          {/* Second BarChart */}
          <div className="crd_ui w_50">
          <div  style={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={[
                    {
                      userScore: barRankData?.bars?.userScore?.toFixed(2),
                      averageScores:
                        barRankData?.bars?.averageScores?.toFixed(2),
                    },
                  ]}
                  barGap={5}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={[
                      0,
                      Math.ceil(
                        Math.max(
                          barRankData?.bars?.userScore?.toFixed(2),
                          barRankData?.bars?.averageScores?.toFixed(2)
                        ) + 10
                      ),
                    ]}
                  />
                  <Tooltip />
                  <Bar dataKey="userScore" fill="#4B2E83" name="User scores" />
                  <Bar
                    dataKey="averageScores"
                    fill="#B8A0FF"
                    name="Average scores"
                  />
                </BarChart>
              </ResponsiveContainer>
              </div>
            <p style={{ textAlign: "center", margin: "0px", padding: "10px 0 0" }}>Scores →</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyInsights;
