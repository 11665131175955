import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import {
    useAddUserApiMutation,
    useEditUserMutation,
} from "../../services/users";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers/toast";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import { CommonBody, Document } from "../../types/General";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../helpers/constants/Loader";

type docs = {
    documentType?: number;
    documentNumber: string;
    expiryDate: string;
    backImage: string;
    frontImage: string;
};

const ShortTermDocs = () => {
    const { id } = useParams();
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ByIdDetails, setByIdDetails] = useState<docs[]>();
    const [editUSERbyId] = useEditUserMutation();
    const [addDocs] = useAddUserApiMutation();
    const [fetchApi] = useLazyGetSpecificAgentByIdQuery();
    const [agentEmirate, setAgentEmirate] = useState<number>(1);
    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        type: string
    ) => {
        const files = event.target.files;
        const file = files?.length ? files[0] : null;
        if (!file) {
            return;
        }
        try {
            setIsLoading(true);
            const res = await Uploadpdf(file);
            setIsLoading(false);
            if (res?.statusCode === 200) {
                const imageUrl = res?.data[0]?.location;
                formik.setFieldValue(type, imageUrl);

                successToast("Documents uploaded successfully.");
            } else {
                errorToast(res?.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error uploading docs:", error);
            errorToast("Error uploading docs. Please try again.");
        }
    };

    const fetchDataById = async (id: any) => {
        setIsLoading(true);
        try {
            const response = await fetchApi({ userId: id }).unwrap();
            if (response.statusCode === 200) {
                setIsLoading(false);
                setByIdDetails(response?.data?.documents || {});
                setAgentEmirate(response?.data?.agentEmirate || "");


            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };
    const getValidationSchema = (agentEmirate: number) => {
        return Yup.object({
            documentNumber_6: Yup.string().required("Emirate ID is required"),
            frontImage_6: Yup.string().required("Front image is required"),
            backImage_6: Yup.string().required("Back image is required"),
            expiryDate_6: Yup.string().required("Expiry Date is required"),

            documentNumber_1: Yup.string().required("License number is required"),
            frontImage_1: Yup.string().required("Trade License image is required"),
            expiryDate_1: Yup.string().required("Expiry Date is required"),

            documentNumber_3: Yup.string().required("DTCM number is required"),
            frontImage_3: Yup.string().required("DTCM document is required"),
            expiryDate_3: Yup.string().required("Expiry Date is required"),




        });
    };

    const formik = useFormik({
        initialValues: {
            documentType_1: "",
            documentNumber_1: "",
            expiryDate_1: "",
            frontImage_1: "",


            documentType_3: "",
            documentNumber_3: "",
            expiryDate_3: "",
            frontImage_3: "",

            documentType_6: "",
            documentNumber_6: "",
            expiryDate_6: "",
            frontImage_6: "",
            backImage_6: "",
        },


        validationSchema: getValidationSchema(agentEmirate),
        onSubmit: async (values, { setSubmitting }) => {

            if (!formik.isValid) return;

            setSubmitting(true);

            let body: any = {
                profileSteps: 2,
                documents: [
                    {
                        documentType: 1,
                        documentNumber: values.documentNumber_1,
                        frontImage: formik.values.frontImage_1,
                        expiryDate: formik.values.expiryDate_1
                    },


                    {
                        documentType: 18,
                        documentNumber: values.documentNumber_3,
                        frontImage: formik.values.frontImage_3,
                        expiryDate: formik.values.expiryDate_3
                    },
                    {
                        documentType: 17,
                        documentNumber: values.documentNumber_6,
                        frontImage: formik.values.frontImage_6,
                        backImage: formik.values.backImage_6,
                        expiryDate: formik.values.expiryDate_6
                    },
                ],
                isDocumentUploaded: true,
            };
            if (!id) {
                body = { ...body, role: 5 };
            }
            console.log(body, "body");

            let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            if (id) {
                try {
                    setIsLoading(true);
                    const response = await editUSERbyId({
                        id,
                        body: encryptedBody,
                    }).unwrap();
                    if (response.statusCode === 200) {
                        setIsLoading(false);
                        successToast("Short term rental updated successfully");
                        state === "company" ? navigate("/manage-company") :
                        navigate("/short-term-rentals");
                    } else {
                        errorToast("Failed to update user");
                    }
                } catch (error: any) {
                    setIsLoading(false);
                    console.error("Error updating user:", error);
                    errorToast(error?.data?.message);
                } finally {
                    setIsLoading(false);
                    setSubmitting(false);
                }
            } else {
                try {
                    setIsLoading(true);
                    const response = await addDocs({
                        body: encryptedBody,
                    }).unwrap();
                    if (response.statusCode === 200) {
                        setIsLoading(false);
                        successToast("Short term rental Documents added successfully");
                        state === "company" ? navigate("/manage-company") :
                        navigate("/short-term-rentals");
                    } else {
                        errorToast("Failed to update company");
                    }
                } catch (error) {
                    setIsLoading(false);
                    console.error("Error updating user:", error);
                    errorToast("Error updating user. Please try again.");
                } finally {
                    setIsLoading(false);
                    setSubmitting(false);
                }
            }
        },
    });

    useEffect(() => {
        if (id) {
            fetchDataById(id);
        }
    }, []);

    useEffect(() => {
        if (ByIdDetails && ByIdDetails.length) {
            ByIdDetails.forEach((item) => {
                const documentType: number = item?.documentType
                    ? item?.documentType
                    : 0;
                if (documentType >= 1 && documentType <= 5) {
                    formik.setFieldValue(
                        `documentNumber_${documentType}`,
                        item?.documentNumber
                    );
                    formik.setFieldValue(`frontImage_${documentType}`, item?.frontImage);
                    formik.setFieldValue(`expiryDate_${documentType}`, item?.expiryDate);
                } else if (documentType === 17) {
                    formik.setFieldValue(`documentNumber_6`, item?.documentNumber);
                    formik.setFieldValue(`frontImage_6`, item?.frontImage);
                    formik.setFieldValue(`backImage_6`, item?.backImage);
                    formik.setFieldValue(`expiryDate_6`, item?.expiryDate);
                } else if (documentType === 18) {
                    formik.setFieldValue(`documentNumber_3`, item?.documentNumber);
                    formik.setFieldValue(`frontImage_3`, item?.frontImage);
                    formik.setFieldValue(`expiryDate_3`, item?.expiryDate);

                }
            });
        }
    }, [ByIdDetails]);

    return (
        <div className="main_layout">
            <Loader isLoad={isLoading} />
            <form onSubmit={formik.handleSubmit}>
                <div className="single_doc">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="heading">
                            <Typography className="document_head" variant="h5">
                                Emirate Id
                            </Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className="control_group">
                                <Typography className="custom_label">Emirate Id</Typography>
                                <TextField
                                    hiddenLabel
                                    type={"text"}
                                    name="documentNumber_6"
                                    className="text_field"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter id"
                                    value={formik.values.documentNumber_6}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.documentNumber_6 &&
                                        Boolean(formik.errors.documentNumber_6)
                                    }
                                    helperText={
                                        formik.touched.documentNumber_6 &&
                                        formik.errors.documentNumber_6
                                    }
                                />
                            </div>
                            <div>
                                <Typography className="custom_label">Expiry Date</Typography>
                                <TextField
                                    hiddenLabel
                                    type={"date"}
                                    name="expiryDate_6"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.expiryDate_6}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{
                                        min: new Date().toISOString().split("T")[0], // Set minimum date to today
                                    }}
                                    error={
                                        formik.touched.expiryDate_6 && Boolean(formik.errors.expiryDate_6)
                                    }
                                    helperText={formik.touched.expiryDate_6 && formik.errors.expiryDate_6}
                                />
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className="control_group">
                                <Typography className="custom_label">Front Side</Typography>
                                <Box className="docs_div v2">
                                    {formik.values.frontImage_6 ? (
                                        <>
                                            <figure
                                                onClick={() => {
                                                    window.open(formik.values.frontImage_6);
                                                }}
                                            >
                                                <img
                                                    src={
                                                        formik.values.frontImage_6
                                                            ? "/static/images/pdf.png"
                                                            : "/static/images/upload.png"
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                            <span
                                                onClick={() => formik.setFieldValue("frontImage_6", "")}
                                                className="clear_icon"
                                            >
                                                <ClearIcon />
                                            </span>
                                        </>
                                    ) : (
                                        <figure>
                                            <img
                                                src={
                                                    formik.values.frontImage_6
                                                        ? "/static/images/pdf.png"
                                                        : "/static/images/upload.png"
                                                }
                                                alt=""
                                            />
                                            <input
                                                type="file"
                                                id="upload-front-image"
                                                style={{ display: "none" }}
                                                accept="application/pdf"
                                                onChange={(event) =>
                                                    handleImageUpload(event, "frontImage_6")
                                                }
                                            />
                                        </figure>
                                    )}
                                </Box>
                                {formik.touched.frontImage_6 && formik.errors.frontImage_6 && (
                                    <Typography className="text_field" style={{ color: "red", fontSize: "12px" }}>
                                        {formik.errors.frontImage_6}
                                    </Typography>
                                )}
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className="control_group">
                                <Typography className="custom_label">Back Side</Typography>
                                <Box className="docs_div v2">
                                    {formik.values.backImage_6 ? (
                                        <>
                                            <figure
                                                onClick={() => {
                                                    window.open(formik.values.backImage_6);
                                                }}
                                            >
                                                <img
                                                    src={
                                                        formik.values.backImage_6
                                                            ? "/static/images/pdf.png"
                                                            : "/static/images/upload.png"
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                            <span
                                                onClick={() => formik.setFieldValue("backImage_6", "")}
                                                className="clear_icon"
                                            >
                                                <ClearIcon />
                                            </span>
                                        </>
                                    ) : (
                                        <figure>
                                            <img
                                                src={
                                                    formik.values.backImage_6
                                                        ? "/static/images/pdf.png"
                                                        : "/static/images/upload.png"
                                                }
                                                alt=""
                                            />
                                            <input
                                                type="file"
                                                id="upload-back-image"
                                                style={{ display: "none" }}
                                                accept="application/pdf"
                                                onChange={(event) =>
                                                    handleImageUpload(event, "backImage_6")
                                                }
                                            />
                                        </figure>
                                    )}
                                </Box>
                                {formik.touched.backImage_6 && formik.errors.backImage_6 && (
                                    <Typography className="text_field" style={{ color: "red", fontSize: "12px" }}>
                                        {formik.errors.backImage_6}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="single_doc">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="heading">
                            <Typography className="document_head" variant="h5">
                                Trade License
                            </Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className="control_group">
                                <Typography className="custom_label">License Number</Typography>
                                <TextField
                                    hiddenLabel
                                    type={"text"}
                                    name="documentNumber_1"
                                    className="text_field"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter License Number"
                                    value={formik.values.documentNumber_1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.documentNumber_1 &&
                                        Boolean(formik.errors.documentNumber_1)
                                    }
                                    helperText={
                                        formik.touched.documentNumber_1 &&
                                        formik.errors.documentNumber_1
                                    }
                                />
                            </div>
                            <div>
                                <Typography className="custom_label">Expiry Date</Typography>
                                <TextField
                                    hiddenLabel
                                    type={"date"}
                                    name="expiryDate_1"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.expiryDate_1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{
                                        min: new Date().toISOString().split("T")[0], // Set minimum date to today
                                    }}
                                    error={
                                        formik.touched.expiryDate_1 && Boolean(formik.errors.expiryDate_1)
                                    }
                                    helperText={formik.touched.expiryDate_1 && formik.errors.expiryDate_1}
                                />
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className="control_group">
                                <Typography className="custom_label">Upload PDF</Typography>
                                <Box className="docs_div v2">
                                    {formik.values.frontImage_1 ? (
                                        <>
                                            <figure
                                                onClick={() => {
                                                    window.open(formik.values.frontImage_1);
                                                }}
                                            >
                                                <img
                                                    src={
                                                        formik.values.frontImage_1
                                                            ? "/static/images/pdf.png"
                                                            : "/static/images/upload.png"
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                            <span
                                                onClick={() => formik.setFieldValue("frontImage_1", "")}
                                                className="clear_icon"
                                            >
                                                <ClearIcon />
                                            </span>
                                        </>
                                    ) : (
                                        <figure>
                                            <img
                                                src={
                                                    formik.values.frontImage_1
                                                        ? "/static/images/pdf.png"
                                                        : "/static/images/upload.png"
                                                }
                                                alt=""
                                            />
                                            <input
                                                type="file"
                                                id="upload-front-image"
                                                style={{ display: "none" }}
                                                accept="application/pdf"
                                                onChange={(event) =>
                                                    handleImageUpload(event, "frontImage_1")
                                                }
                                            />
                                        </figure>
                                    )}
                                </Box>
                                {formik.touched.frontImage_1 && formik.errors.frontImage_1 && (
                                    <Typography className="text_field" style={{ color: "red", fontSize: "12px" }}>
                                        {formik.errors.frontImage_1}
                                    </Typography>
                                )}
                            </div>
                        </Grid>

                        {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">License Back</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.backImage_1 || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-back-image"
                      style={{ display: "none" }}
                      accept="application/pdf"
                      onChange={(event) => handleImageUpload(event, "backImage_1")}
                    />
                    <span
                      onClick={() => formik.setFieldValue("backImage_1", "")}
                      className="clear_icon"
                    >
                      <ClearIcon />
                    </span>
                  </figure>
                </Box>
              </div>
            </Grid> */}
                    </Grid>
                </div>



                <div className="single_doc">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="heading">
                            <Typography className="document_head" variant="h5">
                                DTCM Documents
                            </Typography>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className="control_group">
                                <Typography className="custom_label">
                                    DTCM Number
                                </Typography>
                                <TextField
                                    hiddenLabel
                                    type={"text"}
                                    name="documentNumber_3"
                                    className="text_field"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter License Number"
                                    value={formik.values.documentNumber_3}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.documentNumber_3 &&
                                        Boolean(formik.errors.documentNumber_3)
                                    }
                                    helperText={
                                        formik.touched.documentNumber_3 &&
                                        formik.errors.documentNumber_3
                                    }
                                />
                            </div>
                            <div>
                                <Typography className="custom_label">Expiry Date</Typography>
                                <TextField
                                    hiddenLabel
                                    type={"date"}
                                    name="expiryDate_3"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.expiryDate_3}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{
                                        min: new Date().toISOString().split("T")[0], // Set minimum date to today
                                    }}
                                    error={
                                        formik.touched.expiryDate_3 && Boolean(formik.errors.expiryDate_3)
                                    }
                                    helperText={formik.touched.expiryDate_3 && formik.errors.expiryDate_3}
                                />
                            </div>

                        </Grid>



                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className="control_group">
                                <Typography className="custom_label">Upload PDF</Typography>
                                <Box className="docs_div v2">
                                    {formik.values.frontImage_3 ? (
                                        <>
                                            <figure
                                                onClick={() => {
                                                    window.open(formik.values.frontImage_3);
                                                }}
                                            >
                                                <img
                                                    src={
                                                        formik.values.frontImage_3
                                                            ? "/static/images/pdf.png"
                                                            : "/static/images/upload.png"
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                            <span
                                                onClick={() =>
                                                    formik.setFieldValue("frontImage_3", "")
                                                }
                                                className="clear_icon"
                                            >
                                                <ClearIcon />
                                            </span>
                                        </>
                                    ) : (
                                        <figure>
                                            <img
                                                src={
                                                    formik.values.frontImage_3
                                                        ? "/static/images/pdf.png"
                                                        : "/static/images/upload.png"
                                                }
                                                alt=""
                                            />
                                            <input
                                                type="file"
                                                id="upload-front-image"
                                                style={{ display: "none" }}
                                                accept="application/pdf"
                                                onChange={(event) =>
                                                    handleImageUpload(event, "frontImage_3")
                                                }
                                            />
                                        </figure>
                                    )}
                                </Box>
                                {formik.touched.frontImage_3 && formik.errors.frontImage_3 && (
                                    <Typography className="text_field" style={{ color: "red", fontSize: "12px" }}>
                                        {formik.errors.frontImage_3}
                                    </Typography>
                                )}
                            </div>
                        </Grid>


                    </Grid>
                </div>




                <div className="form_btn">
                    <Button size="large" type="submit" className="btn btn_primary">
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ShortTermDocs;
