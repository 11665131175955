import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import MainContainer from "../../layout/MainContainer";
import { Autocomplete } from "@react-google-maps/api";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { UploadMedia, Uploadpdf, UploadVideo } from "../../utils/uploadMedia";
import { errorToast, successToast, warnToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";

import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CommonBody, PropertyType, PropertyValues } from "../../types/General";
import {
  useEditPropertyMutation,
  useLazyGetSpecificPropertyByIdQuery,
  usePostAddPropertyMutation,
  useLazyGetpropertYTypeListingQuery,
  useLazyGetAmenitiesListingQuery,
} from "../../services/property";

import { useLazyGetAllCategoryQuery } from "../../services/category";
import Loader from "../../helpers/constants/Loader";
import {
  useLazyGetPendingAgentQuery,
  useLazyGetPendingCompanyQuery,
} from "../../services/users";
import { useLazyGetAllpropertyStatusQuery } from "../../services/propertyStatus";
import moment from "moment";
import { isNumberPrice } from "../../utils/validation";
import DragAndDrop from "../../components/DragAndDrop";

const AddProperty = () => {
  const [ByIdDetails, setByIdDetails] = useState<PropertyValues | any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { _id } = useParams();
  const today = new Date().toISOString().split("T")[0];
  const [addProperties] = usePostAddPropertyMutation();
  const [updateProperty] = useEditPropertyMutation();
  const [fetchApi] = useLazyGetSpecificPropertyByIdQuery();
  const [propertyTypeList] = useLazyGetpropertYTypeListingQuery();
  const [amenitiesTypeList] = useLazyGetAmenitiesListingQuery();
  const [fieldsArray, setFieldsArray] = useState<any>([]);
  console.log('fieldsArray: ', fieldsArray);
  const [propertyTypeId, setPropertyType] = useState<
    { _id: string; name: string }[]
  >([]);

  const [categories, setCategories] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [amennity, setAmenity] = useState<{ _id: string; name: string }[]>([
    {
      _id: "",
      name: "",
    },
  ]);
  const [interiorImages, setInteriorImages] = useState<string[]>([]);
  const [exteriorImages, setExteriorImages] = useState<string[]>([]);
  const [gardenImages, setGardenImages] = useState<string[]>([]);
  const imageHandlers = [setInteriorImages, setExteriorImages, setGardenImages];
  const imageMap: { [key in 0 | 1 | 2]: string[] } = {
    0: interiorImages,
    1: exteriorImages,
    2: gardenImages,
  };
  const [value, setValue] = useState<number>(0);
  const images = imageMap[value as 0 | 1 | 2] || interiorImages;
  const setImages = imageHandlers[value];
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [companyListingApi] = useLazyGetPendingCompanyQuery();
  const [companyListing, setCompanyListing] = useState<any>([]);
  const [companyId, setCompanyId] = useState<string>("");
  const [autocomplete, setAutocomplete] = useState<any>();
  const [rows, setRows] = useState<PropertyType[]>([]);
  const [fetchAll] = useLazyGetAllpropertyStatusQuery();
  const [imageError, setImageError] = useState("");
  const [fetchAllCategory] = useLazyGetAllCategoryQuery();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [rentType, setRentType] = useState<string>("")
  const handlePropertyTypeChange = (item: any) => {
    if (item?._id) {
      formik.setFieldValue("propertyTypeId", item._id);
    }
    setFieldsArray(item?.optionsArray || [])
  };

  const handleAmenities = (event: SelectChangeEvent<string[]>) => {
    if (event?.target?.value) {
      formik.setFieldValue("amenitiesId", event.target?.value);
    }
  };

  const handlePropertyCondition = (event: SelectChangeEvent) => {
    formik.setFieldValue("propertyCondition", event.target?.value);
  };

  const handlePdfUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;
    if (file && file.type !== "application/pdf") {
      warnToast("Please upload a PDF file.");
      return;
    }
    try {
      if (!file) {
        formik.setFieldValue("floorPlan", null);
        return;
      }
      let res;
      res = await Uploadpdf(file);
      if (res?.statusCode === 200) {
        formik.setFieldValue("floorPlan", res?.data?.[0]?.location); // Assuming pdfUrl is the URL of the uploaded PDF
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      errorToast("Error uploading file. Please try again.");
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {

    const files = event.target.files;
    const allowedLength = Array.from(files || []).length;
    if (Array.from(files || []).length > 20) {
      errorToast('Max limit is upto 20 images');
      event.target.value = '';
      return;
    }

    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const allowedVideoTypes = ['video/mp4', 'video/avi', 'video/mkv'];
    const invalidFiles = Array.from(files || []).filter(
      (file) =>
        !allowedTypes.includes(file.type) &&
        !allowedVideoTypes.includes(file.type),
    );
    if (invalidFiles.length > 0) {
      errorToast('Inavlid  type');
      event.target.value = '';
      return;
    }

    if (
      interiorImages.length +
      exteriorImages.length +
      gardenImages.length +
      allowedLength >
      20
    ) {
      errorToast('Max limit is upto 20 images');
      event.target.value = '';
      return;
    }

    const videoFiles = Array.from(files || []).filter((file) =>
      allowedVideoTypes.includes(file.type),
    );

    let validVideos = true;

    await Promise.all(
      videoFiles?.map(
        (file) =>
          new Promise<void>((resolve) => {
            const videoElement = document.createElement('video');
            videoElement.preload = 'metadata';

            videoElement.onloadedmetadata = function () {
              window.URL.revokeObjectURL(videoElement.src);
              if (videoElement.duration > 120) {
                validVideos = false;
                errorToast('Video duration exceeds the limit of 2 minutes');
              }
              resolve();
            };

            videoElement.src = URL.createObjectURL(file);
          }),
      ),
    );

    if (!validVideos) {
      event.target.value = '';
      return;
    }

    setLoading(true);
    const resArray = await Promise.all(
      Array.from(files || [])?.map((file) => {
        if (allowedTypes.includes(file.type)) {
          return UploadMedia(file);
        } else if (allowedVideoTypes.includes(file.type)) {
          return UploadVideo(file);
        }
        return null;
      }),
    );
    resArray?.map((res) => {
      if (res?.statusCode === 200) {
        const { location } = res?.data[0];

        if (imageHandlers[value]) {
          imageHandlers[value]((prev) => [...prev, location]);
        }
        setLoading(false);

        event.target.value = '';
        return;

      } else {
        errorToast(res?.message as string);
        setLoading(false);
        event.target.value = '';
      }
    });
  };


  const [searchParams, setSearchParams] = useSearchParams();
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any)?.getPlace();

      if (place && place?.address_components) {
        let address = place?.address_components;

        let state,
          city,
          country,
          zip = "";

        address?.forEach(function (component: any) {
          let types = component?.types;

          if (
            types?.indexOf("locality") > -1 ||
            types?.indexOf("administrative_area_level_3") > -1
          ) {
            city = component?.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component?.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place?.geometry?.location?.lat();
        var lng = place?.geometry?.location?.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({
        userId: id,
        isDraft: Boolean(searchParams?.get("draft")),
      }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetchAllCategory({
        limit: 50,
        page: 1,
        search: "",
        type: "subcategory",
        parentId: "",
      }).unwrap();
      setCategories(response?.data?.category || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchPropertyType = async () => {
    try {
      const response = await propertyTypeList({}).unwrap();
      setPropertyType(response?.data?.propertyType || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchAmenities = async () => {
    try {
      const response = await amenitiesTypeList({}).unwrap();
      setAmenity(response?.data || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const List = async (search: string) => {
    try {
      const response = await fetchAll({ search: search }).unwrap();
      setRows(response?.data?.PropertyCondition || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const handleDateChange = (event: any) => {
    formik.setFieldValue("availableDateFrom", event.target.value);
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(
        Date.UTC(
          selectedDate.getUTCFullYear(),
          selectedDate.getUTCMonth(),
          selectedDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setStartDate(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };

  const handleCrossClick = (e: React.MouseEvent, i: number) => {
    e.stopPropagation();
    let res = [...imageMap[value as 0 | 1 | 2]];
    res?.splice(i, 1);
    imageHandlers[value]([...res]);
  };

  const handleDateChange1 = (event: any) => {
    formik.setFieldValue("availableDateTo", event.target.value);
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(
        Date.UTC(
          selectedDate.getUTCFullYear(),
          selectedDate.getUTCMonth(),
          selectedDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setEndDate(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };

  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    return result;
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      address: "",
      specifications: "",
      beds: "",
      baths: "",
      advertisementPermitNo: "",
      categoryId: [],
      category: "",
      propertyCondition: "",
      amenitiesId: [],
      latitude: "",
      longitude: "",
      propertyTypeId: "",
      area: "",
      companyName: "",
      agentName: "",
      agentId: "",
      companyId: "",
      size: "",
      availableDateTo: "",
      availableDateFrom: "",
      serviceCharges: "",
      finacialStatus: "",
      // buildingName: "",
      floorPlan: "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Property Name is required"),
      price: Yup.string()
        .required("Price is required")
        .max(10, "Price must be less than 10 characters"),
      address: Yup.string().required("Address is required"),
      area: Yup.string().required("Area is required"),
      specifications: Yup.string().required("Description is required"),
      propertyCondition: Yup.string().required(
        "Please select Property Condition"
      ),
      companyName: Yup.string().required("Please select a company"),
      propertyTypeId: Yup.string().required("Please select Property Type"),
      category: Yup.string().required("Category is required"),
      amenitiesId: Yup.array()
        .min(1, "Please select amenity")
        .required("Amenities are required"),

      beds: Yup.number().required("Number of Beds is required"),
      baths: Yup.number().required("Number of Baths is required"),
      advertisementPermitNo: Yup.string()
        .max(20)
        .required("Advertisement Permit No is required"),
      serviceCharges: Yup.string(),
      floorPlan: Yup.string(),
      finacialStatus: Yup.string(),
      availableDateFrom: Yup.string(),
      availableDateTo: Yup.string(),
    }),

    onSubmit: async (values: any) => {
      formik.setSubmitting(true);


      if (interiorImages?.length === 0) {
        warnToast("Please Upload Interior Images")
        return;
      }
      if (exteriorImages?.length === 0) {
        warnToast("Please Upload Exterior Images")
        return;
      }

      if (
        interiorImages?.length +
        exteriorImages?.length +
        gardenImages?.length < 3

      ) {
        warnToast('Please upload minimum 3 pictures/videos');
        return;
      }

      const body: any = {};

      if (values?.name) {
        body.name = values.name;
      }
      if (values?.price) {
        body.price = Number(String(values.price).replace(/,/g, ''));
      }
      if (values?.address) {
        body.address = values.address;
      }
      if (values?.specifications) {
        body.specifications = values.specifications;
      }
    
      if (values?.beds) {
        body.beds = values.beds;
      }
      if (values?.baths) {
        body.baths = values.baths;
      }
      if (values?.advertisementPermitNo) {
        body.advertisementPermitNo = values.advertisementPermitNo;
      }
      if (values?.area) {
        body.area = values.area;
      }
      if (values?.category) {
        body.categoryId = values.category;
      }

      if (interiorImages?.length) {
        body.interior = interiorImages;
      }
      if (exteriorImages?.length) {
        body.exterior = exteriorImages;
      }
      if (gardenImages?.length) {
        body.garden = gardenImages;
      }
      if (values?.propertyCondition) {
        body.propertyConditionId = values.propertyCondition;
      }
      if (values?.amenitiesId) {
        body.amenitiesId = values.amenitiesId;
      }
      if (values?.latitude) {
        body.latitude = values.latitude;
      }
      if (values?.longitude) {
        body.longitude = values.longitude;
      }
      if (values?.propertyTypeId) {
        body.propertyTypeId = values.propertyTypeId;
      }
      if (companyId) {
        body.companyId = companyId;
      }
      if (selectedAgentId) {
        body.agentId = selectedAgentId;
      }
      // if (values?.size) {
      //   body.size = values.size ? Number(values.size) : 0;
      // }
      if (values?.availableDateTo) {
        body.availableDateTo = endDate;
      }
      if (values?.availableDateFrom) {
        body.availableDateFrom = startDate;
      }
      if (values?.serviceCharges) {
        body.serviceCharges = values.serviceCharges;
      }
      if (values?.finacialStatus) {
        body.finacialStatus = Number(values.finacialStatus);
      }
      // if (values?.buildingName) {
      //   body.buildingName = values.buildingName;
      // }
      if (values?.floorPlan) {
        body.floorPlan = values.floorPlan;
      }
      body.images = [
        ...interiorImages,
        ...exteriorImages,
        ...gardenImages,
      ]

      if (searchParams.get("draft")) {
        body["draftId"] = _id;
      }

      console.log(body, "body");

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        if (!_id || Boolean(searchParams.get("draft"))) {
          const response = await addProperties(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Properties Added Successfully.");
            navigate("/property-listing");
          }
        } else {
          const response = await updateProperty({
            id: _id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Property Updated Successfully.");
            navigate("/property-listing");
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const saveDraft = async () => {
    const values = formik?.values;
    const body: any = {};

    if (values?.name) {
      body.name = values.name;
    }
    if (values?.price) {
      body.price = Number(String(values.price).replace(/,/g, ''));
    }
    if (values?.address) {
      body.address = values.address;
    }
    if (values?.specifications) {
      body.specifications = values.specifications;
    }
   
    if (values?.beds) {
      body.beds = values.beds;
    }
    if (values?.baths) {
      body.baths = values.baths;
    }
    if (values?.advertisementPermitNo) {
      body.advertisementPermitNo = values.advertisementPermitNo;
    }
    if (values?.area) {
      body.area = values.area;
    }
    if (values?.category) {
      body.categoryId = values.category;
    }
    if (interiorImages?.length) {
      body.interior = interiorImages;
    }
    if (exteriorImages?.length) {
      body.exterior = exteriorImages;
    }
    if (gardenImages?.length) {
      body.garden = gardenImages;
    }
    if (values?.propertyCondition) {
      body.propertyConditionId = values.propertyCondition;
    }
    if (values?.amenitiesId) {
      body.amenitiesId = values.amenitiesId;
    }
    if (values?.latitude) {
      body.latitude = values.latitude;
    }
    if (values?.longitude) {
      body.longitude = values.longitude;
    }
    if (values?.propertyTypeId) {
      body.propertyTypeId = values.propertyTypeId;
    }
    if (companyId) {
      body.companyId = companyId;
    }
    if (selectedAgentId) {
      body.agentId = selectedAgentId;
    }
    if (values?.availableDateTo) {
      body.availableDateTo = endDate;
    }
    if (values?.availableDateFrom) {
      body.availableDateFrom = startDate;
    }
    if (values?.serviceCharges) {
      body.serviceCharges = values.serviceCharges;
    }
    if (values?.finacialStatus) {
      body.finacialStatus = Number(values.finacialStatus);
    }

    if (values?.floorPlan) {
      body.floorPlan = values.floorPlan;
    }
    body.images = [
      ...interiorImages,
      ...exteriorImages,
      ...gardenImages,
    ]

    // Always set isDraftSaved to true
    body.isDraftSaved = true;

    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

    try {
      if (!_id) {
        const response = await addProperties(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Properties drafted successfully.");
          navigate("/property-listing");
        }
      } else {
        const response = await updateProperty({
          id: _id,
          body: encryptedBody,
        }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Property drafted successfully.");
          navigate("/property-listing");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    } finally {
      formik.setSubmitting(false);
    }
  };
  const [getApprovedCompany] = useLazyGetPendingAgentQuery();
  const [agentsData, setAgentsData] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const fetchApproved = async (role: number, page: number, search: string) => {
    setLoading(true);
    try {
      const response = await getApprovedCompany({
        page: page,
        search: "",
        limit: 200,
        parentId: companyId,
        isAdminAprooved: 2,
        startDate: "",
        endDate: "",
      }).unwrap();
      if (response.statusCode === 200) {
        setLoading(false);
        setAgentsData(response?.data?.user);
        // if (!response?.data?.user?.length)
        //   errorToast("This company dont have any agents");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  console.log(formik.values.propertyTypeId, "formik.values.propertyTypeId ");

  useEffect(() => {
    fetchCategories();
    fetchAmenities();
    fetchPropertyType();
    fetchCompanyListing();
    List("");
  }, []);
  useEffect(() => {
    if (_id) fetchDataById(_id);
  }, [_id]);
  useEffect(() => {
    if (ByIdDetails) {
      // @ts-ignore
      formik.setValues({
        name: ByIdDetails?.name || "",
        price: ByIdDetails?.price ? formatNumberWithCommas(String(ByIdDetails?.price)) : "",
        address: ByIdDetails?.address || "",
        specifications: ByIdDetails?.specifications || "",
       
        propertyCondition: ByIdDetails?.propertyConditionId || "",
        propertyTypeId: ByIdDetails?.propertyTypeId?._id || "",
        categoryId: ByIdDetails?.categoryId?._id || "",
        category: ByIdDetails?.categoryId?._id || "",
        area: ByIdDetails?.area,
        amenitiesId:
          ByIdDetails?.amenities?.map((item: { _id: any }) => item?._id) || [],
        beds: ByIdDetails?.beds || "",
        baths: ByIdDetails?.baths || "",
        advertisementPermitNo: ByIdDetails?.advertisementPermitNo || "",
        latitude: ByIdDetails?.latitude || "",
        longitude: ByIdDetails?.longitude || "",
        companyName: ByIdDetails?.companyId || "",
        agentName: ByIdDetails?.agentId || "",
        size: ByIdDetails?.size || "",
        serviceCharges: ByIdDetails?.serviceCharges || "",
        // buildingName: ByIdDetails?.buildingName || "",
        floorPlan: ByIdDetails?.floorPlan || "",
        availableDateTo:
          moment(ByIdDetails?.availableDateTo).format("YYYY-MM-DD") || "",
        availableDateFrom:
          moment(ByIdDetails?.availableDateFrom).format("YYYY-MM-DD") || "",
        finacialStatus: ByIdDetails?.finacialStatus || "",
      });
      setSelectedAgentId(ByIdDetails?.agentId || "");
      setCompanyId(ByIdDetails?.companyId || "");
      setUploadedImages(ByIdDetails?.images || []);
      setInteriorImages(ByIdDetails?.interior || [])
      setExteriorImages(ByIdDetails?.exterior || [])
      setGardenImages(ByIdDetails?.garden || [])
    }
  }, [ByIdDetails]);

  const fetchCompanyListing = async () => {
    try {
      const res = await companyListingApi({
        page: 1,
        search: "",
        limit: 100,
        range: {
          to: "",
          from: "",
        },
        isAdminAprooved: 2,
      }).unwrap();
      if (res?.statusCode === 200) {
        setCompanyListing(res?.data?.user);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };
  useEffect(() => {
    if (companyId) fetchApproved(3, 1, "");
  }, [companyId]);


  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{_id ? "Edit Property" : "Add Property"}</h1>
          <Button
            className="btn btn_primary"
            onClick={() =>
              navigate(
                "/property-listing" +
                (Boolean(searchParams.get("draft")) ? "?draft=true" : ""),
                { replace: true }
              )
            }
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className="custom_tabs">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="basic tabs example"
                      className="custom_tabs_links"
                    >
                      <Tab label="Interior Images" />
                      <Tab label="Exterior Images" />
                      <Tab label="Garden Images" />
                    </Tabs>
                    <Box className="upload_images_container">
                      <div className="upload_image upload_property">
                        <div className="gap_p">
                          <DragAndDrop
                            myImages={images}
                            setMyImages={setImages}
                            handleCrossClick={handleCrossClick}
                          />

                          {images?.length < 20 ? (
                            <label
                              htmlFor="icon-button-file"
                            >
                              <Input
                                sx={{ display: 'none' }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: 'image/*,video/*',
                                  multiple: true,
                                }}
                                onChange={handleImageUpload}
                              />
                              <span className="upload_image_holder">
                                <AddIcon />
                                Upload Picture/Video
                              </span>
                            </label>
                          ) : null}
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Property Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    className="text_field"
                    variant="outlined"
                    disabled={_id ? true : false}
                    fullWidth
                    placeholder="Enter Property Name"
                    value={formik?.values?.name}
                    onChange={(val) => {
                      if (
                        val.target.value === " "
  
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name &&
                        typeof formik.errors.name === "string"
                        ? formik.errors.name
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Advertisement Permit Number
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="advertisementPermitNo"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    disabled={_id ? true : false}
                    placeholder="Enter Advertisement Permit No "
                    value={formik?.values?.advertisementPermitNo}
                    onChange={(val) => {
                      if (
                        val.target.value === " "
  
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.advertisementPermitNo &&
                      Boolean(formik.errors.advertisementPermitNo)
                    }
                    helperText={
                      formik.touched.advertisementPermitNo &&
                        typeof formik.errors.advertisementPermitNo === "string"
                        ? formik.errors.advertisementPermitNo
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Price"
                    value={formik?.values?.price}
                    onChange={(val) => {
                      let { value } = val.target;
                      const numericValue = value.replace(/,/g, '');
                      if (
                        isNumberPrice(numericValue) &&
                        numericValue.length <= 12
                      ) {
                        const formattedValue =
                          formatNumberWithCommas(numericValue);
                        formik.setFieldValue('price', formattedValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={
                      formik.touched.price &&
                        typeof formik.errors.price === "string"
                        ? formik.errors.price
                        : undefined
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Beds</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="beds"
                    fullWidth
                    value={formik?.values?.beds || ""}
                    onChange={(e) =>
                      formik.setFieldValue("beds", e.target?.value)
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250, // Adjust height
                          width: 250,     // Adjust width
                        },
                      },
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched?.beds && Boolean(formik.errors?.beds)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7+</MenuItem>
                  </Select>
                  {formik.touched.beds && formik.errors.beds && (
                    <FormHelperText sx={{ color: "red" }}>
                      {String(formik.errors.beds)}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bathrooms</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="baths"
                    fullWidth
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250, // Adjust height
                          width: 250,     // Adjust width
                        },
                      },
                    }}
                    value={formik?.values?.baths || ""}
                    onChange={(e) =>
                      formik.setFieldValue("baths", e.target?.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.baths && Boolean(formik.errors?.baths)
                    }
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7+</MenuItem>
                  </Select>
                  {formik.touched.baths && formik.errors.baths && (
                    <FormHelperText sx={{ color: "red" }}>
                      {String(formik.errors.baths)}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      Property Type
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyTypeId"
                      value={formik?.values?.propertyTypeId || ""}
                      // onChange={handlePropertyTypeChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.propertyTypeId &&
                        Boolean(formik.errors?.propertyTypeId)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {propertyTypeId?.length > 0
                        ? propertyTypeId?.map((it: any) => (
                          <MenuItem key={it?._id} value={it?._id} onClick={() => { setRentType(it?.name); handlePropertyTypeChange(it) }} >
                            {it?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>

                    {formik.touched?.propertyTypeId &&
                      typeof formik?.errors?.propertyTypeId === "string" && (
                        <Typography
                          sx={{ fontSize: "12px" }}
                          variant="caption"
                          color="red"
                        >
                          {formik.errors?.propertyTypeId}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
                {rentType === "Rent" ? (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Rent Type
                      </Typography>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="propertyTypeId"
                        value={""}
                        onBlur={formik.handleBlur}
                        displayEmpty
                      >

                        <MenuItem value={""} disabled >
                          Yearly
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : ("")}



                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Amenities</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust height
                            width: 250,     // Adjust width
                          },
                        },
                      }}
                      name="amenitiesId"
                      value={formik?.values?.amenitiesId || []}
                      onChange={handleAmenities}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.amenitiesId &&
                        Boolean(formik.errors?.amenitiesId)
                      }
                      displayEmpty
                      multiple
                      renderValue={(selected) =>
                        selected?.length > 0
                          ? selected
                            ?.map((value) => {
                              const amenity = amennity?.find(
                                (it) => it?._id === value
                              );
                              return amenity?.name;
                            })
                            .join(", ")
                          : "Select"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {amennity?.length > 0
                        ? amennity?.map((it) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>
                    {formik.touched.amenitiesId &&
                      typeof formik.errors.amenitiesId === "string" && (
                        <Typography
                          sx={{ fontSize: "12px" }}
                          variant="caption"
                          color="red"
                        >
                          {formik.errors.amenitiesId}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      Property Status
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyCondition"
                      value={formik?.values?.propertyCondition || ""}
                      onChange={handlePropertyCondition}
                      onBlur={formik.handleBlur}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust height
                            width: 250,     // Adjust width
                          },
                        },
                      }}
                      error={
                        formik.touched.propertyCondition &&
                        Boolean(formik.errors.propertyCondition)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {rows?.length &&
                        rows?.map((data: any) => {
                          return (
                            <MenuItem value={data?._id}>{data?.name}</MenuItem>
                          );
                        })}
                    </Select>
                    {formik.touched.propertyCondition &&
                      typeof formik.errors.propertyCondition === "string" && (
                        <Typography
                          sx={{ fontSize: "12px" }}
                          variant="caption"
                          color="red"
                        >
                          {formik.errors.propertyCondition}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Category</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="category"
                      value={formik?.values?.category || ""}
                      onChange={formik.handleChange}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust height
                            width: 250,     // Adjust width
                          },
                        },
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {categories?.length > 0
                        ? categories?.map((category) => (
                          <MenuItem key={category?._id} value={category?._id}>
                            {category?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>
                    {formik.touched?.category && formik.errors?.category && (
                      <Typography
                        sx={{ fontSize: "12px" }}
                        variant="caption"
                        color="red"
                      >
                        Please select a category
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {fieldsArray?.includes("financial_status") ? (
                  ""
                ) : (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Financial Status
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="finacialStatus"
                      fullWidth
                      value={formik?.values?.finacialStatus || ""}
                      onChange={(e) =>
                        formik.setFieldValue("finacialStatus", e.target?.value)
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.finacialStatus &&
                        Boolean(formik.errors?.finacialStatus)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="1">Cash</MenuItem>
                      <MenuItem value="2">Mortgage</MenuItem>
                    </Select>
                    {formik.touched.finacialStatus &&
                      formik.errors.finacialStatus && (
                        <FormHelperText sx={{ color: "red" }}>
                          {String(formik.errors.finacialStatus)}
                        </FormHelperText>
                      )}
                  </Grid>
                )}

                {fieldsArray?.includes("property_available_from") ? (
                  ""
                ) : (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Available Start Date
                    </Typography>
                    <TextField
                      // hiddenTypography
                      type="date"
                      name="availableDateFrom"
                      variant="outlined"
                      fullWidth
                      placeholder="Start date"
                      className="text_field"
                      inputProps={{ maxLength: 30, max: today }}
                      value={formik.values.availableDateFrom}
                      onChange={handleDateChange}
                      InputProps={{
                        endAdornment: formik.values.availableDateFrom && (
                          <InputAdornment position="end" className="eye_btn">
                            <IconButton
                              onClick={() => {
                                setStartDate("");
                                formik.setFieldValue("availableDateFrom", "");
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {formik.touched.availableDateFrom &&
                      formik.errors.availableDateFrom && (
                        <FormHelperText sx={{ color: "red" }}>
                          {String(formik.errors.availableDateFrom)}
                        </FormHelperText>
                      )}
                  </Grid>
                )}
                {fieldsArray?.includes("property_available_to") ? (
                  ""
                ) : (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Available End Date
                    </Typography>
                    <TextField
                      // hiddenTypography
                      type="date"
                      name="availableDateTo"
                      variant="outlined"
                      fullWidth
                      placeholder="End date"
                      className="text_field"
                      inputProps={{ maxLength: 30, min: today }}
                      value={formik.values.availableDateTo}
                      onChange={handleDateChange1}
                      InputProps={{
                        endAdornment: formik.values.availableDateTo && (
                          <InputAdornment position="end" className="eye_btn">
                            <IconButton
                              onClick={() => {
                                setEndDate("");
                                formik.setFieldValue("availableDateTo", "");
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {formik.touched.availableDateTo &&
                      formik.errors.availableDateTo && (
                        <FormHelperText sx={{ color: "red" }}>
                          {String(formik.errors.availableDateTo)}
                        </FormHelperText>
                      )}
                  </Grid>
                )}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <div className="control_group w_50">
                    <Typography className="custom_label">Address</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                      options={{
                        types: ['establishment', 'geocode'], // Restrict to cities
                        componentRestrictions: { country: 'ae' },
                      }}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        name="address"
                        value={formik?.values?.address || ""}
                        placeholder="Enter Address"
                        fullWidth
                        helperText={
                          formik.touched.address &&
                            typeof formik.errors.address === "string"
                            ? formik.errors.address
                            : undefined
                        }
                        onChange={(e) => {
                          if (
                            e.target?.value.trim() === "" ||
                            e.target?.value === "."
                          ) {
                            formik.setFieldValue("address", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Autocomplete>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Area (Sq.ft)</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="area"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter area"
                    value={formik?.values?.area}
                    onChange={(e) => {
                      const re = /^[0-9]*\.?[0-9]*$/;
                      if (e.target?.value === "" || re.test(e.target?.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.area && Boolean(formik.errors.area)}
                    inputProps={{
                      inputMode: "decimal",
                    }}
                    helperText={
                      Boolean(formik.touched.area) &&
                      formik.errors.area?.toString()
                    }
                  />
                </Grid>

                {fieldsArray?.includes("service_charges") ? ("") : (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Service Charges
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="serviceCharges"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter Service Charges"
                      value={formik?.values?.serviceCharges}
                      onChange={(e) => {
                        const re = /^[0-9]*\.?[0-9]*$/;
                        if (e.target?.value === "" || re.test(e.target?.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.serviceCharges &&
                        Boolean(formik.errors.serviceCharges)
                      }
                      inputProps={{
                        inputMode: "decimal",
                      }}
                      helperText={
                        Boolean(formik.touched.serviceCharges) &&
                        formik.errors.serviceCharges?.toString()
                      }
                    />
                  </Grid>
                )}

                

              
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    Description
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="specifications"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Description"
                    value={formik?.values?.specifications}
                   onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.specifications &&
                      Boolean(formik.errors.specifications)
                    }
                    helperText={
                      formik.touched.specifications &&
                        typeof formik.errors.specifications === "string"
                        ? formik.errors.specifications
                        : undefined
                    }
                  />
                </Grid>
                {fieldsArray?.includes("floor_plan") ? ("") : (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Floor Plan (pdf format)
                    </Typography>
                    <Box className="upload_image upload_img_dpinf">
                      <label htmlFor="icon-button-file-vdo">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file-vdo"
                          type="file"
                          inputProps={{
                            type: "file",
                            accept: "application/pdf",
                          }}
                          disabled={formik.values.floorPlan ? true : false}
                          onChange={handlePdfUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            style={{
                              borderRadius: "5px",
                              width: "300px",
                              height: "200px",
                              border: "solid 2px whitesmoke",
                              objectFit: "contain",
                            }}
                            onClick={() =>
                              formik.values.floorPlan ? window.open(formik.values.floorPlan) : null
                            }
                            src={
                              formik.values.floorPlan
                                ? "/static/images/pdf.png"
                                : "/static/images/upload.png"
                            }
                            alt=""
                          />
                        </Button>
                      </label>
                      <div className="close_btn_abs">
                        {formik.values.floorPlan ? (
                          <IconButton
                            onClick={() => formik.setFieldValue("floorPlan", "")}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </div>
                    </Box>
                    {formik.touched.floorPlan &&
                      typeof formik.errors.floorPlan === "string" && (
                        <Typography
                          sx={{ fontSize: "12px" }}
                          variant="caption"
                          color="red"
                        >
                          {formik.errors.floorPlan}
                        </Typography>
                      )}
                  </Grid>
                )}

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography className="custom_label">Company Name</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="companyName"
                    fullWidth
                    disabled={_id ? true : false}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250, // Adjust height
                          // width: 250,     // Adjust width
                        },
                      },
                    }}
                    value={formik?.values?.companyName || ""}
                    onChange={(e) =>
                      formik.setFieldValue("companyName", e.target?.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.companyName &&
                      Boolean(formik.errors?.companyName)
                    }
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {companyListing?.length &&
                      companyListing?.map((item: any) => {
                        return (
                          <MenuItem
                            onClick={() => setCompanyId(item?._id)}
                            value={item?._id}
                          >
                            {item?.companyName + " (" + item?.referenceId + ")"}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {formik.touched?.companyName &&
                    formik.errors?.companyName && (
                      <Typography
                        sx={{ fontSize: "12px" }}
                        variant="caption"
                        color="red"
                      >
                        Please select a company
                      </Typography>
                    )}
                </Grid>
                {agentsData?.length ? (
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Assign Aggent
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={_id ? true : false}
                      name="agentName"
                      fullWidth
                      value={formik?.values?.agentName || ""}
                      onChange={(e) =>
                        formik.setFieldValue("agentName", e.target?.value)
                      }
                      onBlur={formik.handleBlur}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust height
                            // width: 250,     // Adjust width
                          },
                        },
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {agentsData?.length &&
                        agentsData?.map((item: any) => {
                          return (
                            <MenuItem
                              onClick={() => setSelectedAgentId(item?._id)}
                              value={item?._id}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                ) : (
                  ""
                )}
                {/* <Grid item xs={12}>
                  <Typography className="custom_label">Upload Reels</Typography>
                  <Box className="upload_images_container">
                    {uploadReels?.length ? (
                      <div className="upload_image_preview2">
                        <video controls width="100%" height="100%">
                          <source src={uploadReels} type="video/mp4" />
                        </video>
                        <CancelIcon onClick={() => setUploadReels("")} />
                      </div>
                    ) : null}
                    {uploadReels?.length < 2 && (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file-vdo">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-vdo"
                            type="file"
                            inputProps={{
                              accept: "video/*",
                            }}
                            onChange={handleReelsUploads}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                            {/* <CameraAltIcon /> */}
                {/* </Button>
                        </label>
                        {videoError && (
                          <Typography color="error" variant="caption">
                            {videoError}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid> */}
              </Grid>
              <div className="form_btn">
                <Button type="submit" className="btn btn_primary">
                  Save
                </Button>
                {Boolean(searchParams?.get("draft")) && (
                  <Button className="btn btn_secondary" onClick={saveDraft}>
                    Save as draft
                  </Button>
                )}
                {!_id && (
                  <Button className="btn btn_secondary" onClick={saveDraft}>
                    Save as draft
                  </Button>
                )}
              </div>
            </CardContent>
          </form>
        </Card>

      </div>
    </div>
  );
};

export default AddProperty;
