import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "react-phone-input-2";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import { UploadMedia } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import {
  useAddUserApiMutation,
  useEditUserMutation,
  useLazyGetPendingCompanyQuery,
} from "../../services/users";
import Loader from "../../helpers/constants/Loader";
import { Autocomplete } from "@react-google-maps/api";
import { Autocomplete as MUIAutocomplete } from "@mui/material";
import { isString } from "../../utils/validation";

const TeachingDetails = () => {
  const [dialCode, setPhoneCode] = useState("+971");
  const [error, setError] = useState(false);
  const [profile_picture, setProfilePicture] = useState("");
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const navigate = useNavigate();
  const [editUSERbyId] = useEditUserMutation();
  const [autocomplete, setAutocomplete] = useState<any>();
  const { id } = useParams();
  const [fetchApi, { isLoading }] = useLazyGetSpecificAgentByIdQuery();
  const [addUser] = useAddUserApiMutation();
  const [companyListingApi] = useLazyGetPendingCompanyQuery();
  const [companyListing, setCompanyListing] = useState<any>([]);
  const [companyId, setCompanyId] = useState<string>("");

  const fetchCompanyListing = async () => {
    try {
      const res = await companyListingApi({
        page: 1,
        search: "",
        limit: 100,
        range: { from: "", to: "" },
        isAdminAprooved: 2,
      }).unwrap();
      if (res?.statusCode === 200) {
        setCompanyListing(res?.data?.user);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
        setPhoneCode(response?.data?.dialCode || "");
        setCompanyId(response?.data?.parentAgentId || "")
        console.log('response?.data?.parentAgentId: ', response?.data);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");

        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        formik.setFieldValue("profile_picture", imageUrl);
        setProfilePicture(imageUrl);
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      companyName: "",
      dialCode: dialCode,
      profile_picture: profile_picture,
      latitude: 0,
      longitude: 0,
      parentAgentId: "",
      companyLocation: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          "Enter a valid email address"
        )
        .matches(
          /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/, // Regex to disallow emojis
          "Emojis are not allowed in the email address"
        ),
      phone: Yup.string().required("Phone number is required"),
      address: Yup.string().required("Address is required"),
      companyName: Yup.string().required("Company name is required"),
      companyLocation: Yup.string().required("Company location is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) return;
      let body: any = {
        name: values.name || "",
        email: values.email || "",
        phoneNo: values.phone || "",
        image: values?.profile_picture || "",
        dialCode: dialCode,
        parentAgentId: companyId || "",
        address: values?.address || "",
        companyName: values.companyName || "",
        latitude: values?.latitude || 0,
        longitude: values?.longitude || 0,
        companyLocation: values?.companyLocation || ""
      };
      if (!id) {
        body = { ...body, role: 3 };
      }
      console.log(body, "body");

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (id) {
        try {
          const response = await editUSERbyId({
            id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Agent updated successfully");
            navigate("/manage-agents");
          }
        } catch (error: any) {
          errorToast(error?.data?.message);
        } finally {
          setSubmitting(false);
        }
      } else {
        try {
          const response = await addUser({ body: encryptedBody }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Agent Added successfully");
            navigate("/manage-agents");
          }
        } catch (error: any) {
          errorToast(error?.data?.message);
        } finally {
          setSubmitting(false);
        }
      }
    },
  });
  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);

  useEffect(() => {
    fetchCompanyListing();
  }, []);

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        name: ByIdDetails?.name || "",
        email: ByIdDetails?.email || "",
        phone: ByIdDetails?.phoneNo || "",
        profile_picture: ByIdDetails?.image || "",
        dialCode: ByIdDetails?.dialCode || "",
        companyName: ByIdDetails?.companyName || "",
        address: ByIdDetails?.address || "",
        latitude: ByIdDetails?.latitude || 0,
        longitude: ByIdDetails?.longitude || 0,
        parentAgentId: ByIdDetails?.parentAgentId || "",
        companyLocation: ByIdDetails?.companyLocation || ""
      });
    }
  }, [ByIdDetails]);

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country?.dialCode, ""));
    setPhoneCode(
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
  };

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1 className="mn_hdng">{id ? "Edit Agent" : "Add Agent"}</h1>
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-agents");
          }}
        >
          Back
        </Button>
      </div>
      <Card className="cards">
        <form onSubmit={formik.handleSubmit}>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} className="heading"> */}
              {/* <Typography className="custom_label" variant="h5">
                  Agent Details
                </Typography> */}
              {/* </Grid> */}
              <Grid item xs={12}>
                <Typography className="custom_label">Image</Typography>
                {formik.values.profile_picture ? (
                  <div className="upload_image_preview">
                    <CardMedia
                      component="img"
                      image={formik.values.profile_picture}
                      alt="photo"
                    />
                    <CancelIcon
                      onClick={(e) => {
                        e.preventDefault();
                        setProfilePicture("");
                        formik.setFieldValue("profile_picture", "");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image">
                    <label htmlFor="icon-button-file">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        onChange={handleImageUpload}
                      />
                      <Button component="span" className="upload_image_btn">
                        <img src="/static/images/user_placeholder.png" alt="" />
                        <CameraAltIcon />
                      </Button>
                    </label>
                  </Box>
                )}
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography className="custom_label">Full Name</Typography>

                <TextField
                  hiddenLabel
                  type="text"
                  className="text_field"
                  name="name"
                  variant="outlined"
                  fullWidth
                  placeholder="Full Name"
                  onChange={(val) => {
                    if (
                      val.target.value === " " ||
                      val.target.value === "."
                    ) {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography className="custom_label">Email</Typography>

                <TextField
                  hiddenLabel
                  className="text_field"
                  type="text"
                  name="email"
                  variant="outlined"
                  fullWidth
                  placeholder="Email"
                  onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">Phone Number</Typography>
                <PhoneInput
                  value={dialCode + formik.values.phone}
                  country={"ae"}
                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputStyle={{ width: "100%" }}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <h6 className="err_msg">
                    {formik.touched.phone && formik.errors.phone.toString()}
                  </h6>
                ) : (
                  ""
                )}
              </Grid>



              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography className="custom_label">Company Name</Typography>
                <MUIAutocomplete
                  options={companyListing || []}
                  getOptionLabel={(option: any) => option?.companyName + " (" + option?.referenceId + ")" || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Company"
                      variant="outlined"
                      fullWidth
                      error={
                        formik.touched?.companyName &&
                        Boolean(formik.errors?.companyName)
                      }
                      onBlur={formik.handleBlur}
                      name="companyName"
                    />
                  )}
                  value={
                    companyListing?.find(
                      (item: any) =>
                        item?.companyName === formik.values.companyName
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "companyName",
                      newValue?.companyName || ""
                    );
                    setCompanyId(newValue?._id || "");
                  }}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.companyName === value?.companyName
                  }
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className="control_group w_50">
                  <Typography className="custom_label">Address</Typography>
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={() => onPlaceChanged()}
                    options={{
                      types: ['establishment', 'geocode'], // Restrict to cities
                      componentRestrictions: { country: 'ae' },
                    }}
                  >
                    <TextField
                      className="text_field"
                      hiddenLabel
                      name="address"
                      value={formik.values?.address}
                      placeholder="Enter Address"
                      fullWidth
                      helperText={
                        formik.touched.address &&
                          typeof formik.errors.address === "string"
                          ? formik.errors.address
                          : undefined
                      }
                      onChange={(e) => {
                        if (
                          e.target.value.trim() === "" ||
                          e.target.value === "."
                        ) {
                          formik.setFieldValue("address", "");
                        } else {
                          formik.handleChange(e);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Autocomplete>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography className="custom_label">Company Location</Typography>

                <TextField
                  hiddenLabel
                  type="text"
                  className="text_field"
                  name="companyLocation"
                  variant="outlined"
                  fullWidth
                  placeholder="Full address"
                  onChange={(val) => {
                    if (
                      val.target.value === " "

                    ) {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyLocation}
                  error={formik.touched.companyLocation && Boolean(formik.errors.companyLocation)}
                  helperText={formik.touched.companyLocation && formik.errors.companyLocation}
                />
              </Grid>
            </Grid>
            <div className="form_btn">
              <Button
                size="large"
                type="submit"
                className="btn btn_primary"
                onClick={() => setError(true)}
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default TeachingDetails;
