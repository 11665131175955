import React, { useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import RedoIcon from '@mui/icons-material/Redo';
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
  Popper,
  ClickAwayListener,
  Paper,
  Chip,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, CompanyRequest } from "../../types/General";
import { errorToast, successToast } from "../../helpers/toast";
import Pagination from "../../components/Pagination";

import Loader from "../../helpers/constants/Loader";

import {
  useDeleteRequestByIdMutation,
  useGetAllRequestQuery,
  usePostReasonMutation,
} from "../../services/companyRequest";
import ReasonModal from "../../components/RejectReason";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { Clear } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { useAppDispatch, useAppSelector } from "../../hooks/store";
import {
  getAgentCount,
  getCompanyCount,
  getCompanyRequestCount,
  getPaymentRequestCount,
  getPropertyRequestCount,
  getShortTermRequestCount,
  setRequestCount,
} from "../../reducers/generalSlice";
import { useResendCredsMutation } from "../../services/auth";
import WarnModal from "../../components/WarnModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCompanyRequests = () => {
  const navigate = useNavigate();

  const agentCount = useAppSelector(getAgentCount);
  const companyCount = useAppSelector(getCompanyCount);
  const companyRequestCount = useAppSelector(getCompanyRequestCount);
  const propertyRequestCount = useAppSelector(getPropertyRequestCount);
  const paymentRequestCount = useAppSelector(getPaymentRequestCount)
  const [deleteReqAPi] = useDeleteRequestByIdMutation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(10);
  const [reason, setReason] = useState<string>("");
  const [resendCredsApi] = useResendCredsMutation();
  const [request, setRequest] = useState<CompanyRequest[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [editCompanybyId] = usePostReasonMutation();
  let totalPages = Math.ceil(totalCount / limit);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const shortTermRequestCount = useAppSelector(getShortTermRequestCount);

  const [value, setValue] = React.useState(0);

  const [dataCount, setDataCount] = useState({
    pendingRequests: 0,
    aproovedRequests: 0,
    rejectedRequests: 0,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };

  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("");
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const {
    data,
    isError,
    isLoading: load,
    isSuccess,
  } = useGetAllRequestQuery({
    page: page,
    search: debouncedSearchTerm,
    limit: limit,
    status: Number(value + 1),
    agencyType: 1,
    range: {
      from: range?.from
        ? moment(range?.from).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
        : "",
      to: range?.to === range?.from ? "" :
        range?.to
          ? moment(range?.to).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
    },
  });

  const ResendCreds = async (id: string) => {
    try {
      const res = await resendCredsApi({ id: id, GIT_Request: true }).unwrap();
      if (res?.statusCode === 200) {
        successToast("Credentials sent successfully")
      }
    } catch (error: any) {
      errorToast(error?.data?.message)
    }
  }

  const handleDeleteRequest = async (userId: any) => {
    try {
      const response = await deleteReqAPi({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Request deleted successfully");

      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.data?.message || "");
    }
  };



  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const [receiverStates, setReceiverStates] = useState<{
    [key: string]: string;
  }>({});

  const handleReceiverChange = async (
    event: SelectChangeEvent,
    userId: string
  ) => {
    const newValue = event.target.value;

    // Update the receiver state for the specific row
    setReceiverStates((prevState) => ({
      ...prevState,
      [userId]: newValue,
    }));

    const body = {
      status: Number(newValue),
    };

    if (Number(newValue) === 2) {
      try {
        setIsLoading(true);
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await editCompanybyId({
          body: encryptedBody,
          id: userId,
        }).unwrap();
        if (response?.statusCode === 200) {
          setIsLoading(false);
          setValue(1);
          dispatch(
            setRequestCount({
              agents: agentCount,
              company: companyCount,
              companyRequests: companyRequestCount
                ? companyRequestCount - 1
                : 0,
              propertyRequest: propertyRequestCount,
              paymentRequestCount: paymentRequestCount,
              shortTermRequests: shortTermRequestCount



            })
          );
          successToast("Request Approved successfully");
        }
      } catch (error: any) {
        setIsLoading(false);
        errorToast(error?.data?.message || "");
      }
    } else {
      setReceiverStates((prevState) => ({
        ...prevState,
        [userId]: "3",
      }));
      setOpen(true);
    }
  };

  const handleReject = async () => {
    if (!selectedId || Number(receiverStates[selectedId]) !== 3) {
      return;
    }

    const body = {
      status: 3,
      rejectReason: reason, // Use reason for the selected row
    };

    try {
      setIsLoading(true);

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editCompanybyId({
        body: encryptedBody,
        id: selectedId,
      }).unwrap();

      if (response?.statusCode === 200) {
        setIsLoading(false);
        setValue(2);
        dispatch(
          setRequestCount({
            agents: agentCount,
            company: companyCount,
            companyRequests: companyRequestCount ? companyRequestCount - 1 : 0,
            propertyRequest: propertyRequestCount,
            paymentRequestCount: paymentRequestCount,
            shortTermRequests: shortTermRequestCount


          })
        );
        successToast("Request rejected successfully");

        // Optionally reset the state for the row after rejection
        setReceiverStates((prev) => ({ ...prev, [selectedId]: "1" })); // Reset receiver to Pending or default value
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRequest(res?.adminRequests);
      setTotalCount(data?.data?.count);
      setDataCount({
        pendingRequests: res?.pendingRequests || 0,
        aproovedRequests: res?.aproovedRequests || 0,
        rejectedRequests: res?.rejectedRequests || 0,
      });
    }
  }, [isSuccess, data]);

  return (
    <div>
      <Loader isLoad={isLoading || load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Company Requests</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <Box className="cards_header_left">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <div className="control_group" style={{ width: "250px" }}>
                  <TextField
                    // label="Select Date Range"
                    variant="outlined"
                    hiddenLabel
                    className="text_field-range"
                    fullWidth
                    onClick={handleClick}
                    value={getFormattedRange()}
                    placeholder="Select Date Range"
                    InputProps={{
                      readOnly: true,
                      endAdornment: range?.from ? (
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: -50 }}
                        >
                          <IconButton
                            onClick={() => {
                              setRange({
                                from: undefined,
                                to: undefined,
                              });
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <IconButton>
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Popper
                  open={open1}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  disablePortal
                  style={{
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper
                      elevation={3}
                      style={{ position: "relative", zIndex: 1 }}
                    >
                      <div
                        style={{
                          padding: 8,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: "8px",
                          }}
                        >
                          <Chip
                            label="Last Week"
                            variant={
                              selectedChip === "lastWeek"
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => handleChipClick("lastWeek")}
                          />
                          <Chip
                            label="Last Month"
                            variant={
                              selectedChip === "lastMonth"
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => handleChipClick("lastMonth")}
                          />
                          <Chip
                            label="Last Year"
                            variant={
                              selectedChip === "lastYear"
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => handleChipClick("lastYear")}
                          />
                        </div>

                        <DayPicker
                          className="react-day-picker"
                          mode="range"
                          selected={range}
                          onSelect={handleSelect}
                          // numberOfMonths={10}
                          month={month}
                          onMonthChange={handleMonthChange}
                        // styles={{
                        //   months: { display: "flex", gap: "16px" },
                        //   month: { margin: 0 },
                        // }}
                        />
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Box>
            </Box>
            <Tabs
              style={{ marginTop: "20px" }}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab
                label={`Pending (${dataCount?.pendingRequests})`}
                {...a11yProps(0)}
              />
              <Tab
                label={`Approved (${dataCount?.aproovedRequests})`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Rejected (${dataCount?.rejectedRequests})`}
                {...a11yProps(2)}
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>First name</TableCell>
                      <TableCell>Last name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date & time</TableCell>
                      <TableCell>company</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {request?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No company request found
                        </TableCell>
                      </TableRow>
                    ) : (
                      request?.map((row: any, i: any) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>

                          <TableCell>{row?.firstName || "-"}</TableCell>
                          <TableCell>{row?.lastName || "-"}</TableCell>
                          <TableCell>
                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                          <TableCell>{row?.companyName || "-"} {row?.referenceId ? " (" + row?.referenceId + ")" : ""}</TableCell>


                          <TableCell>

                            <Select
                              fullWidth
                              className="select_div reqst_Select"
                              labelId="demo-simple-select-label"
                              id={`demo-simple-select`}
                              value={receiverStates[row._id] || "1"} // Get the state for the specific row
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              displayEmpty
                              onChange={(e: any) => {
                                setSelectedId(row._id);
                                handleReceiverChange(e, row._id);
                              }}
                            >
                              <MenuItem value="1" disabled>
                                Pending
                              </MenuItem>
                              <MenuItem value="2">Approve</MenuItem>
                              <MenuItem value="3">Reject</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <Tooltip title="View company details">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-company-requests/details/${row?._id}`
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Request">
                                <IconButton
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setDeleteId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {request?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  module={request}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={openDelete}
                setOpen={setOpenDelete}
                handleDelete={() => handleDeleteRequest(deleteId)}
                name="Company Request"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>First name</TableCell>
                      <TableCell>Last name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date & time</TableCell>
                      <TableCell>company</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {request?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No company request found
                        </TableCell>
                      </TableRow>
                    ) : (
                      request?.map((row: any, i: any) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>

                          <TableCell>{row?.firstName || "-"}</TableCell>
                          <TableCell>{row?.lastName || "-"}</TableCell>
                          <TableCell>
                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                          <TableCell>{row?.companyName || "-"} {row?.referenceId ? " (" + row?.referenceId + ")" : ""}</TableCell>

                          <TableCell>Accepted</TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              <Tooltip title="View company details">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-company-requests/details/${row?._id}`
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Send credentials to user again">
                                <IconButton
                                  onClick={() => ResendCreds(row?._id)}
                                >
                                  <RedoIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Request">
                                <IconButton
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setDeleteId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {request?.length ? (
                <Pagination
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  module={request}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={openDelete}
                setOpen={setOpenDelete}
                handleDelete={() => handleDeleteRequest(deleteId)}
                name="Company Request"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>First name</TableCell>
                      <TableCell>Last name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date & time</TableCell>
                      <TableCell>company</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {request?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No company request found
                        </TableCell>
                      </TableRow>
                    ) : (
                      request?.map((row: any, i: any) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>

                          <TableCell>{row?.firstName || "-"}</TableCell>
                          <TableCell>{row?.lastName || "-"}</TableCell>
                          <TableCell>
                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                          <TableCell>{row?.companyName || "-"} {row?.referenceId ? " (" + row?.referenceId + ")" : ""}</TableCell>


                          <TableCell>Rejected</TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <Tooltip title="View company details">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-company-requests/details/${row?._id}`
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Request">
                                <IconButton
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setDeleteId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {request?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  module={request}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={openDelete}
                setOpen={setOpenDelete}
                handleDelete={() => handleDeleteRequest(deleteId)}
                name="Company Request"
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <ReasonModal
        open={open}
        setOpen={setOpen}
        handleDelete={handleReject}
        reason={reason}
        setReason={setReason}
      />
    </div>
  );
};

export default ManageCompanyRequests;
