import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";

import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";

import Loader from "../../helpers/constants/Loader";
import {
  useEditpropertyStatusMutation,
  useLazyGetStatusByIdQuery,
  usePostAddpropertyStatusMutation,
} from "../../services/propertyStatus";

const AddPropertyStatus = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState("");
  const [fetchApi, { data }] = useLazyGetStatusByIdQuery();
  const [fetchAddCategory, { isLoading }] = usePostAddpropertyStatusMutation();
  const [updateCategory] = useEditpropertyStatusMutation();

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setProfilePicture(response.data.image || "");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      // token: ""
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      // token: Yup.string()
      //   .required("This field is required")
      //   .max(10, "Maximum 10 characters are allowed")
    }),
    onSubmit: async (values) => {
      const body = {
        name: values.title,
        // token: values.token
      };

      if (!_id)
        try {
          const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await fetchAddCategory(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast(
              response.data.message || "Property Status Added Successfully"
            );
            navigate("/manage-propertyStatus");
          } else {
            errorToast("error");
          }
        } catch (e: any) {
          errorToast(e?.data?.message);
        }
      else {
        try {
          const encryptedBody2 = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateCategory({
            id: _id,
            body: encryptedBody2,
          }).unwrap();
          if (response.statusCode === 200) {
            successToast("Property Status Updated Successfully");
            navigate("/manage-propertyStatus");
          } else {
            errorToast(response?.message);
          }
        } catch (e: any) {
          console.log("Error1:", e);
          errorToast(e?.message);
        }
      }
    },
  });

  useEffect(() => {
    if (data && data.statusCode === 200) {
      formik.setValues({
        title: data?.data?.name || "",
        // token: data?.data?.settings?.token || ""
      });
    }
  }, [data]);
  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  return (
    <>
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{_id ? "Edit Property Status" : "Add Property Status"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-propertyStatus");
              }}
            >
              Back
            </Button>
          </div>
          <Loader isLoad={isLoading} />
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Property Status Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      inputProps={{ maxLength: 40 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.title}
                      placeholder="Property Status Name"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " "

                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.title && formik.errors.title
                          ? formik.errors.title.toString()
                          : ""
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Typography className="custom_label">
                      Tokens
                    </Typography>
                    <TextField
                      hiddenLabel

                      type={"text"}
                      name="token"
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.token}
                      placeholder="Tokens"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.token && formik.errors.token
                          ? formik.errors.token.toString()
                          : ""
                      }
                    />
                  </Grid> */}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddPropertyStatus;
