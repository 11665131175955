import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton
} from "@mui/material";
import { format } from 'date-fns';
import MainContainer from "../../layout/MainContainer";
import { useLazyGetSpecificAdByIdQuery } from "../../services/ads";
import Loader from "../../helpers/constants/Loader";
import CloseIcon from '@mui/icons-material/Close';
import { ImageModal } from "../../components";

const AdsDetails = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [details, setDetails] = useState<any>();
  const [fetchApi, { isLoading }] = useLazyGetSpecificAdByIdQuery();
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const formatDate = (dateString: string | number | Date) => {
    return dateString ? format(new Date(dateString), 'PPP') : '-';
  };

  const handleLinkClick = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (details?.link) {
      window.open(details.link, '_blank');
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setDetails(response?.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Banner Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-ads");
            }}
          >
            Back
          </Button>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Images</Typography>
                      <Grid container spacing={1}>
                        {details?.images?.map((image: string, index: number) => (
                          <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                            <figure className="profile_img">
                              <img
                                style={{ borderRadius: 0, width: '100%', cursor: 'pointer' }}
                                src={image}
                                alt={`Property Image ${index + 1}`}
                              />
                              <div className="overlay">
                                <img
                                  onClick={() => handleOpenModal(image)}
                                  src='/static/images/visibility.png' alt='img'
                                />
                              </div>
                            </figure>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}/>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box>
                      <Typography component="h5">Title</Typography>
                      <Typography component="p">{details?.title || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Company Name</Typography>
                      <Typography component="p">{details?.userId?.companyName || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Start Date</Typography>
                      <Typography component="p">{formatDate(details?.startDate) || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">End Date</Typography>
                      <Typography component="p">{formatDate(details?.endDate) || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Link</Typography>
                      {details?.url ? (
                        <Typography
                          component="a"
                          href={details.url}
                          onClick={handleLinkClick}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {details.url}
                        </Typography>
                      ) : (
                        <Typography component="p" sx={{ cursor: 'default' }}>
                          -
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Address</Typography>
                      <Typography component="p">{details?.address || "-"}</Typography>
                    </Box>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />
    </div>
  );
};

export default AdsDetails;
