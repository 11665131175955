import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { usePostAddBannerMutation, useLazyGetSpecificBannerByIdQuery, useEditBannerMutation } from "../../services/banners";
import { UploadMedia } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Autocomplete } from "@react-google-maps/api";

const AddBanners = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [profilePicture, setProfilePicture] = useState("");
  const [addBanner] = usePostAddBannerMutation();
  const [updateBanner] = useEditBannerMutation();
  const [getBannerById] = useLazyGetSpecificBannerByIdQuery();
  const [imageError, setImageError] = useState("");
  const [autocomplete, setAutocomplete] = useState<any>();
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      address: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      address: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      if (!profilePicture) {
        errorToast("Kindly upload an image");
        return;
      }

      const body = {
        title: values.title,
        address: values.address,
        description: values.description,
        image: profilePicture,
      };

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

        if (_id) {
          const response = await updateBanner({ id: _id, body: encryptedBody }).unwrap();
          if (response.statusCode === 200) {
            successToast("Banner Updated Successfully");
            navigate("/manage-banners/");
          } else {
            errorToast(response.message);
          }
        } else {
          const response = await addBanner(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast("Banner Added Successfully");
            navigate("/manage-banners/");
          } else {
            errorToast(response.message);
          }
        }
      } catch (e: any) {
        errorToast(e?.data?.message);
      }
    },
  });

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    if (!file) {
      setProfilePicture("");
      setImageError("Image is required");
      return;
    }

    if (!file.type.includes("image")) {
      errorToast("Only images are allowed");
      return;
    }

    const image = new Image();
    const objectUrl = URL.createObjectURL(file);

    image.onload = async () => {
      URL.revokeObjectURL(objectUrl);

      const width = image.width;
      const height = image.height;

      if ((width < 498 || height < 540)) {
        errorToast("Banner image minimum dimensions must be 498×540");
        return;
      }

      if ((width < 2340 || height < 618)) {
        errorToast("Banner image minimum dimensions must be 2340×618");
        return;
      }

      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          const imageUrl = res?.data[0]?.location;
          setProfilePicture(imageUrl);
          setImageError("");
          successToast("Image uploaded successfully.");
        } else {
          errorToast(res?.message);
        }
      } catch (error) {
        errorToast("Error uploading image. Please try again.");
      }
    };

    image.onerror = () => {
      errorToast("Invalid image file");
    };

    image.src = objectUrl;
  };



  useEffect(() => {
    if (_id) {
      (async () => {
        try {
          const response = await getBannerById({ userId: _id }).unwrap();
          const data = response?.data;

          if (data) {
            formik.setValues({
              title: data?.title,
              address: data?.address,
              description: data?.description,
            });
            setProfilePicture(data?.image);
          }
        } catch (e: any) {
          errorToast(e?.message);
        }
      })();
    }
  }, [_id, getBannerById]);

  return (
    <div>

      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{_id ? `Edit Banners` : `Add Banners`}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-banners")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {profilePicture ? (
                    <div className="upload_image_preview">
                      <CardMedia
                        component="img"
                        style={{ borderRadius: 0 }}
                        image={profilePicture}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={(e) => {
                          e.preventDefault();
                          setProfilePicture("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={handleImageUpload}
                        />
                        <Button
                          component="span"
                          className="upload_image_btn"
                        >
                          <img
                            style={{ borderRadius: 0 }}
                            src="/static/images/product1.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                      {imageError && (
                        <Typography color="error" variant="caption">
                          {imageError}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Property Name</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="title"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Property Name"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={
                      formik.touched.title && formik.errors.title
                        ? formik.errors.title.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <div className="control_group w_50">
                    <Typography className="custom_label">Address</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                      options={{
                        types: ['establishment', 'geocode'], // Restrict to cities
                        componentRestrictions: { country: 'ae' },
                      }}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        name="address"
                        value={formik.values?.address}
                        placeholder="Enter Address"
                        fullWidth
                        helperText={
                          formik.touched.address &&
                            typeof formik.errors.address === "string"
                            ? formik.errors.address
                            : undefined
                        }
                        onChange={(e) => {
                          if (
                            e.target.value.trim() === "" ||
                            e.target.value === "."
                          ) {
                            formik.setFieldValue("address", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Autocomplete>
                  </div>
                </Grid>


                {/* <Grid item lg={4} md={4} sm={6} xs={12}>

                </Grid> */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="custom_label">
                    Description
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="description"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description.toString()
                        : ""
                    }
                  />
                </Grid>


              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default AddBanners;
