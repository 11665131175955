import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, Typography } from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyGetSpecificChatByIdQuery } from "../../services/chat";
import { errorToast } from "../../helpers";
import Loader from "../../helpers/constants/Loader";
import { MEDIA_TYPES } from "../../utils/enums";
import moment from "moment";
import { useLazyHistoryOfEnqQuery } from "../../services/enquries";


const EnqueriesChatDetails = () => {

  const { id } = useParams();
  const [rows, setRows] = useState<any[]>([]);
  const [fetchApi, { isLoading }] = useLazyHistoryOfEnqQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const {state}=location;
  console.log('state: ', state);

  const getById = async (id: any) => {
    try {
      const response = await fetchApi({ id: id }).unwrap();
      setRows(response?.data);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  useEffect(() => {
    getById(id);
  }, [id]);

  const shouldShowTimestamp = (currentIndex: number) => {
    if (currentIndex === 0) return true;
    const currentTime = new Date(rows[currentIndex].createdAt);
    const previousTime = new Date(rows[currentIndex - 1].createdAt);
    const timeDifference = (currentTime.getTime() - previousTime.getTime()) / (1000 * 60 * 60); // difference in hours
    return timeDifference > 6;
  };




  const distinctSenderTypes = Array.from(new Set(rows.map(chat => chat.senderType)));
  const incomingType = distinctSenderTypes.includes(3) ? 3 : distinctSenderTypes.find(type => type !== 4);
  const outgoingType = distinctSenderTypes.find(type => type !== incomingType);

  const LoadImage = ({ data }: any) => {
    return (
      <div key={data._id} className={'msg_body'}>
        <figure style={{ width: '100%' }}>
          <img src={data?.message} width={'100%'} height={'170px'} alt="" />
        </figure>
        <p
          style={{
            fontWeight: '400',
            fontSize: 'x-small',
          }}
        >
          {moment(data?.createdAt).format('LT')}
        </p>
      </div>
    );
  };


  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">History Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-enqueries");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards chat_card">
          <CardContent sx={{ p: 1 }}>
            <div className="main">
              <div className="heading" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="userDetail" style={{ display: 'flex', alignItems: 'center' }}>
               
                    <p style={{ fontWeight: "600", fontSize: "18px" }}>Enquiry:{state}</p>
                 

                </div>

              </div>
              <div className="chat-div">
                <div className="chats_lst">
                  {rows?.length ? (
                    rows?.map((item) => {
                      return (
                        <div style={{ textAlign: "right", margin: "2px" }}>
                          <p style={{ margin: "2px" }}>{item?.adminReply}</p>
                          <span style={{ fontSize: "12px", color: "grey" }}>{item?.createdAt ? moment(item?.createdAt).format("LLL") : ""} </span>
                        </div>
                      )
                    })
                  ) : ("No Revert message found")}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EnqueriesChatDetails;






