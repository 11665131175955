import { readConfigFile } from "typescript";


import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, FormHelperText, Grid, Input, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEditBoostProfileMutation, useLazyGetSpecificBoostProfileByIdQuery, usePostAddboostProfileMutation } from "../../services/boostProfile";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers/toast";
import { isNumber } from "../../utils/validation";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";

const AddBoost = () => {
  const { id } = useParams();
  const [displayEditText, setDisplayEditText] = useState(true);
  const navigate = useNavigate();
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const [addBoost, { isLoading }] = usePostAddboostProfileMutation();
  const [updateBoost] = useEditBoostProfileMutation();

  const [fetchById] = useLazyGetSpecificBoostProfileByIdQuery();


  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>, str: string) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file && str === "image") {
        formik.setFieldValue("profile_picture", "");

        return;
      } else if (!file && str === "pdf") {
        formik.setFieldValue("frontImage", "");
      }

      if (str === "image") {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          const imageUrl = res?.data[0]?.location;
          formik.setFieldValue("image", imageUrl);

          successToast("Image uploaded successfully.");
        } else {
          errorToast(res?.message);
        }
      } else if (str === "pdf") {
        const res = await Uploadpdf(file);
        if (res?.statusCode === 200) {
          const imageUrl = res?.data[0]?.location;
          formik.setFieldValue("frontImage", imageUrl);
        } else {
          errorToast(res?.message);
        }
      }

    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      token: "",
      validity: "",
      image: "",
      boostType: ""
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .max(200, "Maximum 200 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      validity: Yup.string()
        .required("This field is required")
        .max(4, "Maximum 4 characters are allowed")
        .min(1, "Minimum 1 characters are required"),
      token: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed")
        .min(1, "Minimum 1 character are required"),
      boostType: Yup.string()
        .required("This field is required")

    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        title: formik?.values?.title,
        token: formik?.values?.token,
        description: formik?.values?.description,
        validity: String(formik?.values?.validity),
        boostType: Number(formik?.values?.boostType),
        image: formik.values.image
      };
      console.log(body,"body");
      
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      try {
        if (!id) {
          const response = await addBoost(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Boost Plan Added Successfully.");
            navigate("/manage-boost");
          }
        } else {
          const response = await updateBoost({
            id: id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Boost Plan Updated Successfully.");
            navigate("/manage-boost");
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message);
      } finally {
        formik.setSubmitting(false);
      }

    },
  });
  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchById({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        title: ByIdDetails?.title || "",
        description: ByIdDetails?.description || "",
        token: ByIdDetails?.token || "",
        validity: String(ByIdDetails?.validity) || "",
        image: ByIdDetails?.image || "",
        boostType: String(ByIdDetails?.boostType) || ""
      });
    }
  }, [ByIdDetails]);

  return (
    <>
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? `Edit Boost Profile` : `Add Boost Profile`}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-boost");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {formik.values.image ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={formik.values.image}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={(e) => {
                            e.preventDefault();

                            formik.setFieldValue("image", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(event) => handleImageUpload(event as ChangeEvent<HTMLInputElement>, "image")}
                          />
                          <Button
                            component="span"
                            className="upload_image_btn"
                          >
                            <img
                              src="/static/images/picture.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>


                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      // inputProps={{ maxLength: 40 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.title}
                      placeholder="Enter Title"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " "

                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Token</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      className="text_field"
                      name="token"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter token"
                      value={formik.values.token}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.token && Boolean(formik.errors.token)}
                      helperText={formik.touched.token && formik.errors.token}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Validity</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="validity"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter Validity"
                      value={formik.values.validity}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.validity && Boolean(formik.errors.validity)}
                      helperText={formik.touched.validity && formik.errors.validity}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Select Boost Type</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="agentEmirate"
                      fullWidth
                      value={formik?.values?.boostType || ""}
                      onChange={(e) =>
                        formik.setFieldValue("boostType", e.target?.value)
                      }
                      onBlur={formik.handleBlur}
                      error={formik.touched?.boostType && Boolean(formik.errors?.boostType)}

                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="3">Apex Boost</MenuItem>
                      <MenuItem value="2">Elevate Boost	</MenuItem>
                      <MenuItem value="1">Spotlight Boost	</MenuItem>

                    </Select>
                    {formik.touched.boostType && formik.errors.boostType && (
                      <FormHelperText sx={{ color: "red" }}>{String(formik.errors.boostType)}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Description</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="description"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                      placeholder="Enter Description"
                      value={formik.values.description}
                      onChange={(val) => {
                        if (
                          val.target.value === " "

                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Grid>

                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddBoost;
