import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetSpecificBoostProfileByIdQuery } from "../../services/boostProfile";
import { errorToast } from "../../helpers";

const BoostDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<any>();
  const [detailsById] = useLazyGetSpecificBoostProfileByIdQuery();
  const fetchApi = async () => {
    try {
      const respose = await detailsById({ userId: _id }).unwrap();
      if (respose?.statusCode === 200) {
        setData(respose?.data);
      }
    }
    catch (e: any) {
      errorToast(e?.message);
    }
  }

  useEffect(() => {
    fetchApi()
  }, [_id]);
  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Boost Profile Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-boost");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Image</Typography>
                      <Grid item lg={2} md={2} sm={6} xs={12}>
                        <figure className="profile_img">
                          <img
                            style={{ borderRadius: 0, cursor: 'pointer', width: '100%' }}
                            src={data?.image || `/static/images/product1.png`}
                            alt="Banner"
                          // onClick={() => handleOpenModal(data?.image)}
                          />
                          <div className="overlay">
                            <img
                              // onClick={() => handleOpenModal(data?.image)}
                              src='/static/images/visibility.png' alt='img'
                            />
                          </div>
                        </figure>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Boost Name
                      </Typography>
                      <Typography component="p">{data?.title || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Token
                      </Typography>
                      <Typography component="p"> {data?.token ? data?.token + " Token" : "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Validity
                      </Typography>
                      <Typography component="p">{data?.validity || "-"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Description
                      </Typography>
                      <Typography component="p">{data?.description || "-"}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default BoostDetails;
