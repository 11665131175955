import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParmas = {
  id?: string;
  categoryId?: string;
  typeId?: string;
  statusId?: string;
  startDate?: any;
  endDate?: string;
  graphType?: number;
  lat?: number;
  lng?: number;
  creditsType?: string;
  propertyConditionId?: string;
  publishGraph?: string;
  filterType?: string;
  leadType?: string;
};
export const insightsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getListingPerformances: builder.query<
      CommonResponseType & { data: any },
      QueryParmas
    >({
      query: ({
        graphType,
        creditsType,
        startDate,
        endDate,
        lat,
        lng,
        typeId,
        categoryId,
        propertyConditionId,
        publishGraph,
        id,
      }) => {
        // Create an instance of URLSearchParams
        const params = new URLSearchParams();
        const user = id ? `/user/${id}/` : "";
        // Add parameters conditionally
        if (publishGraph)
          params.append("publishedListingsType", publishGraph.toString());
        if (graphType) params.append("graphType", graphType.toString());
        if (lat) params.append("lat", lat.toString());
        if (lng) params.append("lng", lng.toString());
        if (propertyConditionId)
          params.append("propertyConditionId", propertyConditionId.toString());
        if (creditsType) params.append("creditsType", creditsType);
        if (startDate) params.append("startDate", startDate);
        if (categoryId) params.append("categoryId", categoryId);
        if (typeId) params.append("propertyTypeId", typeId);
        if (endDate) params.append("endDate", endDate);

        return {
          url: `${user}${END_POINTS.leadsInsightPerformance}?${params.toString()}`,
          method: "GET",
        };
      },
    }),

    superAgentInsightsOverview: builder.query<
      CommonResponseType & { data: any },
      { categoryId: string; latitude: number; longitude: number, userId: any }
    >({
      query: ({ categoryId, latitude, longitude, userId }) => {
        return {
          url: `${END_POINTS.performanceInsightsOverview}?categoryId=${categoryId}&latitude=${latitude}&longitude=${longitude}&userId=${userId}`,
          method: "GET",
        };
      },
    }),


  }),
});

export const {
  useLazyGetListingPerformancesQuery,
  useLazySuperAgentInsightsOverviewQuery,
} = insightsApi;
