import { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { errorToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import { useLazyGetAllTransactionsQuery } from "../../services/agents";
import moment from "moment";
import { PropertyType } from "../../types/General";
import { useLazyGetAllpropertyTypeQuery } from "../../services/propertyType";

const AgentTransactions = () => {

  const [data, setData] = useState<any>();
  const [transactionsApi, { isLoading }] = useLazyGetAllTransactionsQuery();
  const { _id } = useParams();

  const [page, setPage] = useState(1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [records, setRecords] = useState<any[]>([]);
  const [propertyType, setPropertyType] = useState<string>("");
  const [rows, setRows] = useState<PropertyType[]>([]);
  const [fetchAll] = useLazyGetAllpropertyTypeQuery();
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    // setData([]);
  };
  const fetchApi = async () => {
    try {
      const response = await transactionsApi({ page: page, id: _id, limit: limit }).unwrap();
      // setData(response?.data?.propertyType?.[0] || {});
      setRecords(response?.data?.records || [])
      setTotalCount(response?.data?.total);
    } catch (e: any) {
      errorToast(e?.data?.message);
    }
  };

  const List = async () => {
    try {
      const response = await fetchAll({ page: 1, search: "", limit: 50 }).unwrap();
      setRows(response?.data?.propertyType || []);
    }
    catch (e: any) {
      errorToast(e?.message);
    }
  }

  const convertToInternationalCurrencySystem = (labelValue: number) => {
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  };

  const numbers = [
    {
      label: "Closed Deals",
      value: totalCount ? totalCount : 0,
    },


  ];

  useEffect(() => {
    if (_id) {
      fetchApi()

    }
  }, [propertyType]);

  useEffect(() => {
    if (_id) {
      List()
    }
  }, []);
  return (
    <>
      <Card className="cards">
        <Box className="cards_header">
          <div className="lstng_insgts">
            <div className="insight-card">
              <div className="insight-count">
                {numbers?.map((item, index) => (
                  <div
                    className='card-number'
                    key={index}
                    style={{
                      cursor: "pointer",
                      border: "",
                    }}
                  >
                    <h4>{item?.value}</h4>
                    <p>{item?.label}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="cards_header_right">
            <Select
              className="childFilter"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={propertyType}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250, // Adjust height
                    width: 250,     // Adjust width
                  },
                },
              }}
              onChange={(e) => setPropertyType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Property Type
              </MenuItem>
              {rows?.length > 0
                ? rows?.map((it: any) => (
                  <MenuItem key={it?._id} value={it?._id}>
                    {it?.name}
                  </MenuItem>
                ))
                : ""}
            </Select>
            {propertyType !== "" ? (
              <Button
                className="btn btn_primary"
                style={{ backgroundColor: "black" }}
                onClick={() => {
                  setPropertyType("");
                }}
              >
                Clear filter
              </Button>
            ) : ("")}

          </div>


        </Box>

        <TableContainer className="table_container">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Property Name</TableCell>
                <TableCell>Deal Type</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Bedrooms</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No transactions found
                  </TableCell>
                </TableRow>
              ) : (
                records?.map((item: any, index: number) => (
                  <TableRow key={item?._id}>
                    <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                    <TableCell>
                      <figure className="user_img">
                        <img src={item?.images?.[0] || "/static/images/town.png"} alt="" />
                      </figure>
                    </TableCell>
                    <TableCell>
                      {item?.properties?.name || "-"}
                    </TableCell>
                    <TableCell>
                      {item?.propertyTypeName || "-"}
                    </TableCell>
                    <TableCell>{convertToInternationalCurrencySystem(item?.price) || "-"}</TableCell>
                    <TableCell>{item?.properties?.beds || "-"}</TableCell>
                    <TableCell>{item?.properties?.categoryName || "-"}</TableCell>
                    <TableCell>{item?.properties?.address || "-"}</TableCell>
                    <TableCell>{moment(item?.createdAt).format("DD-MM-YYYY") || "-"}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card> {records?.length > 0 ? (
        <Pagination
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          module={records}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AgentTransactions;
