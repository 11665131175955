import React, { useEffect, useLayoutEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
  Popper,
  ClickAwayListener,
  Paper,
  Chip,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";

import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, CompanyRequest } from "../../types/General";
import { errorToast, successToast } from "../../helpers/toast";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/constants/Loader";

import ReasonModal from "../../components/RejectReason";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import {
  useEditPropertyMutation,
  useLazyGetPropertyRequestForClaimedPropertyQuery,
  useLazyGetPropertyRequestForUnderOfferQuery,
} from "../../services/property";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getAgentCount,
  getCompanyCount,
  getCompanyRequestCount,
  getPaymentRequestCount,
  getPropertyRequestCount,
  getShortTermRequestCount,
  setRequestCount,
} from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManagePropertyRequests = () => {
  const dispatch = useAppDispatch();

  const agentCount = useAppSelector(getAgentCount);
  const companyCount = useAppSelector(getCompanyCount);
  const companyRequestCount = useAppSelector(getCompanyRequestCount);
  const propertyRequestCount = useAppSelector(getPropertyRequestCount);
  const paymentRequestCount = useAppSelector(getPaymentRequestCount);
  const shortTermRequestCount = useAppSelector(getShortTermRequestCount);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [propertyType, setPropertyType] = useState<number>(2);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [reason, setReason] = useState<string>("");
  const { state } = useLocation();
  let totalPages = Math.ceil(totalCount / limit);
  const [request, setRequest] = useState<CompanyRequest[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [editCompanybyId] = useEditPropertyMutation();
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const [claimType, setClaimType] = useState<string>("");
  const [getAllReqUnderOffer] = useLazyGetPropertyRequestForUnderOfferQuery();
  const [getAllReqClaimed] = useLazyGetPropertyRequestForClaimedPropertyQuery();
  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("");
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const [dataCount, setDataCount] = useState({
    pendingRequests: 0,
    aproovedRequests: 0,
    rejectedRequests: 0,
    dropUnder: 0,
    dropClaim: 0
  });

  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };


  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    return result;
  }

  const fetchData = async () => {
    if (propertyType === 1) {
      setIsLoading(true);
      try {
        const response = await getAllReqUnderOffer({
          page: page,
          search: debouncedSearchTerm,
          limit: limit,
          underOfferStatus: value === 0 ? 1 : value === 1 ? 2 : 3,
          startDate: range?.from ? moment(range?.from).startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
          endDate: range?.from === range?.to ? "" : range?.to ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
        }).unwrap();
        if (response.statusCode === 200) {
          setIsLoading(false);
          setRequest(response?.data?.property);
          setTotalCount(response?.data?.count);
          setDataCount({
            pendingRequests: response?.data?.pendingRequests || 0,
            aproovedRequests: response?.data?.aproovedRequests || 0,
            rejectedRequests: response?.data?.rejectedRequests || 0,
            dropClaim: response?.data?.pendingClaimRequests || 0,
            dropUnder: response?.data?.pendingUnderOfferRequests || 0,
          });
        }
      } catch (error) {
        setIsLoading(false);

        console.error(error);
      }
    } else {
      setIsLoading(true);
      try {
        const response = await getAllReqClaimed({
          page: page,
          search: debouncedSearchTerm,
          limit: limit,
          isClaimDocStatus: value === 0 ? 1 : value === 1 ? 2 : 3,
          startDate: range?.from ? String(range?.from) : "",
          endDate: range?.to ? String(range?.to) : "",
          isPublish: claimType == "1" ? true : false,
        }).unwrap();
        if (response.statusCode === 200) {
          setIsLoading(false);
          setRequest(response?.data?.property);
          setTotalCount(response?.data?.count);
          setDataCount({
            pendingRequests: response?.data?.pendingRequests || 0,
            aproovedRequests: response?.data?.aproovedRequests || 0,
            rejectedRequests: response?.data?.rejectedRequests || 0,
            dropClaim: response?.data?.pendingClaimRequests || 0,
            dropUnder: response?.data?.pendingUnderOfferRequests || 0,
          });
        }
      } catch (error) {
        setIsLoading(false);

        console.error(error);
      }
    }
  };

  const [receiver, setReceiver] = useState<string>("1");

  const handleReceiverChange = async (
    event: SelectChangeEvent,
    userId: any
  ) => {
    let body;
    if (propertyType === 1) {
      body = {
        underOfferStatus: Number(event.target.value),
      };
    } else {
      body = {
        isClaimDocStatus: Number(event.target.value),
      };
    }
    console.log(body, "body");

    if (Number(event.target.value) === 2) {
      try {
        setIsLoading(true);

        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await editCompanybyId({
          body: encryptedBody,
          id: userId,
        }).unwrap();
        if (response?.statusCode === 200) {
          setIsLoading(false);
          setValue(1);

          dispatch(
            setRequestCount({
              agents: agentCount,
              company: companyCount,
              companyRequests: companyRequestCount,
              propertyRequest: propertyRequestCount
                ? propertyRequestCount - 1
                : 0,
              paymentRequestCount: paymentRequestCount,
              shortTermRequests: shortTermRequestCount

            })
          );
          successToast("Request changed successfully");
          setTimeout(() => {
            fetchData();
          }, 100);
        }
      } catch (error: any) {
        setIsLoading(false);

        errorToast(error?.data?.message || "");
      }
    } else {
      setReceiver("3");
      setOpen(true);
    }
  };

  const handleReject = async () => {
    let body;
    if (propertyType === 1) {
      body = {
        underOfferStatus: Number(receiver),
        underOfferRejectionReason: reason,
      };
    } else {
      body = {
        isClaimDocStatus: Number(receiver),
        isClaimRejectionReason: reason,
      };
    }
    console.log(body, "body");

    if (Number(receiver) === 3) {
      try {
        setIsLoading(true);

        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await editCompanybyId({
          body: encryptedBody,
          id: selectedId,
        }).unwrap();
        if (response?.statusCode === 200) {
          setIsLoading(false);
          setValue(2);
          dispatch(
            setRequestCount({
              agents: agentCount,
              company: companyCount,
              companyRequests: companyRequestCount,
              propertyRequest: propertyRequestCount
                ? propertyRequestCount - 1
                : 0,
              paymentRequestCount: paymentRequestCount,
              shortTermRequests: shortTermRequestCount

            })
          );
          successToast("Request rejected successfully");
          fetchData();
        }
      } catch (error: any) {
        setIsLoading(false);
        errorToast(error?.data?.message || "");
      }
    } else {
      return;
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    fetchData();
  }, [page, value, debouncedSearchTerm, propertyType, range, claimType]);

  useLayoutEffect(() => {
    if (state) setValue(state);
  }, []);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Property Requests</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header" marginBottom={"20px"}>
              <Box className="cards_header_left">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <div className="control_group">
                  <TextField
                    // label="Select Date Range"
                    variant="outlined"
                    hiddenLabel
                    className="text_field-range "
                    fullWidth
                    onClick={handleClick}
                    value={getFormattedRange()}
                    placeholder="Select Date Range"
                    InputProps={{
                      readOnly: true,
                      endAdornment: range?.from ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setRange({
                                from: undefined,
                                to: undefined,
                              });
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <IconButton>
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Popper
                  open={open1}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  disablePortal
                  style={{
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper
                      elevation={3}
                      style={{ position: "relative", zIndex: 1 }}
                    >
                      <div
                        style={{
                          padding: 8,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: "8px",
                          }}
                        >
                          <Chip
                            label="Last Week"
                            variant={
                              selectedChip === "lastWeek"
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => handleChipClick("lastWeek")}
                          />
                          <Chip
                            label="Last Month"
                            variant={
                              selectedChip === "lastMonth"
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => handleChipClick("lastMonth")}
                          />
                          <Chip
                            label="Last Year"
                            variant={
                              selectedChip === "lastYear"
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => handleChipClick("lastYear")}
                          />
                        </div>

                        <DayPicker
                          className="react-day-picker"
                          mode="range"
                          selected={range}
                          onSelect={handleSelect}
                          // numberOfMonths={10}
                          month={month}
                          onMonthChange={handleMonthChange}
                        // styles={{
                        //   months: { display: "flex", gap: "16px" },
                        //   month: { margin: 0 },
                        // }}
                        />
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Popper>


                {propertyType === 2 ? (
                  <Box>
                    <Select
                      className="childFilter"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={claimType}

                      onChange={(e) => setClaimType(e.target.value)}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">Default properties </MenuItem>
                      <MenuItem value="1">Re-published properties</MenuItem>
                    </Select>
                  </Box>
                ) : ("")}

              </Box>
              <Box className="cards_header_right"  >
                <Select
                  className="childFilter prop_req"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={propertyType}

                  onChange={(e) => setPropertyType(Number(e.target.value))}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Property Type
                  </MenuItem>
                  <MenuItem value={1}>Under Offer Properties ({dataCount?.dropUnder}) </MenuItem>
                  <MenuItem value={2}>Claimed Properties ({dataCount?.dropClaim})</MenuItem>
                </Select>
              </Box>
            </Box>

            <Box className="custom_tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab
                  label={`Pending (${dataCount?.pendingRequests})`}
                  {...a11yProps(0)}
                />
                <Tab
                  label={`Approved (${dataCount?.aproovedRequests})`}
                  {...a11yProps(1)}
                />
                <Tab
                  label={`Rejected (${dataCount?.rejectedRequests})`}
                  {...a11yProps(2)}
                />
              </Tabs>

              <CustomTabPanel value={value} index={0}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Property Id</TableCell>
                        <TableCell>Property Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Agent Name</TableCell>
                        <TableCell>Property Category</TableCell>
                        <TableCell>Property Type</TableCell>
                        <TableCell>Date & time</TableCell>
                        <TableCell>Request</TableCell>

                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {request?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No Property request found
                          </TableCell>
                        </TableRow>
                      ) : (
                        request?.map((row: any, i: any) => (
                          <TableRow key={i}>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>

                            <TableCell>
                              <figure
                                onClick={() =>
                                  navigate(
                                    `/manage-property-requests/details/${row?._id}`,
                                    { state: { tab: 0 } }
                                  )
                                }
                                className="user_img"
                              >
                                <img
                                  style={{ borderRadius: 0 }}
                                  src={
                                    row?.images[0] ||
                                    `/static/images/product1.png`
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{row?.referenceId || "-"}</TableCell>
                            <TableCell>{row?.name || "-"}</TableCell>
                            <TableCell>{row?.price ? formatNumberWithCommas(row?.price) : "-"}</TableCell>
                            <TableCell>{row?.companyId?.companyName ? row?.companyId?.companyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                            <TableCell>{row?.userId?.role === 5 || !row?.userId?.role ? "-" : row?.userId?.name + " (" + row?.userId?.referenceId + ")" || "-"}</TableCell>
                            <TableCell>{row?.category?.name || "-"}</TableCell>
                            <TableCell>
                              {row?.propertyTypes?.name || "-"}
                            </TableCell>
                            <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>

                            <TableCell>
                              <Select
                                fullWidth
                                className="select_div reqst_Select"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={receiver}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                onChange={(e: any) => {
                                  setSelectedId(row?._id);
                                  handleReceiverChange(e, row?._id);
                                }}
                              >
                                <MenuItem value="1" disabled>
                                  Pending
                                </MenuItem>
                                <MenuItem value="2">Approve</MenuItem>
                                {propertyType === 1 ? ("") : (

                                  <MenuItem value="3">Reject</MenuItem>
                                )}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <Tooltip title="View property details">
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-property-requests/details/${row?._id}`,
                                        { state: { tab: 0 } }
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {request?.length > 0 ? (
                  <Pagination
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    module={request}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                  />
                ) : (
                  ""
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Property Id</TableCell>
                        <TableCell>Property Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Agent Name</TableCell>
                        <TableCell>Property Category</TableCell>
                        <TableCell>Property Type</TableCell>
                        <TableCell>Date & time</TableCell>
                        <TableCell>Request</TableCell>

                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {request?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No Property request found
                          </TableCell>
                        </TableRow>
                      ) : (
                        request?.map((row: any, i: any) => (
                          <TableRow key={i}>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>

                            <TableCell>
                              <figure
                                onClick={() =>
                                  navigate(
                                    `/manage-property-requests/details/${row?._id}`,
                                    { state: { tab: 1 } }
                                  )
                                }
                                className="user_img"
                              >
                                <img
                                  style={{ borderRadius: 0 }}
                                  src={
                                    row?.images[0] ||
                                    `/static/images/product1.png`
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{row?.referenceId || "-"}</TableCell>
                            <TableCell>{row?.name || "-"}</TableCell>
                            <TableCell>{row?.price ? formatNumberWithCommas(row?.price) : "-"}</TableCell>
                            <TableCell>{row?.companyId?.companyName ? row?.companyId?.companyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                            <TableCell>{row?.userId?.role === 5 || !row?.userId?.role ? "-" : row?.userId?.name + " (" + row?.userId?.referenceId + ")" || "-"}</TableCell>
                            <TableCell>{row?.category?.name || "-"}</TableCell>
                            <TableCell>
                              {row?.propertyTypes?.name || "-"}
                            </TableCell>
                            <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>

                            <TableCell>Accepted</TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <Tooltip title="View property details">
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-property-requests/details/${row?._id}`,
                                        { state: { tab: 1 } }
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {request?.length > 0 ? (
                  <Pagination
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    module={request}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                  />
                ) : (
                  ""
                )}

              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Property Id</TableCell>
                        <TableCell>Property Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Agent Name</TableCell>
                        <TableCell>Property Category</TableCell>
                        <TableCell>Property Type</TableCell>
                        <TableCell>Date & time</TableCell>
                        <TableCell>Request</TableCell>

                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {request?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No Property request found
                          </TableCell>
                        </TableRow>
                      ) : (
                        request?.map((row: any, i: any) => (
                          <TableRow key={i}>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>

                            <TableCell>
                              <figure
                                onClick={() =>
                                  navigate(
                                    `/manage-property-requests/details/${row?._id}`,
                                    { state: { tab: 2 } }
                                  )
                                }
                                className="user_img"
                              >
                                <img
                                  style={{ borderRadius: 0 }}
                                  src={
                                    row?.images[0] ||
                                    `/static/images/product1.png`
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{row?.referenceId || "-"}</TableCell>
                            <TableCell>{row?.name || "-"}</TableCell>
                            <TableCell>{row?.price ? formatNumberWithCommas(row?.price) : "-"}</TableCell>
                            <TableCell>{row?.companyId?.companyName ? row?.companyId?.companyName + " (" + row?.companyId?.referenceId + ")" : "-"}</TableCell>
                            <TableCell>{row?.userId?.role === 5 || !row?.userId?.role ? "-" : row?.userId?.name + " (" + row?.userId?.referenceId + ")" || "-"}</TableCell>
                            <TableCell>{row?.category?.name || "-"}</TableCell>
                            <TableCell>
                              {row?.propertyTypes?.name || "-"}
                            </TableCell>
                            <TableCell>{row?.createdAt ? moment(row?.createdAt).format("LLL") : "-"}</TableCell>
                            <TableCell>Rejected</TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <Tooltip title="View property details">
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-property-requests/details/${row?._id}`,
                                        { state: { tab: 2 } }
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {request?.length > 0 ? (
                  <Pagination
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    module={request}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                  />
                ) : (
                  ""
                )}
              </CustomTabPanel>

            </Box>
          </Box>
        </Card>
      </div>
      <ReasonModal
        open={open}
        setOpen={setOpen}
        handleDelete={handleReject}
        reason={reason}
        setReason={setReason}
      />
    </div>
  );
};

export default ManagePropertyRequests;
