import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import "react-phone-input-2/lib/bootstrap.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";

import { getFromStorage } from "../../constants/storage";
import { UploadMedia } from "../../utils/uploadMedia";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { usePutUpdateProfileMutation } from "../../services/profile";
import { useSelector } from "react-redux";
import { getToken, setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import { errorToast, successToast, warnToast } from "../../helpers/toast";
import { STORAGE_KEYS } from "../../helpers/constants/storageKeys";
import { isString } from "../../utils/validation";
import { useLazyGetUserQuery } from "../../services/auth";
import { useEffect, useState } from "react";


const Profile = () => {
  const token: any = useSelector(getToken);
  const dispatch = useAppDispatch();
  const user = useAuth();
  const navigate = useNavigate();
  const [updateprofilre] = usePutUpdateProfileMutation();
  const [getUser] = useLazyGetUserQuery();
  const [details, setDetails] = useState<any>();

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        formik.setFieldValue("profile_picture", imageUrl);
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: details?.email || "",
      name: details?.name || "",
      profile_picture: details?.image || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          "Enter a valid email address"
        )
        .matches(
          /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/, // Regex to disallow emojis
          "Emojis are not allowed in the email address"
        ),
      name: Yup.string()
        .required("Full Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(25, "Maximum 25 characters are allowed")
        .test(
          "is-not-email",
          "Email address is not allowed in First Name",
          function (value) {
            return !Yup.string()
              .matches(
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
              )
              .isValidSync(value);
          }
        ),

    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) return;
      if (!values.profile_picture) {
        warnToast("Please upload a profile picture")
        return;
      }
      try {
        setSubmitting(true);
        const body = {
          email: values.email,
          name: values.name,
          image: values.profile_picture,
        };
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateprofilre(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Profile Updated Successfully.");
          getUserDetails();
          navigate("/dashboard");
        }
        else {
          errorToast(response?.message || "Failed to update profile.");
        }

      } catch (error) {
        errorToast("An error occurred while updating profile.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getUserDetails = async () => {

    try {
      const result = await getUser({}).unwrap();
      if (result?.statusCode === 200) {
        setDetails(result?.data)
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );

      }
    } catch (error) {
      console.log(error);
    }

  };


  useEffect(() => {

    getUserDetails();

  }, []);


  return (
    <>
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Profile</h1>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {formik.values.profile_picture ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={formik.values.profile_picture}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={(e) => {
                            e.preventDefault();
                            formik.setFieldValue("profile_picture", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Full Name</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Full Name"
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      className="text_field"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.name && formik.errors.name
                          ? formik.errors.name.toString()
                          : ""
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="text_field"
                      disabled
                      helperText={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email.toString()
                          : ""
                      }
                    ></TextField>
                  </Grid>

                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    disabled={formik.isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Profile;
